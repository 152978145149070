import React, { CSSProperties } from 'react';

import { Button, Grid, Typography } from '@mui/material';

import intl from 'react-intl-universal';
import { useNavigate } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import { Formik, Field } from 'formik';
import * as yup from 'yup';

import { useAppDispatch } from 'store/hooks';
import { forgotPassword } from 'store/features/auth/authSlice';

import { ROUTES, UTILS } from 'shared/constants';
import defaultTheme from 'shared/theme';
import { initIntl } from 'shared/locales';

interface FormData {
  email: string;
}

initIntl();

const validationSchema = yup.object({
  email: yup
    .string()
    .email(intl.get('validation.invalidFormat'))
    .required(intl.get('validation.requiredField')),
});

const linkStyle: CSSProperties = {
  color: defaultTheme.palette.primary.main,
  cursor: 'pointer',
  textDecoration: 'underline',
};

/**
 * Create password form component
 * @return {JSX.Element}
 */
function ForgotPasswordForm(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const initialValues = {
    email: UTILS.BLANK,
  };

  const onForgotPassword = async (values: FormData) => {
    await dispatch(forgotPassword({ ...values }));
  };

  return (<Formik
    enableReinitialize
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={async (values, { setSubmitting }) => {
      setSubmitting(true);
      await onForgotPassword(values);
      setSubmitting(false);
      navigate(ROUTES.LOGIN);
    }}
  >
    {({ submitForm, isSubmitting }) => (
      <>
        <Grid item xs={12}>
          <Typography>{intl.get('pages.forgotPassword.title')}</Typography>
          <Typography variant="h3">{intl.get('pages.forgotPassword.description')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Field
            component={TextField}
            fullWidth
            variant="outlined"
            name="email"
            type="email"
            label={intl.get('pages.forgotPassword.yourEmail')}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            onClick={submitForm}
          >
            {intl.get('pages.forgotPassword.send')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <a style={linkStyle} onClick={() => navigate(ROUTES.LOGIN)}>{intl.get('pages.forgotPassword.backToLogin')}</a>
          </Typography>
        </Grid>
      </>
    )}
  </Formik>);
}

export default ForgotPasswordForm;
