import React, { useMemo } from 'react';

import { Box, Button } from '@mui/material';

import intl from 'react-intl-universal';

import Report from 'models/Report';
import Team from 'models/Team';
import { BehavioralAspectsByType } from 'models/BehavioralAspect';

import { ROUTES } from 'shared/constants';

import ReportPDF, {
  downloadOrSendReportButton as disabledReportButton,
} from 'components/reportPDF';

interface Props {
  team: Team;
  report: Report;
  behavioralAspects: BehavioralAspectsByType;
  lgUp: boolean;
}

/**
 * DownloadReports component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function DownloadReports(props: Props): JSX.Element {
  const showFullReport = useMemo(() => {
    if (props.report.reportSpecialties.length) {
      return true;
    }

    if (
      props.report.reportSpecialties.length !==
      props.team.specialtiesUsers.length
    ) {
      return false;
    }

    for (
      let reportSpecialtyIndex = 0;
      reportSpecialtyIndex < props.report.reportSpecialties.length;
      reportSpecialtyIndex++
    ) {
      const reportSpecialty =
        props.report.reportSpecialties[reportSpecialtyIndex];
      const totalBehavioralAspects =
        props.behavioralAspects.behavioral.length +
        props.behavioralAspects.cognitive.length +
        props.behavioralAspects.adaptive.length;

      if (
        !reportSpecialty.goals.length ||
        !reportSpecialty.considerations ||
        !reportSpecialty.evaluation ||
        !reportSpecialty.behavioralObs ||
        !reportSpecialty.adaptiveObs ||
        !reportSpecialty.cognitiveObs ||
        !reportSpecialty.introduction ||
        !reportSpecialty.procedures ||
        !reportSpecialty.analysis ||
        !reportSpecialty.week1 ||
        !reportSpecialty.week2 ||
        !reportSpecialty.week3 ||
        !reportSpecialty.week4 ||
        !reportSpecialty.week5 ||
        !reportSpecialty.week6 ||
        !reportSpecialty.week7 ||
        !reportSpecialty.week8 ||
        !reportSpecialty.week9 ||
        !reportSpecialty.week10 ||
        !reportSpecialty.week11 ||
        !reportSpecialty.week12 ||
        reportSpecialty.behavioralAspects.length < totalBehavioralAspects
      ) {
        return false;
      }

      for (
        let goalsIndex = 0;
        goalsIndex < reportSpecialty.goals.length;
        goalsIndex++
      ) {
        const goal = reportSpecialty.goals[goalsIndex];

        if (!goal.score) {
          return false;
        }
      }
    }

    return true;
  }, [props.report]);

  return (
    <>
      {props.team && props.report ? (
        props.lgUp ? (
          <Box display={'flex'} flexDirection="column">
            <Button
              disabled={!showFullReport}
              sx={{ width: '300px', mb: 2 }}
              onClick={() =>
                window.open(
                  ROUTES.REPORTS_VIEW.replace(
                    ':teamId',
                    props.team.id.toString(),
                  ).replace(':learnerId', props.report.learner.id.toString()),
                  '_blank',
                  'noopener,noreferrer',
                )
              }
              variant="contained"
              color="primary"
            >
              {intl.get('pages.viewLearner.download.full')}
            </Button>
            {showFullReport ? (
              <ReportPDF
                viewer={false}
                learnerId={props.report.learner.id.toString()}
                teamId={props.team.id.toString()}
              />
            ) : (
              disabledReportButton(false, true)
            )}
            <Button
              sx={{ width: '300px' }}
              onClick={() =>
                window.open(
                  ROUTES.REPORTS_PARTIAL_VIEW.replace(
                    ':teamId',
                    props.team.id.toString(),
                  ).replace(':learnerId', props.report.learner.id.toString()),
                  '_blank',
                  'noopener,noreferrer',
                )
              }
              variant="contained"
              color="primary"
            >
              {intl.get('pages.viewLearner.download.partial')}
            </Button>
          </Box>
        ) : (
          <>
            {showFullReport ? (
              <ReportPDF
                viewer={false}
                learnerId={props.report.learner.id.toString()}
                teamId={props.team.id.toString()}
              />
            ) : (
              disabledReportButton(false, true)
            )}
            <ReportPDF
              partial
              viewer={false}
              learnerId={props.report.learner.id.toString()}
              teamId={props.team.id.toString()}
            />
          </>
        )
      ) : null}
    </>
  );
}
