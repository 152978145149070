import React, { ReactElement, useEffect } from 'react';
import { Routes as Switch, Route, useLocation, useNavigate } from 'react-router-dom';

import { CREATE_PASSWORD, PERMISSIONS, RESET_PASSWORD, ROUTES } from 'shared/constants';
import { useAppSelector } from 'store/hooks';

import NotFound from 'pages/notFound';
import Login from 'pages/login';
import Home from 'pages/home';

import Users from 'pages/users';
import FormUser from 'pages/users/form';

import CLINICS from 'pages/clinics';
import FormClinic from 'pages/clinics/form';

import Learners from 'pages/learners';
import FormLearner from 'pages/learners/form';
import ViewLearner from 'pages/learners/view';

import Specialties from 'pages/specialties';
import FormSpecialty from 'pages/specialties/form';

import Teams from 'pages/teams';
import FormTeam from 'pages/teams/form';
import ViewTeam from 'pages/teams/view';

import Highlights from 'pages/highlights';
import FormHighlight from 'pages/highlights/form';

import InfoFIles from 'pages/infoFIles';
import FormInfoFile from 'pages/infoFIles/form';

import ViewReport from 'pages/viewReport';

import Layout from 'components/Layout';
import LoginLayout from 'components/Layout/login';
import BlankLayout from 'components/Layout/blank';
import PrintLayout from 'components/Layout/print';

/**
 * Application routes
 * @return {JSX.Element}
 */
export default function Routes(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const { logged, loading, user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (
      !loading &&
      !logged &&
      ![ROUTES.FORGOT_PASSWORD, ROUTES.RESET_PASSWORD].includes(location.pathname) &&
      location.pathname.search(RESET_PASSWORD) === -1 &&
      location.pathname.search(CREATE_PASSWORD) === -1
    ) {
      navigate(ROUTES.LOGIN);
    }
  }, [navigate, logged]);

  const checkPermission = (route: ReactElement, permissions: string[]) => {
    if (user && permissions.includes(user.role.id.toString())) {
      return route;
    }
    return <></>;
  };

  return (
    <Switch>
      {
        logged ? <Route path={ROUTES.ROOT} element={<Layout />}>
          <Route path={ROUTES.ROOT} element={<Home />} />
          {
            checkPermission(
              <Route path={ROUTES.USERS}>
                {checkPermission(<Route path={ROUTES.USERS} element={<Users />} />, PERMISSIONS.USERS.LIST)}
                {checkPermission(<Route path={ROUTES.USERS_ADD} element={<FormUser />} />, PERMISSIONS.USERS.CREATE)}
                {checkPermission(<Route path={ROUTES.USERS_EDIT} element={<FormUser />} />, PERMISSIONS.USERS.EDIT)}
              </Route>,
              PERMISSIONS.USERS.LIST,
            )
          }
          {
            checkPermission(
              <Route path={ROUTES.CLINICS}>
                {checkPermission(<Route path={ROUTES.CLINICS} element={<CLINICS />} />, PERMISSIONS.CLINICS.LIST)}
                {checkPermission(<Route path={ROUTES.CLINICS_ADD} element={<FormClinic />} />, PERMISSIONS.CLINICS.CREATE)}
                {checkPermission(<Route path={ROUTES.CLINICS_EDIT} element={<FormClinic />} />, PERMISSIONS.CLINICS.EDIT)}
              </Route>,
              PERMISSIONS.CLINICS.LIST,
            )
          }
          {
            checkPermission(
              <Route path={ROUTES.LEARNERS}>
                {checkPermission(<Route path={ROUTES.LEARNERS} element={<Learners />} />, PERMISSIONS.LEARNERS.LIST)}
                {checkPermission(<Route path={ROUTES.LEARNERS_ADD} element={<FormLearner />} />, PERMISSIONS.LEARNERS.CREATE)}
                {checkPermission(<Route path={ROUTES.LEARNERS_EDIT} element={<FormLearner />} />, PERMISSIONS.LEARNERS.EDIT)}
              </Route>,
              [...PERMISSIONS.LEARNERS.LIST, ...PERMISSIONS.LEARNERS.VIEW],
            )
          }
          {
            checkPermission(
              <Route path={ROUTES.SPECIALTIES}>
                {checkPermission(<Route path={ROUTES.SPECIALTIES} element={<Specialties />} />, PERMISSIONS.SPECIALTIES.LIST)}
                {checkPermission(<Route path={ROUTES.SPECIALTIES_ADD} element={<FormSpecialty />} />, PERMISSIONS.SPECIALTIES.CREATE)}
                {checkPermission(<Route path={ROUTES.SPECIALTIES_EDIT} element={<FormSpecialty />} />, PERMISSIONS.SPECIALTIES.EDIT)}
              </Route>,
              PERMISSIONS.SPECIALTIES.LIST,
            )
          }
          {
            checkPermission(
              <Route path={ROUTES.TEAMS}>
                {checkPermission(<Route path={ROUTES.TEAMS} element={<Teams />} />, PERMISSIONS.TEAMS.LIST)}
                {checkPermission(<Route path={ROUTES.TEAMS_ADD} element={<FormTeam />} />, PERMISSIONS.TEAMS.CREATE)}
                {checkPermission(<Route path={ROUTES.TEAMS_EDIT} element={<FormTeam />} />, PERMISSIONS.TEAMS.EDIT)}
                {checkPermission(<Route path={ROUTES.TEAMS_VIEW} element={<ViewTeam />} />, PERMISSIONS.TEAMS.VIEW)}
                {checkPermission(<Route path={ROUTES.TEAMS_VIEW_LEARNERS_VIEW} element={<ViewLearner />} />, PERMISSIONS.LEARNERS.VIEW)}
              </Route>,
              PERMISSIONS.TEAMS.LIST,
            )
          }
          {
            checkPermission(
              <Route path={ROUTES.HIGHLIGHTS}>
                {checkPermission(<Route path={ROUTES.HIGHLIGHTS} element={<Highlights />} />, PERMISSIONS.HIGHLIGHTS.LIST)}
                {checkPermission(<Route path={ROUTES.HIGHLIGHTS_ADD} element={<FormHighlight />} />, PERMISSIONS.HIGHLIGHTS.CREATE)}
                {checkPermission(<Route path={ROUTES.HIGHLIGHTS_EDIT} element={<FormHighlight />} />, PERMISSIONS.HIGHLIGHTS.EDIT)}
              </Route>,
              PERMISSIONS.HIGHLIGHTS.LIST,
            )
          }
          {
            checkPermission(
              <Route path={ROUTES.INFO_FILES}>
                {checkPermission(<Route path={ROUTES.INFO_FILES} element={<InfoFIles />} />, PERMISSIONS.INFO_FILES.LIST)}
                {checkPermission(<Route path={ROUTES.INFO_FILES_ADD} element={<FormInfoFile />} />, PERMISSIONS.INFO_FILES.CREATE)}
                {checkPermission(<Route path={ROUTES.INFO_FILES_EDIT} element={<FormInfoFile />} />, PERMISSIONS.INFO_FILES.EDIT)}
              </Route>,
              PERMISSIONS.INFO_FILES.LIST,
            )
          }
          {/* {
            checkPermission(
              <Route path={ROUTES.SPECIAL_TEAMS}>
                {checkPermission(<Route path={ROUTES.SPECIAL_TEAMS} element={<Teams />} />, PERMISSIONS.TEAMS.LIST)}
                {checkPermission(<Route path={ROUTES.SPECIAL_TEAMS_ADD} element={<FormTeam />} />, PERMISSIONS.TEAMS.CREATE)}
                {checkPermission(<Route path={ROUTES.SPECIAL_TEAMS_EDIT} element={<FormTeam />} />, PERMISSIONS.TEAMS.EDIT)}
                {checkPermission(<Route path={ROUTES.SPECIAL_TEAMS_VIEW} element={<ViewTeam />} />, PERMISSIONS.TEAMS.VIEW)}
                {checkPermission(<Route path={ROUTES.SPECIAL_TEAMS_LEARNERS_VIEW} element={<ViewLearner />} />, PERMISSIONS.LEARNERS.VIEW)}
              </Route>,
              PERMISSIONS.TEAMS.LIST,
            )
          } */}
        </Route> : null
      }
      <Route path={ROUTES.ROOT} element={<LoginLayout />}>
        <Route path={ROUTES.LOGIN} element={<Login />} />
        <Route path={ROUTES.CREATE_PASSWORD} element={<Login />} />
        <Route path={ROUTES.FORGOT_PASSWORD} element={<Login />} />
        <Route path={ROUTES.RESET_PASSWORD} element={<Login />} />
      </Route>
      <Route path={ROUTES.ROOT} element={<BlankLayout />}>
        <Route path='*' element={<NotFound />} />
      </Route>
      <Route path={ROUTES.ROOT} element={<PrintLayout />}>
        <Route path={ROUTES.REPORTS_PARTIAL_VIEW} element={<ViewReport />} />
        <Route path={ROUTES.REPORTS_VIEW} element={<ViewReport />} />
      </Route>
    </Switch>
  );
}
