import React, { useEffect, useMemo, useState } from 'react';

import intl from 'react-intl-universal';
import { useLocation } from 'react-router-dom';

import { ENDPOINTS, PERMISSIONS, ROUTES } from 'shared/constants';
import { initIntl } from 'shared/locales';
import api from 'shared/api';

import User from 'models/User';
import Team from 'models/Team';

import { useAppSelector } from 'store/hooks';

import Table, { Field } from 'components/Table';
import { Type } from 'components/NoResultsBox';

initIntl();

const getColumns = (user: User) => {
  return [
    intl.get('pages.teams.id'),
    intl.get('pages.teams.name'),
  ];
};

const getFields = (user: User): Field[] => {
  return [
    {
      name: 'id',
    },
    {
      name: 'name',
    },
  ];
};

/**
 * Teams component
 * @return {JSX.Element}
 */
function Teams(): JSX.Element {
  const location = useLocation();
  const [teams, setTeams] = useState<Team[]>([]);
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    getTeams();
  }, [location.pathname]);

  const paths = useMemo(() => {
    // if (location.pathname.search('multifuncionais') !== -1) {
    //   return {
    //     add: ROUTES.SPECIAL_TEAMS_ADD,
    //     edit: ROUTES.SPECIAL_TEAMS_EDIT,
    //     view: ROUTES.SPECIAL_TEAMS_VIEW,
    //   };
    // }
    return {
      add: ROUTES.TEAMS_ADD,
      edit: ROUTES.TEAMS_EDIT,
      view: ROUTES.TEAMS_VIEW,
    };
  }, [location.pathname]);

  const fields = useMemo(() => {
    if (user) {
      return getFields(user);
    }
    return [];
  }, [user]);

  const columns = useMemo(() => {
    if (user) {
      return getColumns(user);
    }
    return [];
  }, [user]);

  const selects = useMemo(() => {
    return [];
  }, []);

  const getTeams = async () => {
    let endpoint = ENDPOINTS.TEAMS.GET.TRADITIONAL;
    if (location.pathname.search('multifuncionais') !== -1) {
      endpoint = ENDPOINTS.TEAMS.GET.SPECIAL;
    }
    const response = await api.get(endpoint);
    setTeams(response.data);
  };

  return (
    <>
      {
        user ? <Table
          type={Type.team}
          columns={columns}
          fields={fields}
          onRefresh={getTeams}

          filter={{
            data: teams,
            searchMatchFields: ['name'],
            searchPlaceholder: intl.get('pages.teams.searchPlaceholder'),
            selects: selects,
          }}

          deleteDialog={{
            titleIntlKey: 'pages.teams.deleteTitleDialog',
            descriptionIntlKey: 'pages.teams.deleteDescriptionDialog',
          }}

          actions={{
            addPath: PERMISSIONS.TEAMS.CREATE.includes(user.role.id.toString()) ? paths.add : undefined,
            editPath: PERMISSIONS.TEAMS.EDIT.includes(user.role.id.toString()) ? paths.edit : undefined,
            viewPath: PERMISSIONS.TEAMS.VIEW.includes(user.role.id.toString()) ? paths.view : undefined,
            deleteEndpoint: PERMISSIONS.TEAMS.REMOVE.includes(user.role.id.toString()) ? ENDPOINTS.TEAMS.DELETE.BY_ID : undefined,
          }}
        /> : null
      }

    </>
  );
}

export default Teams;
