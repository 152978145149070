import React, { useState } from 'react';

import { Button, Grid } from '@mui/material';

import intl from 'react-intl-universal';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { TextField } from 'formik-material-ui';
import { Formik, Field } from 'formik';
import query from 'query-string';
import * as yup from 'yup';

import { useAppDispatch } from 'store/hooks';
import { resetPassword } from 'store/features/auth/authSlice';

import { ROUTES, UTILS } from 'shared/constants';
import { initIntl } from 'shared/locales';

interface FormData {
  password: string;
  passwordConfirmation: string;
}

initIntl();

const validationSchema = yup.object({
  password: yup
    .string()
    .required(intl.get('validation.requiredField'))
    .min(UTILS.PASSWORD_MIN_LEN, intl.get('validation.passwordMin'))
    .max(UTILS.STRING_MAX_LEN),
  passwordConfirmation: yup.string()
    .oneOf([yup.ref('password'), null], intl.get('validation.passwordMustMatch')),
});

/**
 * Reset password form component
 * @return {JSX.Element}
 */
function ResetPasswordForm(): JSX.Element {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const { token } = useParams<{ id: string, token: string }>();
  const params = query.parse(encodeURI(location.search));
  let email = UTILS.BLANK;
  if (typeof params.email === 'string') {
    email = decodeURIComponent(params.email);
  }

  const initialValues = {
    email: email,
    password: UTILS.BLANK,
    passwordConfirmation: UTILS.BLANK,
  };

  const onResetPassword = async (values: FormData) => {
    if (email && token) {
      setDisabled(true);
      dispatch(resetPassword({
        email,
        token,
        ...values,
      }));
      setDisabled(false);
    }
  };

  return (<Formik
    enableReinitialize
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={async (values, { setSubmitting }) => {
      await onResetPassword(values);
      setSubmitting(false);
      navigate(ROUTES.LOGIN);
    }}
  >
    {({ submitForm, isSubmitting }) => (
      <>
        <Grid item xs={12}>
          <Field
            component={TextField}
            fullWidth
            disabled
            shrink
            variant="outlined"
            name="email"
            label={intl.get('pages.resetPassword.email')}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={TextField}
            fullWidth
            variant="outlined"
            name="password"
            type="password"
            label={intl.get('pages.resetPassword.newPassword')}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={TextField}
            fullWidth
            variant="outlined"
            name="passwordConfirmation"
            type="password"
            label={intl.get('pages.resetPassword.confirmNewPassword')}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={disabled || isSubmitting}
            onClick={submitForm}
          >
            {intl.get('pages.resetPassword.resetPassword')}
          </Button>
        </Grid>
      </>
    )}
  </Formik>);
}

export default ResetPasswordForm;
