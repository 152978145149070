export const sortAlphabetically = <T>( a: T, b: T, field: keyof T ) => {
  const first = a[field] as string;
  const second = b[field] as string;

  if ( first.toLowerCase() < second.toLowerCase() ) {
    return -1;
  }
  if ( first.toLowerCase() > second.toLowerCase() ) {
    return 1;
  }
  return 0;
};

export const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K): {[key: string]: T[]} =>
  arr.reduce((groups, item) => {
    (groups[key(item)] ||= []).push(item);
    return groups;
  }, {} as Record<K, T[]>);
