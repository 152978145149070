import React, { CSSProperties } from 'react';

import { Container, CssBaseline, Paper, SxProps, Theme, useMediaQuery } from '@mui/material';

import { Outlet } from 'react-router-dom';

import defaultTheme from 'shared/theme';

import AppBar from './AppBar';

const containerStyle: SxProps<Theme> = {
  backgroundColor: '#f3f3f4',
  position: 'absolute',
  maxWidth: 'unset',
  minHeight: '100vh',
  top: 0,
};

const paperStyle = (smDown: boolean): SxProps<Theme> => ({
  marginTop: defaultTheme.spacing(3),
  marginBottom: defaultTheme.spacing(3),
  marginLeft: smDown ? 'unset' : defaultTheme.spacing(2.5 * 10),
  padding: defaultTheme.spacing(3),
  minHeight: 'calc(100vh - 118px)',
});

const imageStyle: CSSProperties = {
  height: '70px',
};

/**
 * Default layout
 * @return {JSX.Element}
 */
function Layout(): JSX.Element {
  const smDown = useMediaQuery(defaultTheme.breakpoints.down('md'));

  return (
    <>
      <CssBaseline />
      <AppBar />
      <Container maxWidth={false} id="defaultLayout" sx={containerStyle}>
        <div style={imageStyle} />
        <Paper elevation={1} square={false} sx={paperStyle(smDown)}>
          <Outlet />
        </Paper>
      </Container>
    </>
  );
};

export default Layout;
