import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import intl from 'react-intl-universal';
import { AxiosError } from 'axios';

import User from 'models/User';

import api from 'shared/api';
import { ENDPOINTS } from 'shared/constants';

import { useToastify } from 'hooks/toastfy';

import { displayError } from 'helpers/http';

const toast = useToastify();

export interface AuthState {
  logged: boolean;
  user: User | null;
  loading: boolean;
}

const initialState: AuthState = {
  logged: false,
  loading: true,
  user: null,
};

interface LoginData {
  email: string;
  password: string;
};

interface CreatePasswordData {
  id: string;
  token: string;
  password: string;
  passwordConfirmation: string;
};

interface ResetPasswordData {
    email: string;
    token: string;
    password: string;
    passwordConfirmation: string;
};

export const login = createAsyncThunk<void, LoginData>(
  'auth/login',
  async ({ email, password }, thunkApi) => {
    try {
      await api.get(ENDPOINTS.AUTH.GET.CSRF);
      await api.post(ENDPOINTS.AUTH.POST.LOGIN, { email, password });
      thunkApi.dispatch(logged());
      thunkApi.dispatch(getUser());
    } catch (error) {
      displayError(error as AxiosError);
    }
  },
);

export const createPassword = createAsyncThunk<void, CreatePasswordData>(
  'auth/createPassword',
  async ({ id, token, password, passwordConfirmation }) => {
    try {
      await api.get(ENDPOINTS.AUTH.GET.CSRF);
      await api.patch(`${ENDPOINTS.USERS.POST.CREATE_PASSWORD.replace(':id', id).replace(':token', token)}`,
        { password, password_confirmation: passwordConfirmation });
      toast.success(intl.get('toast.passwordCreated'));
    } catch (error) {
      displayError(error as AxiosError);
    }
  },
);

export const forgotPassword = createAsyncThunk<void, { email: string }>(
  'auth/forgotPassword',
  async ({ email }) => {
    try {
      await api.get(ENDPOINTS.AUTH.GET.CSRF);
      await api.post(`${ENDPOINTS.USERS.POST.FORGOT_PASSWORD}`,
        { email });
      toast.success(intl.get('toast.forgotPasswordEmailSent'));
    } catch (error) {
      displayError(error as AxiosError);
    }
  },
);

export const resetPassword = createAsyncThunk<void, ResetPasswordData>(
  'auth/resetPassword',
  async ({ email, token, password, passwordConfirmation }) => {
    try {
      await api.get(ENDPOINTS.AUTH.GET.CSRF);
      await api.post(`${ENDPOINTS.USERS.POST.RESET_PASSWORD}`,
        {
          email,
          token,
          password,
          password_confirmation: passwordConfirmation,
        });
      toast.success(intl.get('toast.passwordUpdated'));
    } catch (error) {
      displayError(error as AxiosError);
    }
  },
);

export const logout = createAsyncThunk(
  'auth/logout',
  async () => {
    try {
      await api.post(ENDPOINTS.AUTH.POST.LOGOUT);
    } catch (error) {
      displayError(error as AxiosError);
    }
  },
);

export const getUser = createAsyncThunk(
  'auth/getUser',
  async (data, thunkApi) => {
    try {
      const response = await api.get(ENDPOINTS.USERS.GET.AUTHENTICATED);
      thunkApi.dispatch(logged());
      return response.data;
    } catch (error) {
      thunkApi.dispatch(logout());
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logged(state) {
      state.logged = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(getUser.rejected, (state) => {
        state.loading = false;
        state.logged = false;
      });
    builder
      .addCase(logout.pending, (state) => {
        state.loading = true;
      })
      .addCase(logout.fulfilled, (state) => {
        state.loading = false;
        state.logged = false;
        state.user = null;
      })
      .addCase(logout.rejected, (state) => {
        state.loading = false;
        state.logged = false;
        state.user = null;
      });
  },
});

export const { logged } = authSlice.actions;

export default authSlice.reducer;
