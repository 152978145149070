import React, { useEffect, useState } from 'react';

import { FormControl, FormControlLabel, FormLabel, Grid, LinearProgress, Radio, RadioGroup, SxProps, Theme } from '@mui/material';
import { red } from '@mui/material/colors';

import intl from 'react-intl-universal';

import { ROLES, UTILS } from 'shared/constants';

import { useAppSelector } from 'store/hooks';

import ConditionalContainer from 'components/ConditionalContainer';

const radioStyle: SxProps<Theme> = {
  'color': red[800],
  '&.Mui-checked': {
    color: red[600],
  },
};

interface Props {
  initialValue: string;
  userBelongsToSpecialty: boolean;
  onChange: (value: string) => void;
  onSave: () => void;
  isSubmiting: boolean;
}

/**
 * Considerations component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function Considerations(props: Props): JSX.Element {
  const { user } = useAppSelector((state) => state.auth);
  const [considerations, setConsiderations] = useState(UTILS.BLANK);

  useEffect(() => {
    setConsiderations(props.initialValue);
  }, [props.initialValue]);

  useEffect(() => {
    if (props.initialValue !== considerations) {
      const saveReportSpecialtyTimeOutId = setTimeout(() => props.onSave(), 500);
      return () => clearTimeout(saveReportSpecialtyTimeOutId);
    }
  }, [considerations]);

  return <>
    <Grid item xs={12}>
      <FormControl>
        <FormLabel sx={{ mb: 1, fontWeight: 'bold' }} id="considerations-radio-buttons-group-label">
          {intl.get('pages.viewLearner.map.considerationsLabel')}
        </FormLabel>
        <RadioGroup
          aria-labelledby="considerations-radio-buttons-group-label"
          onChange={(e) => {
            const value = (e.target as HTMLInputElement).value;
            setConsiderations(value);
            props.onChange(value);
          }}
          value={considerations}
        >
          <FormControlLabel
            sx={{ mb: 1 }}
            value={intl.get('pages.viewLearner.map.considerations.one')}
            control={<Radio sx={!considerations ? radioStyle : undefined} />}

            label={intl.get('pages.viewLearner.map.considerations.one')}
            disabled={(user && user.role.id.toString() === ROLES.THERAPIST && !props.userBelongsToSpecialty) || props.isSubmiting}
          />
          <FormControlLabel
            sx={{ mb: 1 }}
            value={intl.get('pages.viewLearner.map.considerations.two')}
            control={<Radio sx={!considerations ? radioStyle : undefined} />}

            label={intl.get('pages.viewLearner.map.considerations.two')}
            disabled={(user && user.role.id.toString() === ROLES.THERAPIST && !props.userBelongsToSpecialty) || props.isSubmiting}
          />
          <FormControlLabel
            sx={{ mb: 1 }}
            value={intl.get('pages.viewLearner.map.considerations.three')}
            control={<Radio sx={!considerations ? radioStyle : undefined} />}

            label={intl.get('pages.viewLearner.map.considerations.three')}
            disabled={(user && user.role.id.toString() === ROLES.THERAPIST && !props.userBelongsToSpecialty) || props.isSubmiting}
          />
          <FormControlLabel
            sx={{ mb: 1 }}
            value={intl.get('pages.viewLearner.map.considerations.four')}
            control={<Radio sx={!considerations ? radioStyle : undefined} />}

            label={intl.get('pages.viewLearner.map.considerations.four')}
            disabled={(user && user.role.id.toString() === ROLES.THERAPIST && !props.userBelongsToSpecialty) || props.isSubmiting}
          />
        </RadioGroup>
      </FormControl>
    </Grid>
    <ConditionalContainer noComponent checkIf={props.isSubmiting}>
      <Grid item xs={12} mb={3} mt={1}>
        <Grid item xs={12} md={6}>
          {props.isSubmiting && <LinearProgress />}
        </Grid>
      </Grid>
    </ConditionalContainer>
  </>;
}
