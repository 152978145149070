import intl from 'react-intl-universal';

import { initIntl } from './locales';

import { SelectOption } from 'components/Table/Filter';

export const API_HOST = 'https://sopassessoria.com.br/clath/api/api';
// export const API_HOST = 'http://127.0.0.1:8000/api';

export const APP_NAME = 'CLATH';

export const UTILS = {
  PASSWORD_MIN_LEN: 8,
  STRING_MAX_LEN: 255,
  TEXT_MAX_LEN: 500,
  BLANK: '',
  SPACE: ' ',
  ELLIPSIS: '...',
  DOT: '.',
  QUESTION: '?',
  EQUAL: '=',
};

export const ERROR_STATUS = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
};

export const PAGINATION = {
  ROWS_PER_PAGE: [10, 20, 50],
  RADIX: 10,
};

export const ROLES = {
  ADMIN: '1',
  CLINIC: '2',
  THERAPIST: '3',
};

initIntl();

export const ROLES_OPTIONS: SelectOption[] = [
  { value: ROLES.ADMIN, label: intl.get('common.roles.admin') },
  { value: ROLES.CLINIC, label: intl.get('common.roles.clinic') },
  { value: ROLES.THERAPIST, label: intl.get('common.roles.therapist') },
];

export const RESET_PASSWORD = 'redefinir-a-senha';
export const CREATE_PASSWORD = 'nova-senha';

export const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  NOT_FOUND: '/404',
  FORGOT_PASSWORD: '/esqueci-a-senha',
  RESET_PASSWORD: `/${RESET_PASSWORD}/:token`,
  CREATE_PASSWORD: `/${CREATE_PASSWORD}/:id/:token`,

  USERS: '/usuarios',
  USERS_ADD: '/usuarios/adicionar',
  USERS_EDIT: '/usuarios/editar/:id',

  CLINICS: '/clinicas',
  CLINICS_ADD: '/clinicas/adicionar',
  CLINICS_EDIT: '/clinicas/editar/:id',

  LEARNERS: '/aprendentes',
  LEARNERS_ADD: '/aprendentes/adicionar',
  LEARNERS_EDIT: '/aprendentes/editar/:id',

  SPECIALTIES: '/especialidades',
  SPECIALTIES_ADD: '/especialidades/adicionar',
  SPECIALTIES_EDIT: '/especialidades/editar/:id',

  TEAMS: '/grupos-terapeuticos',
  TEAMS_ADD: '/grupos-terapeuticos/adicionar',
  TEAMS_EDIT: '/grupos-terapeuticos/editar/:id',
  TEAMS_VIEW: '/grupos-terapeuticos/ver/:id',
  TEAMS_VIEW_LEARNERS_VIEW: '/grupos-terapeuticos/:teamId/aprendentes/ver/:id/',

  // SPECIAL_TEAMS: '/salas-multifuncionais',
  // SPECIAL_TEAMS_ADD: '/salas-multifuncionais/adicionar',
  // SPECIAL_TEAMS_EDIT: '/salas-multifuncionais/editar/:id',
  // SPECIAL_TEAMS_VIEW: '/salas-multifuncionais/ver/:id',
  // SPECIAL_TEAMS_LEARNERS_VIEW: '/salas-multifuncionais/:teamId/aprendentes/ver/:id/',

  REPORTS_VIEW: '/relatorios/grupos-terapeutico/:teamId/aprendente/:learnerId',
  REPORTS_PARTIAL_VIEW: '/relatorios/parcial/grupos-terapeutico/:teamId/aprendente/:learnerId',

  HIGHLIGHTS: '/avisos',
  HIGHLIGHTS_ADD: '/avisos/adicionar',
  HIGHLIGHTS_EDIT: '/avisos/editar/:id',

  INFO_FILES: '/arquivos',
  INFO_FILES_ADD: '/arquivos/adicionar',
  INFO_FILES_EDIT: '/arquivos/editar/:id',
};

export const ENDPOINTS = {
  AUTH: {
    GET: {
      CSRF: '/csrf-cookie',
    },
    POST: {
      LOGIN: '/login',
      LOGOUT: '/logout',
    },
  },
  DASHBOARD: {
    GET: {
      ALL: '/dashboard',
    },
  },
  USERS: {
    GET: {
      AUTHENTICATED: '/users/authenticated',
      ALL: '/users',
      BY_ID: '/users/:id',
      TEACHERS: '/users/therapists',
    },
    POST: {
      ADD: '/users',
      CREATE_PASSWORD: '/users/create-password/:id/:token',
      FORGOT_PASSWORD: '/users/forgot-password',
      RESET_PASSWORD: '/users/reset-password',
    },
    PUT: {
      BY_ID: '/users/:id',
    },
    DELETE: {
      BY_ID: '/users/:id',
    },
  },
  CLINICS: {
    GET: {
      ALL: '/clinics',
      BY_ID: '/clinics/:id',

    },
    POST: {
      ADD: '/clinics',
    },
    PUT: {
      BY_ID: '/clinics/:id',
    },
    DELETE: {
      BY_ID: '/clinics/:id',
    },
  },
  LEARNERS: {
    GET: {
      ALL: '/learners',
      BY_ID: '/learners/:id',
    },
    POST: {
      ADD: '/learners',
    },
    PUT: {
      BY_ID: '/learners/:id',
    },
    DELETE: {
      BY_ID: '/learners/:id',
    },
  },
  SPECIALTIES: {
    GET: {
      ALL: '/specialties',
      BY_ID: '/specialties/:id',

    },
    POST: {
      ADD: '/specialties',
    },
    PUT: {
      BY_ID: '/specialties/:id',
    },
    DELETE: {
      BY_ID: '/specialties/:id',
    },
  },
  TEAMS: {
    GET: {
      TRADITIONAL: '/teams',
      SPECIAL: '/teams/special',
      BY_ID: '/teams/:id',
    },
    POST: {
      ADD: '/teams',
    },
    PUT: {
      BY_ID: '/teams/:id',
    },
    DELETE: {
      BY_ID: '/teams/:id',
    },
  },
  SPECIALTY_USERS: {
    GET: {
      ALL: '/specialties-users',
    },
  },
  LEARNER_TEAM: {
    GET: {
      BY_LEARNER_ID_AND_TEAM_ID: '/learner-team/learner/:learnerId/team/:teamId',
    },
  },
  REPORTS: {
    POST: {
      ADD: '/reports',
      SEND: '/reports/:id',
    },
    DELETE: {
      BY_LEARNER_AND_TEAM: '/reports/learner/:learnerId/team/:teamId',
      BY_TEAM: '/reports/team/:teamId',
    },
  },
  GOALS: {
    POST: {
      ADD: '/goals',
    },
    PUT: {
      BY_ID: '/goals/:id',
    },
    DELETE: {
      BY_ID: '/goals/:id',
    },
    GET: {
      QUERY: '/goals?query=:query',
    },
  },
  REPORT_SPECIALTIES: {
    POST: {
      ADD: '/report-specialties',
    },
    PUT: {
      BY_ID: '/report-specialties/:id',
    },
  },
  BEHAVIORAL_ASPECTS: {
    GET: {
      ALL: '/behavioral-aspects',
    },
  },
  REPORT_SPECIALTY_BEHAVIORAL_ASPECTS: {
    POST: {
      ONE: '/report-specialty-behavioral-aspects',
    },
  },
  REPORT_SPECIALTY_IMAGES: {
    POST: {
      ONE: '/report-specialty-images',
    },
    GET: {
      BY_ID: '/report-specialty-images/:id',
    },
    DELETE: {
      BY_ID: '/report-specialty-images/:id',
    },
  },
  HIGHLIGHTS: {
    GET: {
      ALL: '/highlights',
      BY_ID: '/highlights/:id',
    },
    POST: {
      ADD: '/highlights',
    },
    PUT: {
      BY_ID: '/highlights/:id',
    },
    DELETE: {
      BY_ID: '/highlights/:id',
    },
  },
  INFO_FILES: {
    GET: {
      ALL: '/info-files',
      BY_ID: '/info-files/:id',
    },
    POST: {
      ADD: '/info-files',
    },
    PUT: {
      BY_ID: '/info-files/:id',
    },
    DELETE: {
      BY_ID: '/info-files/:id',
    },
  },
};

export const PERMISSIONS = {
  HOME: {
    LIST: [ROLES.ADMIN, ROLES.CLINIC, ROLES.THERAPIST],
  },
  USERS: {
    CREATE: [ROLES.ADMIN, ROLES.CLINIC],
    EDIT: [ROLES.ADMIN, ROLES.CLINIC],
    REMOVE: [ROLES.ADMIN, ROLES.CLINIC],
    LIST: [ROLES.ADMIN, ROLES.CLINIC],
    VIEW: [ROLES.ADMIN, ROLES.CLINIC],
  },
  CLINICS: {
    CREATE: [ROLES.ADMIN],
    EDIT: [ROLES.ADMIN],
    REMOVE: [ROLES.ADMIN],
    LIST: [ROLES.ADMIN],
    VIEW: [ROLES.ADMIN],
  },
  LEARNERS: {
    CREATE: [ROLES.CLINIC],
    EDIT: [ROLES.CLINIC],
    REMOVE: [ROLES.CLINIC],
    LIST: [ROLES.CLINIC],
    VIEW: [ROLES.CLINIC, ROLES.THERAPIST],
  },
  SPECIALTIES: {
    CREATE: [ROLES.CLINIC],
    EDIT: [ROLES.CLINIC],
    REMOVE: [ROLES.CLINIC],
    LIST: [ROLES.CLINIC],
    VIEW: [ROLES.CLINIC],
  },
  TEAMS: {
    CREATE: [ROLES.CLINIC],
    EDIT: [ROLES.CLINIC],
    REMOVE: [ROLES.CLINIC],
    LIST: [ROLES.CLINIC, ROLES.THERAPIST],
    VIEW: [ROLES.CLINIC, ROLES.THERAPIST],
  },
  REPORTS: {
    REMOVE: [ROLES.CLINIC],
  },
  HIGHLIGHTS: {
    CREATE: [ROLES.ADMIN],
    EDIT: [ROLES.ADMIN],
    REMOVE: [ROLES.ADMIN],
    LIST: [ROLES.ADMIN],
    VIEW: [ROLES.ADMIN],
  },
  INFO_FILES: {
    CREATE: [ROLES.CLINIC],
    EDIT: [ROLES.CLINIC],
    REMOVE: [ROLES.CLINIC],
    LIST: [ROLES.CLINIC, ROLES.THERAPIST],
    VIEW: [ROLES.CLINIC, ROLES.THERAPIST],
  },
};
