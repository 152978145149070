import React, { useEffect, useMemo, useState } from 'react';

import intl from 'react-intl-universal';

import { ENDPOINTS, PERMISSIONS, ROUTES, UTILS } from 'shared/constants';
import { initIntl } from 'shared/locales';
import api from 'shared/api';

import Highlight from 'models/Highlight';

import { useAppSelector } from 'store/hooks';

import Table, { Field } from 'components/Table';
import { Type } from 'components/NoResultsBox';

initIntl();

const getColumns = () => {
  return [
    intl.get('pages.infoFiles.id'),
    intl.get('pages.infoFiles.name'),
  ];
};

const getFields = (): Field[] => {
  return [
    {
      name: 'id',
    },
    {
      name: 'name',
    },
  ];
};

/**
 * InfoFIles component
 * @return {JSX.Element}
 */
function InfoFIles(): JSX.Element {
  const [infoFiles, setInfoFIles] = useState<Highlight[]>([]);
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    getInfoFIles();
  }, []);

  const fields = useMemo(() => {
    return getFields();
  }, []);

  const columns = useMemo(() => {
    return getColumns();
  }, []);

  const selects = useMemo(() => {
    return [];
  }, []);

  const getInfoFIles = async () => {
    const response = await api.get(ENDPOINTS.INFO_FILES.GET.ALL);
    setInfoFIles(response.data);
  };

  return (
    <>
      {
        user ? <Table
          type={Type.highlight}
          columns={columns}
          fields={fields}
          onRefresh={getInfoFIles}

          filter={{
            data: infoFiles,
            searchMatchFields: ['name'],
            searchPlaceholder: intl.get('pages.infoFiles.searchPlaceholder'),
            selects: selects,
          }}

          deleteDialog={{
            titleIntlKey: 'pages.infoFiles.deleteTitleDialog',
            descriptionIntlKey: 'pages.infoFiles.deleteDescriptionDialog',
          }}

          actions={{
            addPath: PERMISSIONS.INFO_FILES.CREATE.includes(user.role.id.toString()) ? ROUTES.INFO_FILES_ADD : undefined,
            editPath: PERMISSIONS.INFO_FILES.EDIT.includes(user.role.id.toString()) ? ROUTES.INFO_FILES_EDIT : undefined,
            deleteEndpoint: PERMISSIONS.INFO_FILES.REMOVE.includes(user.role.id.toString()) ? ENDPOINTS.INFO_FILES.DELETE.BY_ID : undefined,
            downloadPath: PERMISSIONS.INFO_FILES.VIEW.includes(user.role.id.toString()) ?
              `${ENDPOINTS.INFO_FILES.GET.BY_ID}${UTILS.QUESTION}download${UTILS.EQUAL}download` :
              undefined,
          }}
        /> : null
      }
    </>
  );
}

export default InfoFIles;
