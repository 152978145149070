import React from 'react';

import { Button, Grid } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import intl from 'react-intl-universal';
import { TextField } from 'formik-material-ui';
import { Formik, Field } from 'formik';
import * as yup from 'yup';

import { useAppDispatch } from 'store/hooks';
import { createPassword } from 'store/features/auth/authSlice';
import { ROUTES, UTILS } from 'shared/constants';

interface FormData {
  password: string;
  passwordConfirmation: string;
}

const validationSchema = yup.object({
  password: yup
    .string()
    .required(intl.get('validation.requiredField'))
    .min(UTILS.PASSWORD_MIN_LEN, intl.get('validation.passwordMin'))
    .max(UTILS.STRING_MAX_LEN),
  passwordConfirmation: yup.string()
    .oneOf([yup.ref('password'), null], intl.get('validation.passwordMustMatch')),
});

/**
 * Create password form component
 * @return {JSX.Element}
 */
function CreatePasswordForm(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id, token } = useParams<{ id: string, token: string }>();
  const initialValues = {
    password: UTILS.BLANK,
    passwordConfirmation: UTILS.BLANK,
  };

  const onCreatePassword = async (values: FormData) => {
    if (id && token) {
      await dispatch(createPassword({
        id,
        token,
        ...values,
      }));
    }
  };

  return (<Formik
    enableReinitialize
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={async (values, { setSubmitting }) => {
      setSubmitting(true);
      await onCreatePassword(values);
      setSubmitting(false);
      navigate(ROUTES.ROOT);
    }}
  >
    {({ submitForm, isSubmitting }) => (
      <>
        <Grid item xs={12}>
          <Field
            component={TextField}
            fullWidth
            variant="outlined"
            name="password"
            type="password"
            label={intl.get('pages.createPassword.newPassword')}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={TextField}
            fullWidth
            variant="outlined"
            name="passwordConfirmation"
            type="password"
            label={intl.get('pages.createPassword.confirmNewPassword')}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            onClick={submitForm}
          >
            {intl.get('pages.createPassword.storeNewPassword')}
          </Button>
        </Grid>
      </>
    )}
  </Formik>);
}

export default CreatePasswordForm;
