import React, { useEffect, useMemo, useState } from 'react';

import { Button, Grid, LinearProgress, Typography } from '@mui/material';

import intl from 'react-intl-universal';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { AxiosError } from 'axios';

import { useToastify } from 'hooks/toastfy';

import { displayError } from 'helpers/http';

import api from 'shared/api';
import { initIntl } from 'shared/locales';
import { UTILS, ENDPOINTS, ROUTES } from 'shared/constants';

import Breadcrumbs from 'components/Breadcrumbs';

interface FormData {
  name: string;
  email: string;
  birthdate: string;
  affiliation_1: string;
  affiliation_2: string;
}

initIntl();

const getValidation = () => yup.object({
  name: yup
    .string()
    .required(intl.get('validation.requiredField'))
    .max(UTILS.STRING_MAX_LEN),
  birthdate: yup
    .string()
    .nullable()
    .required(intl.get('validation.requiredField'))
    .max(UTILS.STRING_MAX_LEN),
  email: yup
    .string()
    .email()
    .required(intl.get('validation.requiredField'))
    .max(UTILS.STRING_MAX_LEN),
  affiliation_1: yup
    .string()
    .required(intl.get('validation.requiredField'))
    .max(UTILS.STRING_MAX_LEN),
  affiliation_2: yup
    .string()
    .required(intl.get('validation.requiredField'))
    .max(UTILS.STRING_MAX_LEN),
});

/**
 * FormLearner component
 * @return {JSX.Element}
 */
export default function FormLearner(): JSX.Element {
  const navigate = useNavigate();
  const toast = useToastify();
  const { id } = useParams<{ id: string }>();
  const [initialValues, setInitialValues] = useState<FormData>({
    name: UTILS.BLANK,
    birthdate: UTILS.BLANK,
    email: UTILS.BLANK,
    affiliation_1: UTILS.BLANK,
    affiliation_2: UTILS.BLANK,
  });

  const validationSchema = getValidation();

  useEffect(() => {
    if (id) {
      getLearner();
    }
  }, []);

  const breadcrumbs = useMemo(() => {
    return [
      {
        name: intl.get('pages.learners.title'),
        onClickCallback: () => navigate(ROUTES.LEARNERS),
      },
      {
        name: `${id ? intl.get('common.edit') : intl.get('common.add')}${UTILS.SPACE}${intl.get('pages.formLearner.title')}`,
        onClickCallback: () => { },
      },
    ];
  }, [id]);

  const getLearner = async () => {
    if (id) {
      const response = await api.get(ENDPOINTS.LEARNERS.GET.BY_ID.replace(':id', id));
      setInitialValues({
        name: response.data.name,
        email: response.data.email,
        birthdate: response.data.birthdate,
        affiliation_1: response.data.affiliation1,
        affiliation_2: response.data.affiliation2,
      });
    }
  };

  const save = async (values: FormData) => {
    try {
      if (id) {
        await api.put(ENDPOINTS.LEARNERS.PUT.BY_ID.replace(':id', id), values);
      } else {
        await api.post(ENDPOINTS.LEARNERS.POST.ADD, values);
      }
      toast.success(intl.get('toast.saveSuccess'));
    } catch (error) {
      displayError(error as AxiosError);
    }
  };

  return (
    <>
      <Breadcrumbs items={breadcrumbs} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await save(values);
          setSubmitting(false);
          navigate(ROUTES.LEARNERS);
        }}
      >
        {({ submitForm, isSubmitting, values }) => (
          <Form
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                submitForm();
              }
            }}
          >
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <Grid item sm={12} md={6}>
                  <Typography variant="h1">
                    {id ? intl.get('common.edit') : intl.get('common.add')} {intl.get('pages.formLearner.title')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item sm={12} md={6}>
                  <Field
                    component={TextField}
                    fullWidth
                    type="text"
                    label={intl.get('pages.formLearner.name')}
                    name="name"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item sm={12} md={6}>
                  <Field
                    component={TextField}
                    fullWidth
                    type="email"
                    label={intl.get('pages.formLearner.email')}
                    name="email"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item sm={12} md={6}>
                  <Field
                    InputLabelProps={{ shrink: true }}
                    value={values.birthdate || UTILS.BLANK}
                    component={TextField}
                    fullWidth
                    type="date"
                    label={intl.get('pages.formLearner.birthdate')}
                    name="birthdate"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item sm={12} md={6}>
                  <Field
                    component={TextField}
                    fullWidth
                    type="text"
                    label={intl.get('pages.formLearner.affiliation_1')}
                    name="affiliation_1"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item sm={12} md={6}>
                  <Field
                    component={TextField}
                    fullWidth
                    type="text"
                    label={intl.get('pages.formLearner.affiliation_2')}
                    name="affiliation_2"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item sm={12} md={6}>
                  {isSubmitting && <LinearProgress />}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                  <Button
                    variant="outlined"
                    onClick={() => navigate(-1)}
                  >
                    {intl.get('common.cancel')}
                  </Button>
                  &nbsp;
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    {intl.get('common.save')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
