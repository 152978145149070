/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';

import intl from 'react-intl-universal';
import { useNavigate, useParams } from 'react-router-dom';

import { ENDPOINTS, PERMISSIONS, ROUTES, UTILS } from 'shared/constants';
import { initIntl } from 'shared/locales';
import api from 'shared/api';

import { useAppSelector } from 'store/hooks';

import Team, { TeamLearner } from 'models/Team';
import User from 'models/User';

import Table, { Field } from 'components/Table';
import Breadcrumbs from 'components/Breadcrumbs';
import { Type } from 'components/NoResultsBox';

initIntl();

const getColumns = (user: User) => {
  return [
    intl.get('pages.teams.id'),
    intl.get('pages.teams.name'),
  ];
};

const getFields = (user: User): Field[] => {
  return [
    {
      name: 'id',
    },
    {
      name: 'name',
    },
  ];
};

/**
 * ViewTeam component
 * @return {JSX.Element}
 */
export default function ViewTeam(): JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { user } = useAppSelector((state) => state.auth);
  const [team, setTeam] = useState<Team>();

  useEffect(() => {
    if (id) {
      getTeam();
    }
  }, []);

  const getTeam = async () => {
    if (id) {
      const response = await api.get(ENDPOINTS.TEAMS.GET.BY_ID.replace(':id', id));
      setTeam(response.data);
    }
  };

  const fields = useMemo(() => {
    if (user) {
      return getFields(user);
    }
    return [];
  }, [user]);

  const columns = useMemo(() => {
    if (user) {
      return getColumns(user);
    }
    return [];
  }, [user]);

  const breadcrumbs = useMemo(() => {
    if (team) {
      return [
        {
          name: intl.get('pages.teams.title'),
          onClickCallback: () => navigate(ROUTES.TEAMS),
        },
        {
          name: team.name,
          onClickCallback: () => navigate(ROUTES.TEAMS_VIEW.replace(':id', team.id.toString())),
        },
      ];
    }
    return [];
  }, [team]);

  const viewPath = useMemo(() => {
    if (team && user) {
      // if (team.special) {
      //   return PERMISSIONS.LEARNERS.VIEW.includes(user.role.id.toString()) ? ROUTES.SPECIAL_TEAMS_LEARNERS_VIEW : undefined;
      // }
      return PERMISSIONS.LEARNERS.VIEW.includes(user.role.id.toString()) ? ROUTES.TEAMS_VIEW_LEARNERS_VIEW : undefined;
    }
    return UTILS.BLANK;
  }, [team, user]);

  const viewPathResolver = (learner: TeamLearner) => {
    if (team) {
      // if (team.special) {
      //   return ROUTES.SPECIAL_TEAMS_LEARNERS_VIEW.replace(':id', learner.id.toString())
      //     .replace(':teamId', team.id.toString());
      // }
      return ROUTES.TEAMS_VIEW_LEARNERS_VIEW.replace(':id', learner.id.toString())
        .replace(':teamId', team.id.toString());
    }
    return UTILS.BLANK;
  };

  const resetEndpointResolver = (learner: TeamLearner) => {
    if (team) {
      return ENDPOINTS.REPORTS.DELETE.BY_LEARNER_AND_TEAM
        .replace(':learnerId', learner.id.toString())
        .replace(':teamId', team.id.toString());
    }
    return UTILS.BLANK;
  };

  const resetAllEndpointResolver = () => {
    if (team) {
      return ENDPOINTS.REPORTS.DELETE.BY_TEAM
        .replace(':teamId', team.id.toString());
    }
    return UTILS.BLANK;
  };

  return <>
    {
      user && team ? <>
        <Breadcrumbs items={breadcrumbs} />
        <Table
          type={Type.learner}
          columns={columns}
          fields={fields}
          onRefresh={getTeam}

          filter={{
            data: team.learners,
            searchMatchFields: ['name'],
            searchPlaceholder: intl.get('pages.teams.searchPlaceholder'),
          }}

          actions={{
            viewPath,
            viewPathResolver,
            resetEndpoint: PERMISSIONS.REPORTS.REMOVE.includes(user.role.id.toString()) ? ENDPOINTS.REPORTS.DELETE.BY_LEARNER_AND_TEAM : undefined,
            resetAllEndpoint: PERMISSIONS.REPORTS.REMOVE.includes(user.role.id.toString()) ? ENDPOINTS.REPORTS.DELETE.BY_TEAM : undefined,
            resetEndpointResolver,
            resetAllEndpointResolver,
          }}

          resetDialog={{
            titleIntlKey: 'pages.viewTeam.resetTitleDialog',
            descriptionIntlKey: 'pages.viewTeam.resetDescriptionDialog',
          }}

          resetAllDialog={{
            titleIntlKey: 'pages.viewTeam.resetAllTitleDialog',
            descriptionIntlKey: 'pages.viewTeam.resetAllDescriptionDialog',
          }}
        />
      </> :
        null
    }
  </>;
}
