import React from 'react';

import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import MuiDialog from '@mui/material/Dialog';

import intl from 'react-intl-universal';

interface CommonProps {
  open: boolean;
  title: string;
  text: string;
  onCancel: () => void;
}

type ConditionalProps =
  // require onConfirm props when onButton is false or undefined
  | { oneButton?: false; onConfirm: () => void }
  // not require onConfirm props when onButton is true
  | { oneButton: true; onConfirm?: never };

type Props = CommonProps & ConditionalProps;

/**
 * Dialog component
 * @param {Props} props
 * @return {any}
 */
function Dialog(props: Props) {
  return (
    <div>
      <MuiDialog
        open={props.open}
        onClose={props.onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            padding: 2,
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" color="primary" sx={{ fontSize: '27px' }}>
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="text.primary" sx={{ fontSize: '20px' }}>
            {props.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {
            props.oneButton ?
              <Button
                onClick={props.onCancel}
                disableTouchRipple
                id="okButton"
                variant="contained"
                color="primary"
                fullWidth
              >
                {intl.get('components.dialog.ok')}
              </Button> :
              <>
                <Button
                  onClick={props.onCancel}
                  disableTouchRipple
                  id="cancelButton"
                  variant="outlined"
                  color="primary"
                  fullWidth
                >
                  {intl.get('components.dialog.no')}
                </Button>
                <Button
                  onClick={props.onConfirm}
                  disableTouchRipple
                  id="confirmButton"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  {intl.get('components.dialog.yes')}
                </Button>
              </>
          }
        </DialogActions>
      </MuiDialog>
    </div>
  );
}

export default Dialog;
