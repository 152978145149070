import React from 'react';
import { toast } from 'react-toastify';
import intl from 'react-intl-universal';
import Toast, { ToastTypes } from 'components/Toast';
import { initIntl } from 'shared/locales';

initIntl();

const toastify = ({
  success: (content: string) => toast.success(
    React.createElement(Toast, { type: ToastTypes.SUCCESS, content }), { toastId: 'successToast' },
  ),
  warning: (content: string) => toast.warning(
    React.createElement(Toast, { type: ToastTypes.WARNING, content }), { toastId: 'warningToast' },
  ),
  error: (content: string) => toast.error(
    React.createElement(Toast, { type: ToastTypes.ERROR, content }), { toastId: 'errorToast' },
  ),
  offline: () => toast.warning(
    React.createElement(Toast, { type: ToastTypes.WARNING, content: intl.get('toast.offline') }),
    { toastId: 'offlineToast', autoClose: false, closeOnClick: false },
  ),
});

export const useToastify = () => {
  return {
    ...toastify,
  };
};
