import React, { useState } from 'react';

import { FormControl, FormControlLabel, FormLabel, Grid, LinearProgress, Radio, RadioGroup, SxProps, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { Theme } from '@emotion/react';

import intl from 'react-intl-universal';
import { AxiosError } from 'axios';

import api from 'shared/api';
import { ENDPOINTS, ROLES, UTILS } from 'shared/constants';

import { BehavioralAspectsByType } from 'models/BehavioralAspect';
import { ReportSpecialtyBehavioralAspect } from 'models/ReportSpecialtyBehavioralAspect';

import { displayError } from 'helpers/http';

import { useToastify } from 'hooks/toastfy';

import { useAppSelector } from 'store/hooks';

import ConditionalContainer from 'components/ConditionalContainer';
import ObservationInput from './ObservationInput';

const radioStyle: SxProps<Theme> = {
  'color': red[800],
  '&.Mui-checked': {
    color: red[600],
  },
};

interface Props {
  behavioralAspects: BehavioralAspectsByType
  userBelongsToSpecialty: boolean
  reportSpecialtyId: string
  reportSpecialtyBehavioralAspects: Map<number, ReportSpecialtyBehavioralAspect>
  setReportSpecialtyBehavioralAspects: (value: Map<number, ReportSpecialtyBehavioralAspect>) => void
  shouldRefresh: () => {}
  onChangeBehavioralObsInputs: (value: string) => void
  onChangeCognitiveObsInputs: (value: string) => void
  onChangeAdaptiveObsInputs: (value: string) => void
  onSaveObsInputs: () => void;
  behavioralObsInitialValue: string
  adaptiveObsInitialValue: string
  cognitiveObsInitialValue: string
  isSubmitingObsInputs: boolean
}

const types: (keyof BehavioralAspectsByType)[] = ['behavioral', 'cognitive', 'adaptive'];

const defaultValues = [
  intl.get('pages.viewLearner.map.deficit'),
  intl.get('pages.viewLearner.map.regular'),
  intl.get('pages.viewLearner.map.great'),
];

const behavioralValues = [
  intl.get('pages.viewLearner.map.always'),
  intl.get('pages.viewLearner.map.eventually'),
  intl.get('pages.viewLearner.map.never'),
];

/**
 * BehavioralAspects component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function BehavioralAspects(props: Props): JSX.Element {
  const { user } = useAppSelector((state) => state.auth);
  const toast = useToastify();
  const [isSubmiting, setIsSubmiting] = useState(false);

  const inputObsSetMethods: { [key: string]: Function } = {
    behavioral: props.onChangeBehavioralObsInputs,
    cognitive: props.onChangeCognitiveObsInputs,
    adaptive: props.onChangeAdaptiveObsInputs,
  };

  const inputObsStates: { [key: string]: string } = {
    behavioral: props.behavioralObsInitialValue,
    adaptive: props.adaptiveObsInitialValue,
    cognitive: props.cognitiveObsInitialValue,
  };

  const handleBehavioralAspectChange = async (frequency: string, behavioralAspectId: number) => {
    try {
      const newReportSpecialtyBehavioralAspects = new Map(props.reportSpecialtyBehavioralAspects);

      newReportSpecialtyBehavioralAspects.set(behavioralAspectId, {
        id: 0,
        reportSpecialtyId: parseInt(props.reportSpecialtyId),
        behavioralAspectId,
        frequency,
      });

      props.setReportSpecialtyBehavioralAspects(newReportSpecialtyBehavioralAspects);

      const data = {
        frequency,
        'report_specialty_id': props.reportSpecialtyId,
        'behavioral_aspect_id': behavioralAspectId,
      };

      setIsSubmiting(true);
      const response = await api.post(ENDPOINTS.REPORT_SPECIALTY_BEHAVIORAL_ASPECTS.POST.ONE, data);
      setIsSubmiting(false);
      props.shouldRefresh();

      newReportSpecialtyBehavioralAspects.set(behavioralAspectId, {
        id: response.data.id,
        reportSpecialtyId: parseInt(props.reportSpecialtyId),
        behavioralAspectId,
        frequency,
      });

      props.setReportSpecialtyBehavioralAspects(newReportSpecialtyBehavioralAspects);
      toast.success(intl.get('toast.saveSuccess'));
    } catch (error) {
      setIsSubmiting(false);
      displayError(error as AxiosError);
    }
  };

  const handleBehavioralAspectValue = (behavioralAspectId: number) => {
    if (props.reportSpecialtyBehavioralAspects.has(behavioralAspectId)) {
      return props.reportSpecialtyBehavioralAspects.get(behavioralAspectId)?.frequency;
    }
    return UTILS.BLANK;
  };

  const getBehavioralAspectValue = (type: keyof BehavioralAspectsByType, index: number) => {
    if (type === 'behavioral') {
      return behavioralValues[index];
    }
    return defaultValues[index];
  };

  return (<>
    {
      types.flatMap((type) => {
        const behavioralAspects = props.behavioralAspects[type].map((behavioralAspect, index) => {
          const value = handleBehavioralAspectValue(behavioralAspect.id);
          return <Grid key={`${type}${behavioralAspect.id}`} item xs={12}>
            <FormControl>
              <FormLabel sx={{ mb: 1 }} id="considerations-radio-buttons-group-label">
                {behavioralAspect.description}
              </FormLabel>
              <RadioGroup
                aria-labelledby="considerations-radio-buttons-group-label"
                onChange={(e) => handleBehavioralAspectChange((e.target as HTMLInputElement).value, behavioralAspect.id)}
                value={value}
                row
              >
                <FormControlLabel
                  sx={{ mb: 1 }}
                  value={getBehavioralAspectValue(type, 0)}
                  control={<Radio sx={!value ? radioStyle : undefined} />}
                  label={getBehavioralAspectValue(type, 0)}
                  disabled={(user && user.role.id.toString() === ROLES.THERAPIST && !props.userBelongsToSpecialty) || isSubmiting}
                />
                <FormControlLabel
                  sx={{ mb: 1 }}
                  value={getBehavioralAspectValue(type, 1)}
                  control={<Radio sx={!value ? radioStyle : undefined} />}
                  label={getBehavioralAspectValue(type, 1)}
                  disabled={(user && user.role.id.toString() === ROLES.THERAPIST && !props.userBelongsToSpecialty) || isSubmiting}
                />
                <FormControlLabel
                  sx={{ mb: 1 }}
                  value={getBehavioralAspectValue(type, 2)}
                  control={<Radio sx={!value ? radioStyle : undefined} />}
                  label={getBehavioralAspectValue(type, 2)}
                  disabled={(user && user.role.id.toString() === ROLES.THERAPIST && !props.userBelongsToSpecialty) || isSubmiting}
                />
              </RadioGroup>
            </FormControl>
          </Grid>;
        });

        behavioralAspects.unshift(<Grid item xs={12} key={`${type}title`}>
          <Typography color="text.primary" fontWeight={'bold'}>{intl.get(`pages.viewLearner.map.behavior.${type}`)}</Typography>
        </Grid>);

        behavioralAspects.push(<ObservationInput
          key={`${type}obsInput`}
          isSubmitingObsInputs={props.isSubmitingObsInputs}
          userBelongsToSpecialty={props.userBelongsToSpecialty}
          initialValue={inputObsStates[type]}
          onChange={(value: string) => inputObsSetMethods[type](value)}
          onSave={props.onSaveObsInputs}
          type={type}
        />);

        return behavioralAspects;
      })
    }
    <ConditionalContainer noComponent checkIf={isSubmiting}>
      <Grid item xs={12} mb={3} mt={1}>
        <Grid item xs={12} md={6}>
          {isSubmiting && <LinearProgress />}
        </Grid>
      </Grid>
    </ConditionalContainer>
  </>);
}
