import React, { useEffect, useMemo, useState } from 'react';

import intl from 'react-intl-universal';

import { ENDPOINTS, PERMISSIONS, ROUTES } from 'shared/constants';
import { initIntl } from 'shared/locales';
import api from 'shared/api';

import Highlight from 'models/Highlight';

import { useAppSelector } from 'store/hooks';

import Table, { Field } from 'components/Table';
import { Type } from 'components/NoResultsBox';

initIntl();

const getColumns = () => {
  return [
    intl.get('pages.highlights.id'),
    intl.get('pages.highlights.name'),
    intl.get('pages.highlights.clinic'),
  ];
};

const getFields = (): Field[] => {
  return [
    {
      name: 'id',
    },
    {
      name: 'name',
    },
    {
      name: 'clinic.name',
    },
  ];
};

/**
 * Highlights component
 * @return {JSX.Element}
 */
function Highlights(): JSX.Element {
  const [highlights, setHighlights] = useState<Highlight[]>([]);
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    getHighlights();
  }, []);

  const fields = useMemo(() => {
    return getFields();
  }, []);

  const columns = useMemo(() => {
    return getColumns();
  }, []);

  const selects = useMemo(() => {
    return [];
  }, []);

  const getHighlights = async () => {
    const response = await api.get(ENDPOINTS.HIGHLIGHTS.GET.ALL);
    setHighlights(response.data);
  };

  return (
    <>
      {
        user ? <Table
          type={Type.highlight}
          columns={columns}
          fields={fields}
          onRefresh={getHighlights}

          filter={{
            data: highlights,
            searchMatchFields: ['name'],
            searchPlaceholder: intl.get('pages.highlights.searchPlaceholder'),
            selects: selects,
          }}

          deleteDialog={{
            titleIntlKey: 'pages.highlights.deleteTitleDialog',
            descriptionIntlKey: 'pages.highlights.deleteDescriptionDialog',
          }}

          actions={{
            addPath: PERMISSIONS.HIGHLIGHTS.CREATE.includes(user.role.id.toString()) ? ROUTES.HIGHLIGHTS_ADD : undefined,
            editPath: PERMISSIONS.HIGHLIGHTS.EDIT.includes(user.role.id.toString()) ? ROUTES.HIGHLIGHTS_EDIT : undefined,
            deleteEndpoint: PERMISSIONS.HIGHLIGHTS.REMOVE.includes(user.role.id.toString()) ? ENDPOINTS.HIGHLIGHTS.DELETE.BY_ID : undefined,
          }}
        /> : null
      }
    </>
  );
}

export default Highlights;
