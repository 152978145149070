import React from 'react';
import { ToastContentProps } from 'react-toastify';
import { Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { Theme } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import defaultTheme from 'shared/theme';
import ConditionalContainer from 'components/ConditionalContainer';

const containerStyle = (type: string): SxProps<Theme> => ({
  color: type === ToastTypes.WARNING ? defaultTheme.palette.common.black : defaultTheme.palette.common.white,
});

const closeButtonStyle = (type: string): SxProps<Theme> => ({
  color: type === ToastTypes.WARNING ? defaultTheme.palette.common.black : defaultTheme.palette.common.white,
  padding: 'unset',
});

const closeIconStyle = (smDown: boolean): SxProps<Theme> => ({
  width: smDown ? '30px' : '43px',
  height: smDown ? '30px' : '43px',
});

const typeIconStyle = (smDown: boolean): SxProps<Theme> => ({
  width: smDown ? '30px' : '43px',
  height: smDown ? '30px' : '43px',
});

const textStyle = (smDown: boolean): SxProps<Theme> => ({
  marginLeft: smDown ? '11px' : '11px',
  marginRight: smDown ? '11px' : '11px',
  fontWeight: 600,
  fontSize: smDown ? 16 : 18,
});

export enum ToastTypes {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

interface Props {
  type: ToastTypes
  content: string
}

/**
 * Toast Component
 * @param {Props} props
 * @return {JSX.Element}
 */
function Toast(props: Props & Partial<ToastContentProps>): JSX.Element {
  const smDown = useMediaQuery(defaultTheme.breakpoints.down('md'));
  const id: string = (props.toastProps?.toastId || `${props.type}Toast`).toString();
  const toastTypes: { [key: string]: SvgIconComponent } = {
    [ToastTypes.SUCCESS]: CheckIcon,
    [ToastTypes.WARNING]: WarningIcon,
    [ToastTypes.ERROR]: ReportProblemIcon,
  };

  const Icon = toastTypes[props.type];
  return <Grid
    container
    alignItems='center'
    justifyContent='space-between'
    sx={containerStyle(props.type)}
    id={id}
  >
    <Grid item xs={1}><Icon color='inherit' sx={typeIconStyle(smDown)} /></Grid>
    <Grid item xs={props.type !== ToastTypes.WARNING ? 10 : 11}>
      <Grid container justifyContent={'center'}>
        <Typography sx={textStyle(smDown)}>{props.content}</Typography>
      </Grid>
    </Grid>
    <ConditionalContainer noComponent checkIf={props.type !== ToastTypes.WARNING}>
      <Grid item xs={1}>
        <IconButton
          disableRipple
          onClick={props.closeToast}
          sx={closeButtonStyle(props.type)}
        >
          <CloseIcon color='inherit' sx={closeIconStyle(smDown)} />
        </IconButton>
      </Grid>
    </ConditionalContainer>
  </Grid>;
}

export default Toast;
