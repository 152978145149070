import axios from 'axios';

import { API_HOST, ENDPOINTS, ERROR_STATUS, ROUTES } from './constants';

const instance = axios.create({
  baseURL: `${API_HOST}`,
});

instance.defaults.withCredentials = true;

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.config.url !== ENDPOINTS.USERS.GET.AUTHENTICATED &&
          error.response.config.url !== ENDPOINTS.AUTH.POST.LOGIN
    ) {
      if (error.config.method === 'get') {
        if (error.response.status === ERROR_STATUS.UNAUTHORIZED) {
          window.location.href = ROUTES.LOGIN;
        }
      }
    }
    return Promise.reject(error);
  });

export default instance;
