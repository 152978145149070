/* eslint-disable quote-props */
import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import { Bar, BarChart, CartesianGrid, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import intl from 'react-intl-universal';

import Goal from 'models/Goal';

const colors: { [key: number]: string } = {
  1: 'red',
  2: 'red',
  3: 'red',
  4: 'yellow',
  5: 'yellow',
  6: 'green',
  7: 'green',
  8: 'blue',
  9: 'blue',
  10: 'blue',
};

interface CustomBarProps {
  dataKey: string
}

interface GraphData {
  name: string
  pontuação: number
}

interface Props {
  hide?: boolean
  specialtyId: number
  goals: Goal[]
}

/**
 * GoalsChart component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function GoalsChart(props: Props): JSX.Element {
  const [data, setData] = useState<GraphData[]>([]);

  useEffect(() => {
    const newData = props.goals.map((goal, index) => ({
      name: `${intl.get('components.reportPDF.goalsChart.goal')} ${index + 1}`,
      pontuação: goal.score || 0,
    }));
    setData(newData);
  }, [props.goals]);

  const CustomBar = (props: CustomBarProps & GraphData) => {
    return <Rectangle
      {...props}
      fill={colors[props.pontuação]}
    />;
  };

  return <Box height={'200px'} sx={{ width: '350px', display: props.hide ? 'none' : undefined }} id={`chart${props.specialtyId}`}>
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: -30,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" style={{ fontSize: '15px' }} />
        <YAxis ticks={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]} />
        <Tooltip />
        <Bar fontSize={'40px'} dataKey="pontuação" shape={CustomBar} />
      </BarChart>
    </ResponsiveContainer>
  </Box>;
}
