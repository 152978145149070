import { PERMISSIONS, ROLES, ROUTES } from 'shared/constants';

import User from 'models/User';

 type Item = {
   id: string;
   name_pt: string;
   name_en?: string;
   path: string;
   folder: string;
   icon?: React.ComponentType;
   allowed: string[]
 };

const items = (user: User): Item[] => ([
  {
    id: 'HOME',
    name_pt: 'Início',
    path: ROUTES.ROOT,
    folder: 'Home',
    allowed: PERMISSIONS.HOME.LIST,
  },
  {
    id: 'HIGHLIGHTS',
    name_pt: 'Avisos',
    path: ROUTES.HIGHLIGHTS,
    folder: 'highlights',
    allowed: PERMISSIONS.HIGHLIGHTS.LIST,
  },
  {
    id: 'INFO_FILES',
    name_pt: 'Arquivos',
    path: ROUTES.INFO_FILES,
    folder: 'infoFiles',
    allowed: PERMISSIONS.INFO_FILES.LIST,
  },
  {
    id: 'CLINICS',
    name_pt: 'Clínicas',
    path: ROUTES.CLINICS,
    folder: 'clinics',
    allowed: PERMISSIONS.CLINICS.LIST,
  },
  {
    id: 'USERS',
    name_pt: user.role.id.toString() === ROLES.CLINIC ? 'Terapeutas' : 'Usuários(as)',
    path: ROUTES.USERS,
    folder: 'users',
    allowed: PERMISSIONS.USERS.LIST,
  },
  {
    id: 'SPECIALTIES',
    name_pt: 'Especialidades',
    path: ROUTES.SPECIALTIES,
    folder: 'specialties',
    allowed: PERMISSIONS.SPECIALTIES.LIST,
  },
  {
    id: 'LEARNERS',
    name_pt: 'Aprendentes',
    path: ROUTES.LEARNERS,
    folder: 'learners',
    allowed: PERMISSIONS.LEARNERS.LIST,
  },
  {
    id: 'TEAMS',
    name_pt: 'Grupo Terapeuticos',
    path: ROUTES.TEAMS,
    folder: 'teams',
    allowed: PERMISSIONS.TEAMS.LIST,
  },
  // {
  //   id: 'SPECIAL_TEAMS',
  //   name_pt: 'Salas Multifuncionais',
  //   path: ROUTES.SPECIAL_TEAMS,
  //   folder: 'teams',
  //   allowed: PERMISSIONS.TEAMS.LIST,
  // },
]);

export default items;
