import React from 'react';

/**
 * ConditionalContainerProps component
 * @param {boolean} checkIf condition to show or not the children content
 * @param {boolean} noComponent condition to build the container without the div element
 */
interface ConditionalContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  checkIf?: boolean;
  noComponent?: boolean;
}

/**
  * Container component to do custom things
  * @param {ConditionalContainerProps} props
  * @return {JSX.Element | null}
  */
export default function ConditionalContainer(props: ConditionalContainerProps): JSX.Element | null {
  if (props.checkIf === undefined || props.checkIf) {
    if (props.noComponent) {
      return <>{props.children}</>;
    }
    // keeping only the div props to return
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { checkIf, noComponent, ...divProps } = props;
    return <div {...divProps}></div>;
  }
  return null;
}
