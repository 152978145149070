import React, { useEffect, useMemo, useState } from 'react';

import { FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';

import intl from 'react-intl-universal';
import { AxiosError } from 'axios';

import { useToastify } from 'hooks/toastfy';

import { useAppSelector } from 'store/hooks';

import { ENDPOINTS, ROLES, UTILS } from 'shared/constants';
import api from 'shared/api';

import { displayError } from 'helpers/http';

import Team from 'models/Team';
import Report, { ReportSpecialty } from 'models/Report';
import User from 'models/User';

import ConditionalContainer from 'components/ConditionalContainer';
import { SelectOption } from 'components/Table/Filter';
import GoalDescription from './GoalDescription';
import { minGoals } from '../Pdt';

interface Props {
  team: Team
  report: Report
  user: User
  specialtyOptions: SelectOption[]
  loggedSpecialtyIds: string[]
  onRefresh: () => {}
  shouldRefresh: () => {}
}

/**
 * Dsdt component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function Dsdt(props: Props): JSX.Element {
  const toast = useToastify();
  const { user } = useAppSelector((state) => state.auth);
  const [goals, setGoals] = useState<string[]>([]);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [specialtyId, setSpecialtyId] = useState(UTILS.BLANK);
  const [reportSpecialtyId, setReportSpecialtyId] = useState(UTILS.BLANK);

  useEffect(() => {
    if (props.specialtyOptions.length && !specialtyId) {
      if (user && user.role.id.toString() === ROLES.CLINIC) {
        setSpecialtyId(props.specialtyOptions[0].value);
      } else {
        setSpecialtyId(props.loggedSpecialtyIds[0]);
      }
    }
  }, [props.specialtyOptions]);

  useEffect(() => {
    if (specialtyId) {
      const newGoals = props.report.reportSpecialties
        .filter((reportSpecialty: ReportSpecialty) => reportSpecialty.specialtyId.toString() === specialtyId)
        .flatMap((reportSpecialty: ReportSpecialty) => {
          setReportSpecialtyId(reportSpecialty.id.toString());
          return [
            reportSpecialty.week1,
            reportSpecialty.week2,
            reportSpecialty.week3,
            reportSpecialty.week4,
            reportSpecialty.week5,
            reportSpecialty.week6,
            reportSpecialty.week7,
            reportSpecialty.week8,
            reportSpecialty.week9,
            reportSpecialty.week10,
            reportSpecialty.week11,
            reportSpecialty.week12,
          ];
        });
      setGoals(newGoals);
    }
  }, [specialtyId, props.report]);

  const userBelongsToSpecialty = useMemo(() => props.loggedSpecialtyIds.includes(specialtyId), [props.loggedSpecialtyIds, specialtyId]);

  const specialtiesLabel = useMemo(() => props.team.special ?
    intl.get('pages.viewLearner.contentLabel') :
    intl.get('pages.viewLearner.specialtyLabel'), [props]);

  const handleSpecialtyChange = (event: SelectChangeEvent) => {
    props.onRefresh();
    setSpecialtyId(event.target.value as string);
  };

  const handleChangeGoal = (description: string, index: number) => {
    const goalsCopy = [...goals];
    goalsCopy[index] = description;
    setGoals(goalsCopy);
  };

  const handleSaveReportSpecialty = async (index: number, description: string) => {
    try {
      setIsSubmiting(true);
      const goalsCopy = [...goals];
      goalsCopy[index] = description;

      const data = props.report.reportSpecialties
        .filter((reportSpecialty: ReportSpecialty) => reportSpecialty.specialtyId.toString() === specialtyId)
        .flatMap((reportSpecialty: ReportSpecialty) => ({
          week_1: goalsCopy['0'],
          week_2: goalsCopy['1'],
          week_3: goalsCopy['2'],
          week_4: goalsCopy['3'],
          week_5: goalsCopy['4'],
          week_6: goalsCopy['5'],
          week_7: goalsCopy['6'],
          week_8: goalsCopy['7'],
          week_9: goalsCopy['8'],
          week_10: goalsCopy['9'],
          week_11: goalsCopy['10'],
          week_12: goalsCopy['11'],
        }))
        .shift();

      await api.put(ENDPOINTS.REPORT_SPECIALTIES.PUT.BY_ID.replace(':id', reportSpecialtyId), data);

      props.shouldRefresh();
      setIsSubmiting(false);
      toast.success(intl.get('toast.saveSuccess'));
      setGoals(goalsCopy);
    } catch (error) {
      setIsSubmiting(false);
      displayError(error as AxiosError);
    }
  };

  return (<Grid container alignItems={'center'} columnGap={6} rowSpacing={3}>
    <Grid item xs={12} md={6}>
      <FormControl fullWidth>
        <InputLabel id="specialty-options-label">{specialtiesLabel}</InputLabel>
        <Select
          labelId="specialty-options-label"
          id="specialty-options-select"
          value={specialtyId}
          label={specialtiesLabel}
          onChange={handleSpecialtyChange}
        >
          {
            props.specialtyOptions && props.specialtyOptions.length ?
              props.specialtyOptions.map((specialtyOption, index) => <MenuItem key={index} value={specialtyOption.value}>
                {specialtyOption.label}
              </MenuItem>) :
              <MenuItem value={UTILS.BLANK}>
                {intl.get('pages.viewLearner.noOptions')}
              </MenuItem>
          }
        </Select>
      </FormControl>
    </Grid>
    {
      goals.map((goal, index) => <Grid key={index} item xs={12} md={6}>
        <GoalDescription
          goal={goal}
          index={index}
          isSubmiting={isSubmiting}
          userBelongsToSpecialty={userBelongsToSpecialty}
          onSave={handleSaveReportSpecialty}
          onChange={handleChangeGoal}
        />
      </Grid>)
    }
    <ConditionalContainer noComponent checkIf={goals.length < minGoals}>
      <Grid item xs={12}>
        <Typography color={'primary'} variant="h2" fontWeight={'bold'}>
          {
            userBelongsToSpecialty || (user && user.role.id.toString() === ROLES.CLINIC) ?
              intl.get('pages.viewLearner.dsdt.noGoalsCreateTip') :
              intl.get('pages.viewLearner.dsdt.noGoalsInfoTip')
          }
        </Typography>
      </Grid>
    </ConditionalContainer>
    <ConditionalContainer noComponent checkIf={isSubmiting}>
      <Grid item xs={12} mb={3} mt={1}>
        <Grid item xs={12} md={6}>
          {isSubmiting && <LinearProgress />}
        </Grid>
      </Grid>
    </ConditionalContainer>
  </Grid>);
}
