import React, { useEffect, useState } from 'react';

import AxiosApi from 'shared/api';
import { ENDPOINTS, ROUTES, UTILS } from '../../shared/constants';

import Loading from './Loading';

interface Props {
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
}

/**
 * Check if given url should not render Loading
 * @param {string|undefined} url
 * @return {boolean}
 */
function avoidLoading(url: string | undefined): boolean {
  if (!url) {
    return false;
  }

  const ignored: string[] = [
    ENDPOINTS.GOALS.GET.QUERY.replace(':query', UTILS.BLANK),
  ];

  for (const part of ignored) {
    if (url.indexOf(part) > -1) {
      return true;
    }
  }

  return false;
};

/**
  * RequestInterceptor component
  * @param {Props} props
  * @return {JSX.Element}
  */
function RequestInterceptor(props: Props): JSX.Element {
  const getMethod = 'get';
  const requestCounterKey = 'SOP_II_reqCounter';
  const [startCounter, setStartCounter] = useState(false);

  const resetCounter = () => localStorage.setItem(requestCounterKey, '0');

  useEffect(() => {
    if (!startCounter) {
      setStartCounter(true);
    }
  }, []);

  if (!startCounter) {
    resetCounter();
  }

  const handleDelete = (url: string, method: string) => {
    if (method === getMethod && navigator.onLine && url && !avoidLoading(url)) {
      const currentCounter = localStorage.getItem(requestCounterKey);
      const newCounter = currentCounter ? parseInt(currentCounter) - 1 : 0;
      localStorage.setItem(requestCounterKey, newCounter.toString());
      if (newCounter < 1) {
        props.setIsLoading(false);
      }
    }
  };

  AxiosApi.interceptors.request.use(
    (request) => {
      if (request.method === getMethod && navigator.onLine && request.url && !avoidLoading(request.url)) {
        props.setIsLoading(true);
        const currentCounter = localStorage.getItem(requestCounterKey);
        const newCounter = currentCounter ? parseInt(currentCounter) + 1 : 1;
        localStorage.setItem(requestCounterKey, newCounter.toString());
      }

      return request;
    },
  );

  AxiosApi.interceptors.response.use(
    (response) => {
      if (response.config.method && response.config.url) {
        handleDelete(response.config.url, response.config.method);
      }
      return response;
    },
    (error) => {
      if (error.response) {
        handleDelete(error.response.config.url, error.response.config.method);
      } else {
        resetCounter();
        props.setIsLoading(false);
      }
      return Promise.reject(error);
    },
  );

  return (<Loading open={props.isLoading} />);
};

export default RequestInterceptor;
