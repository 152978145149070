import React, { CSSProperties } from 'react';

import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';
import intl from 'react-intl-universal';

import { useAppDispatch } from 'store/hooks';
import { login } from 'store/features/auth/authSlice';

import { ROUTES, UTILS } from 'shared/constants';
import defaultTheme from 'shared/theme';
import { initIntl } from 'shared/locales';

interface FormData {
  email: string;
  password: string;
}

initIntl();

const validationSchema = yup.object({
  email: yup
    .string()
    .email(intl.get('validation.invalidFormat'))
    .required(intl.get('validation.requiredField')),
  password: yup
    .string()
    .required(intl.get('validation.requiredField'))
    .max(UTILS.STRING_MAX_LEN),
});

const linkStyle: CSSProperties = {
  color: defaultTheme.palette.primary.main,
  cursor: 'pointer',
  textDecoration: 'underline',
};

/**
 * Login form component
 * @return {JSX.Element}
 */
function LoginForm(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const initialValues = {
    email: UTILS.BLANK,
    password: UTILS.BLANK,
  };

  const onLogin = async (values: FormData) => {
    await dispatch(login({ ...values }));
  };

  return (<Formik
    enableReinitialize
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={async (values, { setSubmitting }) => {
      setSubmitting(true);
      await onLogin(values);
      setSubmitting(false);
      navigate(ROUTES.ROOT);
    }}
  >
    {({ submitForm, isSubmitting }) => (
      <Form
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            submitForm();
          }
        }}
      >
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <Field
              component={TextField}
              fullWidth
              variant="outlined"
              name="email"
              type="email"
              label={intl.get('pages.login.email')}
              sx={{
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              component={TextField}
              fullWidth
              variant="outlined"
              name="password"
              type="password"
              label={intl.get('pages.login.password')}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              {intl.get('pages.login.enter')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {intl.get('pages.login.forgotPassword')} <a style={linkStyle} onClick={() => navigate(ROUTES.FORGOT_PASSWORD)}>
                {intl.get('pages.login.clickHere')}
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Form>
    )}
  </Formik>);
}

export default LoginForm;
