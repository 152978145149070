import React, { useEffect, useState } from 'react';

import { FormControl, TextField, Typography } from '@mui/material';

import intl from 'react-intl-universal';

import { ROLES, UTILS } from 'shared/constants';

import { useAppSelector } from 'store/hooks';

interface Props {
  goal: string
  index: number
  userBelongsToSpecialty: boolean
  isSubmiting: boolean
  onSave: (goalId: number, description: string) => void
  onChange: (description: string, index: number) => void
}

/**
 * GoalDescription description
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function GoalDescription(props: Props): JSX.Element {
  const { user } = useAppSelector((state) => state.auth);
  const [description, setDescription] = useState(UTILS.BLANK);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setIsDirty(false);
    setDescription(props.goal || UTILS.BLANK);
    if (!description) {
    }
  }, [props.goal]);

  useEffect(() => {
    if (isDirty) {
      const saveGoalDescriptionTimeOutId = setTimeout(() => props.onSave(props.index, description), 10000);
      return () => clearTimeout(saveGoalDescriptionTimeOutId);
    }
  }, [description]);

  return <>
    <Typography mb={2}>
      {intl.get('pages.viewLearner.dsdt.week', { n: props.index + 1 })}
    </Typography>
    <FormControl fullWidth>
      <TextField
        disabled={(user && user.role.id.toString() === ROLES.THERAPIST && !props.userBelongsToSpecialty) || props.isSubmiting}
        onChange={(e) => {
          setIsDirty(true);
          setDescription(e.target.value);
          props.onChange(e.target.value, props.index);
        }}
        InputLabelProps={{ shrink: true }}
        value={props.goal || UTILS.BLANK}
        label={intl.get('pages.viewLearner.dsdt.goalDescriptionLabel')}
        placeholder={intl.get('pages.viewLearner.dsdt.goalDescriptionPlaceholder')}
        minRows={3}
        error={description.length === 0}
        multiline
      />
    </FormControl>
  </>;
}
