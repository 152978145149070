/* eslint-disable quotes */
import React, { useContext, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Link, useNavigate } from 'react-router-dom';

import { Button, Grid, IconButton, SxProps, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Theme, Tooltip } from '@mui/material';
import MuiTable from '@mui/material/Table';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { API_HOST, PAGINATION, UTILS } from 'shared/constants';
import api from 'shared/api';

import useStateWithPromise from 'hooks/useStateWithPromise';
import { useToastify } from 'hooks/toastfy';

import AppContext from 'contexts/app.context';

import Dialog from '../Dialog';
import Filter, { SelectProps } from './Filter';
import { NoResultsBox, Type } from 'components/NoResultsBox';
import ConditionalContainer from 'components/ConditionalContainer';

export interface Field {
  name: string;
  translate?: boolean;
  link?: boolean;
};

interface Props<T> {
  columns: string[];
  fields: Field[];
  onRefresh: () => void;
  type: Type;

  actions: {
    hide?: boolean;
    addPath?: string;
    editPath?: string;
    viewPath?: string;
    viewPathResolver?: (item: T) => string;
    downloadPath?: string;
    deleteEndpoint?: string;
    resetEndpoint?: string;
    resetEndpointResolver?: (item: T) => string;
    resetAllEndpoint?: string;
    resetAllEndpointResolver?: () => string;
  }

  deleteDialog?: {
    titleIntlKey: string,
    descriptionIntlKey: string
  }

  resetDialog?: {
    titleIntlKey: string,
    descriptionIntlKey: string
  }

  resetAllDialog?: {
    titleIntlKey: string,
    descriptionIntlKey: string
  }

  filter: {
    data: T[];
    searchPlaceholder: string;
    searchMatchFields: string[];
    selects?: SelectProps[];
  }
}

interface DynamicObject {
  [key: string]: any;
}

const actionsColStyle: SxProps<Theme> = {
  position: 'sticky',
  right: 0,
  backgroundColor: '#FFFFFF',
};

/**
 * Table component
 * @param {Props} props
 * @return {JSX.Element}
 */
function Table<T extends DynamicObject>(props: Props<T>): JSX.Element {
  const navigate = useNavigate();
  const toast = useToastify();
  const [rowsPerPage, setRowsPerPage] = useState(PAGINATION.ROWS_PER_PAGE[0]);
  const [filtered, setFiltered] = React.useState<T[]>([]);
  const [page, setPage] = useState(0);
  const [paginated, setPaginated] = useState<T[]>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useStateWithPromise(false);
  const [openResetDialog, setOpenResetDialog] = useStateWithPromise(false);
  const [openResetAllDialog, setOpenResetAllDialog] = useStateWithPromise(false);
  const [toDelete, setToDelete] = useState(0);
  const [toReset, setToReset] = useState<T | null>(null);
  const { isLoading } = useContext(AppContext);

  useEffect(() => {
    if (toDelete) {
      setOpenDeleteDialog(true);
    }
  }, [toDelete]);

  useEffect(() => {
    if (toReset) {
      setOpenResetDialog(true);
    }
  }, [toReset]);

  useEffect(() => {
    if (filtered.length && paginated.length) {
      paginate(0);
    }
  }, [rowsPerPage]);

  useEffect(() => {
    if (filtered.length && !paginated.length) {
      setPage(0);
      paginate(0);
    }
  }, [filtered]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    paginate(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, PAGINATION.RADIX));
    setPage(0);
  };

  const paginate = (newPage: number) => {
    newPage++;
    const newPaginated = filtered.slice((newPage - 1) * rowsPerPage, newPage * rowsPerPage);
    setPaginated(newPaginated);
  };

  const getValue = (value: T, field: Field) => {
    if (field.link) {
      return value[field.name] ? <Link to={value[field.name]}>Ver</Link> : '-';
    }
    if (field.name.indexOf('.') !== -1) {
      const fields = field.name.split('.');
      const object = value[fields[0]];
      if (object) {
        if (field.translate) {
          return intl.get(`common.${object[fields[1]]}`);
        }
        return object[fields[1]];
      }
      return '-';
    }
    if (field.translate) {
      return intl.get(`common.${value[field.name]}`);
    }
    return value[field.name] || '-';
  };

  const onDelete = async (id: number) => {
    if (props.actions.deleteEndpoint) {
      try {
        await api.delete(props.actions.deleteEndpoint.replace(':id', id.toString()));
        toast.success(intl.get('toast.deleteSuccess'));
      } catch (error) {
        toast.error(intl.get('toast.deleteError'));
      }
      setOpenDeleteDialog(false);
      props.onRefresh();
    }
  };

  const onReset = async (value: T | null) => {
    if (props.actions.resetEndpoint && value) {
      try {
        let endpoint = props.actions.resetEndpoint.replace(':id', value.id.toString());
        if (props.actions.resetEndpointResolver) {
          endpoint = props.actions.resetEndpointResolver(value);
        }

        await api.delete(endpoint);
        toast.success(intl.get('toast.deleteSuccess'));
      } catch (error) {
        toast.error(intl.get('toast.deleteError'));
      }
      setOpenResetDialog(false);
      props.onRefresh();
    }
  };

  const onResetAll = async () => {
    if (props.actions.resetAllEndpointResolver) {
      try {
        const endpoint = props.actions.resetAllEndpointResolver();

        await api.delete(endpoint);
        toast.success(intl.get('toast.deleteSuccess'));
      } catch (error) {
        toast.error(intl.get('toast.deleteError'));
      }
      setOpenResetAllDialog(false);
      props.onRefresh();
    }
  };

  const onView = (value: T) => {
    let path = UTILS.BLANK;
    if (props.actions.viewPath) {
      path = props.actions.viewPath.replace(':id', value.id);
      if (props.actions.viewPathResolver) {
        path = props.actions.viewPathResolver(value);
      }
    }
    navigate(path || UTILS.BLANK);
  };

  const onDownload = (id: string) => {
    if (props.actions.downloadPath) {
      const a = document.createElement('a');
      a.href = `${API_HOST}${props.actions.downloadPath.replace(':id', id)}`;
      console.log('href', a.href);
      a.download = 'file.pdf';
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const onCloseDeleteDialog = async () => {
    setOpenDeleteDialog(false);
    setTimeout(() => {
      setToDelete(0);
    }, 100);
  };

  const onCloseResetDialog = async () => {
    setOpenResetDialog(false);
    setTimeout(() => {
      setToReset(null);
    }, 100);
  };

  const onCloseResetAllDialog = async () => {
    setOpenResetAllDialog(false);
  };

  return (
    <div>
      {
        props.actions.addPath || props.actions.resetAllEndpoint ?
          <Grid container sx={{ mb: 4 }}>
            <Grid item xs="auto">
              {
                props.actions.addPath ? <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate(props.actions.addPath || UTILS.BLANK)}
                >
                  {intl.get('components.table.add')}
                </Button> : null
              }
              {
                props.actions.resetAllEndpoint ? <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenResetAllDialog(true)}
                >
                  {intl.get('components.table.resetReports')}
                </Button> : null
              }
            </Grid>
          </Grid> :
          null
      }
      <Filter
        data={props.filter.data}
        setFiltered={setFiltered}
        setPaginated={setPaginated}
        setPage={setPage}

        selects={props.filter.selects}

        searchPlaceholder={props.filter.searchPlaceholder}
        searchMatchFields={props.filter.searchMatchFields}
      />
      <TableContainer sx={{ mt: 3 }}>
        <MuiTable size="small">
          <TableHead>
            <TableRow>
              {
                props.columns.map((column) => <TableCell key={column}>
                  {column}
                </TableCell>)
              }
              {
                !props.actions.hide ?
                  <TableCell
                    key={'actions'}
                    sx={actionsColStyle}
                  >
                    {intl.get('components.table.actions')}
                  </TableCell> : null
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              paginated.map((value, index) => <TableRow key={index}>
                {
                  props.fields.map((field) => <TableCell
                    key={field.name}
                  >
                    {getValue(value, field)}
                  </TableCell>)
                }
                {
                  !props.actions.hide ?
                    <TableCell
                      key={`${index}action`}
                      sx={actionsColStyle}
                    >
                      {
                        props.actions.viewPath ? <Tooltip title={intl.get('components.table.view')}>
                          <IconButton
                            aria-label={intl.get('components.table.view')}
                            color="primary"
                            onClick={() => onView(value)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip> : null
                      }
                      {
                        props.actions.editPath ? <Tooltip title={intl.get('components.table.edit')}>
                          <IconButton
                            aria-label={intl.get('components.table.edit')}
                            color="primary"
                            onClick={() => navigate(
                              props.actions.editPath ? props.actions.editPath.replace(':id', value.id) : UTILS.BLANK,
                            )}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip> : null
                      }
                      {
                        props.actions.deleteEndpoint ? <Tooltip title={intl.get('components.table.delete')}>
                          <IconButton
                            aria-label={intl.get('components.table.delete')}
                            color="primary"
                            onClick={() => setToDelete(value.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip> : null
                      }
                      {
                        props.actions.resetEndpoint ? <Tooltip title={intl.get('components.table.resetReport')}>
                          <IconButton
                            aria-label={intl.get('components.table.resetReport')}
                            color="primary"
                            onClick={() => setToReset(value)}
                          >
                            <RestartAltIcon />
                          </IconButton>
                        </Tooltip> : null
                      }
                      {
                        props.actions.downloadPath ? <Tooltip title={intl.get('components.table.download')}>
                          <IconButton
                            aria-label={intl.get('components.table.download')}
                            color="primary"
                            onClick={() => onDownload(value.id)}
                          >
                            <FileDownloadIcon />
                          </IconButton>
                        </Tooltip> : null
                      }
                    </TableCell> : null
                }
              </TableRow>)
            }
          </TableBody>
        </MuiTable>
      </TableContainer>
      <ConditionalContainer checkIf={!!filtered.length} noComponent>
        <TablePagination
          id="reportsPagination"
          component="div"
          tabIndex={-1}
          page={page}
          count={filtered.length}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={PAGINATION.ROWS_PER_PAGE}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={intl.get('components.tablePagination.labelRowsPerPage')}
        />
      </ConditionalContainer>
      <NoResultsBox
        filtered={filtered.length !== props.filter.data.length}
        show={!!!filtered.length && !isLoading}
        type={props.type}
      />
      {
        props.deleteDialog && props.deleteDialog.titleIntlKey && props.deleteDialog.descriptionIntlKey ?
          <Dialog
            title={intl.get(props.deleteDialog.titleIntlKey)}
            text={intl.getHTML(props.deleteDialog.descriptionIntlKey, { id: toDelete })}
            onCancel={() => onCloseDeleteDialog()}
            onConfirm={() => onDelete(toDelete)}
            open={openDeleteDialog}
          /> : null
      }
      {
        toReset && props.resetDialog && props.resetDialog.titleIntlKey && props.resetDialog.descriptionIntlKey ?
          <Dialog
            title={intl.get(props.resetDialog.titleIntlKey)}
            text={intl.getHTML(props.resetDialog.descriptionIntlKey, { id: toReset.id })}
            onCancel={() => onCloseResetDialog()}
            onConfirm={() => onReset(toReset)}
            open={openResetDialog}
          /> : null
      }
      {
        props.actions.resetAllEndpoint && props.resetAllDialog ?
          <Dialog
            title={intl.get(props.resetAllDialog.titleIntlKey)}
            text={intl.getHTML(props.resetAllDialog.descriptionIntlKey)}
            onCancel={onCloseResetAllDialog}
            onConfirm={onResetAll}
            open={openResetAllDialog}
          /> : null
      }
    </div >
  );
}

export default Table;
