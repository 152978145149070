import { Box } from '@mui/material';
import React from 'react';

import { Outlet } from 'react-router-dom';

/**
 * Blank layout
 * @return {JSX.Element}
 */
function PrintLayout(): JSX.Element {
  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Outlet />
    </Box>
  );
};

export default PrintLayout;
