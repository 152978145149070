import React, { Fragment } from 'react';

import {
  StyleSheet,
  Document,
  Page,
  View,
  Text,
  Image,
} from '@react-pdf/renderer';
import intl from 'react-intl-universal';

import Report from 'models/Report';

import { UTILS } from 'shared/constants';

import { groupBy } from 'helpers/array';

import WaterMarkImage from 'assets/image/logo_clath.png';
import ConditionalContainer from '../../ConditionalContainer';

const goalsLegend = [
  {
    range: '8, 9, 10',
    abbreviation: 'MC',
    description: 'Meta compreendida',
    color: 'blue',
  },
  {
    range: '6, 7',
    abbreviation: 'MPC +',
    description: 'Meta parcialmente compreendida com perspectiva positiva',
    color: 'green',
  },
  {
    range: '4, 5',
    abbreviation: 'MPC',
    description: 'Meta parcialmente compreendida repetida no próximo PIDE',
    color: 'yellow',
  },
  {
    range: '1, 2, 3',
    abbreviation: 'MSC',
    description: 'Meta a ser compreendida',
    color: 'red',
  },
];

const styles = StyleSheet.create({
  cover: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  page: {
    margin: 10,
    padding: 10,
    paddingBottom: 30,
  },
});

interface Props {
  partial?: boolean;
  report: Report;
  charts: Map<number, string>;
  images: Map<number, string>;
}

/**
 * ReportDocument component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function ReportDocument(props: Props): JSX.Element {
  return (
    <Document>
      <Page size={'A4'} style={{ ...styles.page }}>
        <View fixed>
          <View style={{ position: 'absolute', marginTop: '137%', paddingLeft: 20 }}>
            <Text
              style={{ color: '#141C4D', fontSize: 12 }}
              render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
              )}
            />
          </View>
          <Image
            // debug
            src={WaterMarkImage}
            style={{
              objectFit: 'contain',
              position: 'absolute',
              height: '50px',
              width: '50px',
              marginTop: '120%',
              marginLeft: '80%',
            }}
          />
        </View>
        <View style={{ ...styles.cover }}>
          <Text style={{ fontSize: '35px' }}>
            {
              props.partial ?
                intl.get('components.reportPDF.document.title1Partial') :
                intl.get('components.reportPDF.document.title1')
            }
          </Text>
          <Text style={{ fontSize: '35px', marginBottom: '32px' }}>
            {intl.get('components.reportPDF.document.title2')}
          </Text>
          <Text style={{ fontSize: '25px' }}>{props.report.learner.name}</Text>
          <Text style={{ fontSize: '20px', marginBottom: props.report.learner.affiliation2 ? '0' : '32px' }}>
            {props.report.learner.affiliation1}
          </Text>
          {
            props.report.learner.affiliation2 ?
              <Text style={{ fontSize: '20px', marginBottom: '32px' }}>
                {props.report.learner.affiliation2}
              </Text> :
              null
          }
          <Text style={{ fontSize: '20px', marginBottom: '32px' }}>
            {new Date(new Date().toDateString()).toLocaleDateString()}
          </Text>
          {props.partial ? (
            <Text style={{ fontSize: '15px', marginBottom: '8px' }}>
              {intl.get('components.reportPDF.document.partial')}
            </Text>
          ) : (
            <>
              <Text style={{ fontSize: '15px', marginBottom: '8px' }}>
                {intl.get('components.reportPDF.document.description1')}
              </Text>
              <Text style={{ fontSize: '15px', marginBottom: '8px' }}>
                {intl.get('components.reportPDF.document.description2')}
              </Text>
              <Text style={{ fontSize: '15px' }}>
                {intl.get('components.reportPDF.document.description3')}
              </Text>
            </>
          )}
        </View>
        {props.report.reportSpecialties.map((reportSpecialty, index) => (
          <View
            style={{ marginRight: 20 }}
            break={true}
            key={index}
          >
            <Text
              style={{
                fontSize: '25px',
                color: '#141C4D',
              }}
            >
              {reportSpecialty.specialtyUserName}
            </Text>
            <Text
              style={{
                fontSize: '25px',
                marginBottom: '16px',
                color: '#141C4D',
              }}
            >
              {reportSpecialty.specialtyName}
            </Text>
            {
              !props.partial ? <>
                <Text
                  style={{
                    fontSize: '15px',
                    marginBottom: '8px',
                    color: '#141C4D',
                  }}
                >
                  {intl.get('components.reportPDF.document.introduction')}
                </Text>
                <Text style={{ fontSize: '12px', marginBottom: '8px' }}>
                  {reportSpecialty.introduction}
                </Text>
                <Text
                  style={{
                    fontSize: '15px',
                    marginBottom: '8px',
                    color: '#141C4D',
                  }}
                >
                  {intl.get('components.reportPDF.document.procedures')}
                </Text>
                <Text style={{ fontSize: '12px', marginBottom: '8px' }}>
                  {reportSpecialty.procedures}
                </Text>
                <Text
                  style={{
                    fontSize: '15px',
                    marginBottom: '8px',
                    color: '#141C4D',
                  }}
                >
                  {intl.get('components.reportPDF.document.analysis')}
                </Text>
                <Text style={{ fontSize: '12px', marginBottom: '8px' }}>
                  {reportSpecialty.analysis}
                </Text>
              </> :
                null
            }
            <Text
              style={{
                fontSize: '15px',
                marginBottom: '8px',
                color: '#141C4D',
              }}
            >
              {intl.get('components.reportPDF.document.goals')}
            </Text>
            {reportSpecialty.goals.length > 0 ? (
              reportSpecialty.goals.map((goal, index) => (
                <Fragment key={index}>
                  <Text style={{ fontSize: '12px', marginBottom: '8px' }}>
                    <Text style={{ fontSize: '15px', color: '#141C4D' }}>
                      {`${index + 1}${UTILS.DOT}`}
                    </Text>
                    {`${UTILS.SPACE}${goal.name}`}
                  </Text>
                </Fragment>
              ))
            ) : (
              <Text style={{ fontSize: '12px', marginBottom: '16px' }}>
                {UTILS.BLANK}
              </Text>
            )}
            {!props.partial && reportSpecialty.goals.length > 0 &&
              props.charts.get(reportSpecialty.specialtyId) ?
              <View
                wrap={false}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  {goalsLegend.map((legend, index) => (
                    <View
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        marginBottom:
                          index + 1 === goalsLegend.length ? '16px' : '1px',
                      }}
                    >
                      <View
                        style={{
                          backgroundColor: legend.color,
                          width: '10px',
                          height: '10px',
                          border: '1px solid black',
                          borderRadius: '50%',
                        }}
                      />
                      <View style={{ padding: '2px', width: '40px' }}>
                        <Text
                          style={{ fontSize: '9px', textAlign: 'right' }}
                        >
                          {legend.range}
                        </Text>
                      </View>
                      <View style={{ padding: '2px', width: '30px' }}>
                        <Text style={{ fontSize: '7px' }}>
                          {legend.abbreviation}
                        </Text>
                      </View>
                      <View style={{ padding: '2px' }}>
                        <Text style={{ fontSize: '7px' }}>
                          {legend.description}
                        </Text>
                      </View>
                    </View>
                  ))}
                </View>
                <Image
                  src={props.charts.get(reportSpecialty.specialtyId)}
                  style={{ width: '200px', marginBottom: '8px' }}
                />
              </View> :
              null}
            {/* week1 */}
            <ConditionalContainer noComponent checkIf={!!reportSpecialty.week1}>
              <View wrap={false}>
                <Text style={{ fontSize: '12px', marginBottom: '8px', color: '#141C4D' }}>
                  {intl.get('components.reportPDF.document.week', { n: 1 })}
                </Text>
                <Text style={{ fontSize: '12px', marginBottom: '8px' }}>
                  {reportSpecialty.week1 || UTILS.BLANK}
                </Text>
              </View>
            </ConditionalContainer>
            {/* week2 */}
            <ConditionalContainer noComponent checkIf={!!reportSpecialty.week2}>
              <View wrap={false}>
                <Text style={{ fontSize: '12px', marginBottom: '8px', color: '#141C4D' }}>
                  {intl.get('components.reportPDF.document.week', { n: 2 })}
                </Text>
                <Text style={{ fontSize: '12px', marginBottom: '8px' }}>
                  {reportSpecialty.week2 || UTILS.BLANK}
                </Text>
              </View>
            </ConditionalContainer>
            {/* week3 */}
            <ConditionalContainer noComponent checkIf={!!reportSpecialty.week3}>
              <View wrap={false}>
                <Text style={{ fontSize: '12px', marginBottom: '8px', color: '#141C4D' }}>
                  {intl.get('components.reportPDF.document.week', { n: 3 })}
                </Text>
                <Text style={{ fontSize: '12px', marginBottom: '8px' }}>
                  {reportSpecialty.week3 || UTILS.BLANK}
                </Text>
              </View>
            </ConditionalContainer>
            {/* week4 */}
            <ConditionalContainer noComponent checkIf={!!reportSpecialty.week4}>
              <View wrap={false}>
                <Text style={{ fontSize: '12px', marginBottom: '8px', color: '#141C4D' }}>
                  {intl.get('components.reportPDF.document.week', { n: 4 })}
                </Text>
                <Text style={{ fontSize: '12px', marginBottom: '8px' }}>
                  {reportSpecialty.week4 || UTILS.BLANK}
                </Text>
              </View>
            </ConditionalContainer>
            {/* week5 */}
            <ConditionalContainer noComponent checkIf={!!reportSpecialty.week5}>
              <View wrap={false}>
                <Text style={{ fontSize: '12px', marginBottom: '8px', color: '#141C4D' }}>
                  {intl.get('components.reportPDF.document.week', { n: 5 })}
                </Text>
                <Text style={{ fontSize: '12px', marginBottom: '8px' }}>
                  {reportSpecialty.week5 || UTILS.BLANK}
                </Text>
              </View>
            </ConditionalContainer>
            {/* week6 */}
            <ConditionalContainer noComponent checkIf={!!reportSpecialty.week6}>
              <View wrap={false}>
                <Text style={{ fontSize: '12px', marginBottom: '8px', color: '#141C4D' }}>
                  {intl.get('components.reportPDF.document.week', { n: 6 })}
                </Text>
                <Text style={{ fontSize: '12px', marginBottom: '16px' }}>
                  {reportSpecialty.week6 || UTILS.BLANK}
                </Text>
              </View>
            </ConditionalContainer>
            {/* week7 */}
            <ConditionalContainer noComponent checkIf={!!reportSpecialty.week7}>
              <View wrap={false}>
                <Text style={{ fontSize: '12px', marginBottom: '8px', color: '#141C4D' }}>
                  {intl.get('components.reportPDF.document.week', { n: 7 })}
                </Text>
                <Text style={{ fontSize: '12px', marginBottom: '8px' }}>
                  {reportSpecialty.week7 || UTILS.BLANK}
                </Text>
              </View>
            </ConditionalContainer>
            {/* week8 */}
            <ConditionalContainer noComponent checkIf={!!reportSpecialty.week8}>
              <View wrap={false}>
                <Text style={{ fontSize: '12px', marginBottom: '8px', color: '#141C4D' }}>
                  {intl.get('components.reportPDF.document.week', { n: 8 })}
                </Text>
                <Text style={{ fontSize: '12px', marginBottom: '8px' }}>
                  {reportSpecialty.week8 || UTILS.BLANK}
                </Text>
              </View>
            </ConditionalContainer>
            {/* week9 */}
            <ConditionalContainer noComponent checkIf={!!reportSpecialty.week9}>
              <View wrap={false}>
                <Text style={{ fontSize: '12px', marginBottom: '8px', color: '#141C4D' }}>
                  {intl.get('components.reportPDF.document.week', { n: 9 })}
                </Text>
                <Text style={{ fontSize: '12px', marginBottom: '8px' }}>
                  {reportSpecialty.week9 || UTILS.BLANK}
                </Text>
              </View>
            </ConditionalContainer>
            {/* week10 */}
            <ConditionalContainer noComponent checkIf={!!reportSpecialty.week10}>
              <View wrap={false}>
                <Text style={{ fontSize: '12px', marginBottom: '8px', color: '#141C4D' }}>
                  {intl.get('components.reportPDF.document.week', { n: 10 })}
                </Text>
                <Text style={{ fontSize: '12px', marginBottom: '8px' }}>
                  {reportSpecialty.week10 || UTILS.BLANK}
                </Text>
              </View>
            </ConditionalContainer>
            {/* week11 */}
            <ConditionalContainer noComponent checkIf={!!reportSpecialty.week11}>
              <View wrap={false}>
                <Text style={{ fontSize: '12px', marginBottom: '8px', color: '#141C4D' }}>
                  {intl.get('components.reportPDF.document.week', { n: 11 })}
                </Text>
                <Text style={{ fontSize: '12px', marginBottom: '8px' }}>
                  {reportSpecialty.week11 || UTILS.BLANK}
                </Text>
              </View>
            </ConditionalContainer>
            {/* week12 */}
            <ConditionalContainer noComponent checkIf={!!reportSpecialty.week12}>
              <View wrap={false}>
                <Text style={{ fontSize: '12px', marginBottom: '8px', color: '#141C4D' }}>
                  {intl.get('components.reportPDF.document.week', { n: 12 })}
                </Text>
                <Text style={{ fontSize: '12px', marginBottom: '16px' }}>
                  {reportSpecialty.week12 || UTILS.BLANK}
                </Text>
              </View>
            </ConditionalContainer>
            {props.partial ? null : (
              <>
                <View wrap={false}>
                  <Text
                    // break
                    style={{
                      fontSize: '15px',
                      marginBottom: '8px',
                      color: '#141C4D',
                    }}
                  >
                    {intl.get('components.reportPDF.document.evaluation')}
                  </Text>
                  <Text style={{ fontSize: '12px', marginBottom: '16px' }}>
                    {reportSpecialty.evaluation || UTILS.BLANK}
                  </Text>
                </View>
                <View wrap={false}>
                  <Text
                    style={{
                      fontSize: '15px',
                      marginBottom: '8px',
                      color: '#141C4D',
                    }}
                  >
                    {intl.get('components.reportPDF.document.considerations')}
                  </Text>
                  <Text style={{ fontSize: '12px', marginBottom: '16px' }}>
                    {reportSpecialty.considerations || UTILS.BLANK}
                  </Text>
                </View>
                <View style={{ marginBottom: '16px' }}>
                  {reportSpecialty.behavioralAspects.length > 0 ? (
                    Object.entries(groupBy(reportSpecialty.behavioralAspects, (i) => i.frequency)).map(
                      (array, index) => (
                        <View key={index} wrap={false}>
                          {
                            index === 0 ? <Text
                              style={{
                                fontSize: '15px',
                                marginBottom: '8px',
                                color: '#141C4D',
                              }}
                            >
                              {intl.get('components.reportPDF.document.behavior')}
                            </Text> : null
                          }
                          <Text style={{ fontSize: '13px', color: '#141C4D', marginBottom: '8px' }}>
                            {array[0]}:
                          </Text>
                          {
                            array[1].map((reportSpecialtyBehavioralAspect, index) => <Text
                              key={index}
                              style={{ fontSize: '12px', marginBottom: '8px' }}
                            >
                              {reportSpecialtyBehavioralAspect.description}
                            </Text>)
                          }
                        </View>
                      ),
                    )
                  ) : (
                    <Text style={{ fontSize: '12px', marginBottom: '16px' }}>
                      {UTILS.BLANK}
                    </Text>
                  )}
                </View>
                <View style={{ marginBottom: '16px' }}>
                  <View wrap={false}>
                    <Text style={{ fontSize: '13px', color: '#141C4D', marginBottom: '8px' }}>
                      {intl.get('components.reportPDF.document.behavioralObs')}
                    </Text>
                    <Text
                      key={index}
                      style={{ fontSize: '12px', marginBottom: '8px' }}
                    >
                      {reportSpecialty.behavioralObs}
                    </Text>
                  </View>
                  <View wrap={false}>
                    <Text style={{ fontSize: '13px', color: '#141C4D', marginBottom: '8px' }}>
                      {intl.get('components.reportPDF.document.cognitiveObs')}
                    </Text>
                    <Text
                      key={index}
                      style={{ fontSize: '12px', marginBottom: '8px' }}
                    >
                      {reportSpecialty.cognitiveObs}
                    </Text>
                  </View>
                  <View wrap={false}>
                    <Text style={{ fontSize: '13px', color: '#141C4D', marginBottom: '8px' }}>
                      {intl.get('components.reportPDF.document.adaptiveObs')}
                    </Text>
                    <Text
                      key={index}
                      style={{ fontSize: '12px', marginBottom: '8px' }}
                    >
                      {reportSpecialty.adaptiveObs}
                    </Text>
                  </View>
                </View>
                {reportSpecialty.image &&
                  props.images.get(reportSpecialty.image.id) ?
                  <View
                    style={{
                      height: '400px',
                      width: '400px',
                      marginBottom: '16px',
                    }}
                    break
                  >
                    <Text
                      style={{
                        fontSize: '15px',
                        marginBottom: '8px',
                        color: '#141C4D',
                      }}
                    >
                      {intl.get('components.reportPDF.document.visualRecord')}
                    </Text>
                    <Image
                      src={props.images.get(reportSpecialty.image.id)}
                      style={{
                        marginBottom: '16px',
                        borderRadius: '8px',
                      }}
                    />
                  </View> :
                  null}
              </>
            )}
          </View>
        ))}
        {
          !props.partial ?
            <View
              style={{ marginRight: 20 }}
              break={true}
            >
              <Text
                style={{
                  fontSize: '15px',
                  color: '#141C4D',
                  textAlign: 'center',
                  marginBottom: 100,
                }}
              >
                Grupo de Pesquisa & Assessoria Pedagógica e Terapêutica
                para a Pessoa com o Desenvolvimento Atípico
              </Text>
              <Text
                style={{
                  fontSize: '15px',
                  color: '#141C4D',
                  marginBottom: 20,
                }}
              >
                Validação
              </Text>
              {
                props.report.reportSpecialties.map((_, index) => <Text
                  key={index}
                  style={{
                    fontSize: '15px',
                    marginBottom: 20,
                  }}
                >
                  _______________________________
                </Text>)
              }
            </View> :
            null
        }
      </Page>
    </Document>
  );
}
