import React, { useEffect, useMemo, useState } from 'react';

import { FormControl, Grid, Hidden, IconButton, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent, Tooltip, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InfoIcon from '@mui/icons-material/Info';

import intl from 'react-intl-universal';
import { AxiosError } from 'axios';

import Report, { ReportSpecialty } from 'models/Report';
import Team from 'models/Team';
import Goal from 'models/Goal';

import { ENDPOINTS, ROLES, UTILS } from 'shared/constants';
import api from 'shared/api';

import { useToastify } from 'hooks/toastfy';

import { useAppSelector } from 'store/hooks';

import { parseInput } from 'helpers/model';
import { displayError } from 'helpers/http';

import { SelectOption } from 'components/Table/Filter';
import ConditionalContainer from 'components/ConditionalContainer';
import { NoResultsBox, Type } from 'components/NoResultsBox';
import AutocompleteAsync from './AutocompleteAsync';

export const minGoals = 3;

interface Props {
  team: Team
  report: Report
  specialtyOptions: SelectOption[]
  loggedSpecialtyIds: string[]
  onRefresh: () => {}
  shouldRefresh: () => {}
}

/**
 * Pdt component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function Pdt(props: Props): JSX.Element {
  const toast = useToastify();
  const { user } = useAppSelector((state) => state.auth);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [specialtyId, setSpecialtyId] = useState(UTILS.BLANK);
  const [goals, setGoals] = useState<Goal[]>([]);
  const goalsLimit = 5;

  useEffect(() => {
    if (props.specialtyOptions.length && !specialtyId) {
      if (user && user.role.id.toString() === ROLES.CLINIC) {
        setSpecialtyId(props.specialtyOptions[0].value);
      } else {
        setSpecialtyId(props.loggedSpecialtyIds[0]);
      }
    }
  }, [props.specialtyOptions]);

  useEffect(() => {
    if (specialtyId) {
      const newGoals = props.report.reportSpecialties
        .filter((reportSpecialty: ReportSpecialty) => reportSpecialty.specialtyId.toString() === specialtyId)
        .flatMap((reportSpecialty: ReportSpecialty) => reportSpecialty.goals);

      if (!newGoals.length && ((user && user.role.id.toString() === ROLES.CLINIC) || userBelongsToSpecialty)) {
        setGoals([
          parseInput<Goal>({ name: UTILS.BLANK }),
          parseInput<Goal>({ name: UTILS.BLANK }),
          parseInput<Goal>({ name: UTILS.BLANK }),
        ]);
      } else if ((user && user.role.id.toString() === ROLES.CLINIC) || userBelongsToSpecialty) {
        const missingGoals = minGoals - newGoals.length;
        for (let index = 0; index < missingGoals; index++) {
          newGoals.push(parseInput<Goal>({ name: UTILS.BLANK }));
        }
        setGoals(newGoals);
      } else {
        setGoals(newGoals);
      }
    }
  }, [specialtyId, props.report]);

  useEffect(() => {
    if (goals.length >= minGoals && isDirty) {
      const hasEmptyGoals = goals.filter((goal) => !goal.name);
      if (hasEmptyGoals.length === 0) {
        const saveGoalsTimeOutId = setTimeout(() => {
          handleSaveGoal();
          setIsDirty(false);
        }, 500);
        return () => clearTimeout(saveGoalsTimeOutId);
      }
    }
  }, [goals]);

  const userBelongsToSpecialty = useMemo(() => props.loggedSpecialtyIds.includes(specialtyId), [props.loggedSpecialtyIds, specialtyId]);

  const specialtiesLabel = useMemo(() => props.team.special ?
    intl.get('pages.viewLearner.contentLabel') :
    intl.get('pages.viewLearner.specialtyLabel'), [props]);

  const handleSpecialtyChange = (event: SelectChangeEvent) => {
    setSpecialtyId(event.target.value as string);
  };

  const handleAddGoal = () => {
    const goalsCopy = [...goals];
    if (goalsCopy.length < goalsLimit) {
      setGoals([
        ...goalsCopy,
        parseInput<Goal>({
          name: UTILS.BLANK,
        }),
      ]);
    }
  };

  const handleDeleteGoal = async (index: number) => {
    const goalsCopy = [...goals];
    const toDelete = goalsCopy[index];
    if (toDelete.id) {
      try {
        await api.delete(ENDPOINTS.GOALS.DELETE.BY_ID.replace(':id', toDelete.id.toString()));
        toast.success(intl.get('toast.deleteSuccess'));
      } catch (error) {
        displayError(error as AxiosError);
      }
    }
    goalsCopy.splice(index, 1);
    setGoals(goalsCopy);
    props.shouldRefresh();
  };

  const handleChangeGoal = (name: string, index: number) => {
    const goalsCopy = [...goals];
    const toUpdate = goalsCopy[index];
    goalsCopy[index] = { ...toUpdate, name };
    setIsDirty(true);
    setGoals(goalsCopy);
  };

  const handleSaveGoal = async () => {
    const goalsCopy = [...goals];
    let hasError = false;
    let index = 0;
    setIsSubmiting(true);

    for (const goal of goalsCopy) {
      const data = {
        ...goal,
        report_id: props.report.id,
        specialty_id: specialtyId,
      };

      try {
        if (goal.id) {
          await api.put(ENDPOINTS.GOALS.PUT.BY_ID.replace(':id', goal.id.toString()), data);
        } else {
          const response = await api.post(ENDPOINTS.GOALS.POST.ADD, data);
          goalsCopy[index] = { ...goal, id: response.data.id };
        }
      } catch (error) {
        hasError = true;
        displayError(error as AxiosError);
      }
      index = index + 1;
    }

    setIsSubmiting(false);
    setGoals(goalsCopy);
    if (!hasError) {
      toast.success(intl.get('toast.saveSuccess'));
    }
    props.shouldRefresh();
    props.onRefresh();
  };

  return <Grid container alignItems={'center'}>
    <Grid item xs={12} md={6}>
      <FormControl fullWidth>
        <InputLabel id="specialty-options-label">{specialtiesLabel}</InputLabel>
        <Select
          labelId="specialty-options-label"
          id="specialty-options-select"
          value={specialtyId}
          label={specialtiesLabel}
          onChange={handleSpecialtyChange}
        >
          {
            props.specialtyOptions && props.specialtyOptions.length ?
              props.specialtyOptions.map((specialtyOption, index) => <MenuItem key={index} value={specialtyOption.value}>
                {specialtyOption.label}
              </MenuItem>) :
              <MenuItem value={UTILS.BLANK}>
                {intl.get('pages.viewLearner.noOptions')}
              </MenuItem>
          }
        </Select>
      </FormControl>
    </Grid>
    <Hidden mdDown>
      <Grid item md={6} />
    </Hidden>
    <ConditionalContainer checkIf={!!goals.length} noComponent>
      <Grid item xs={'auto'} mt={3} mb={3} mr={1}>
        <Typography variant="h2">{intl.get('pages.viewLearner.pdt.goals')}</Typography>
      </Grid>
      <ConditionalContainer checkIf={((user && user.role.id.toString() === ROLES.CLINIC) || userBelongsToSpecialty)} noComponent>
        <Grid item xs={'auto'}>
          <Tooltip title={intl.get('pages.viewLearner.pdt.addLabel')} enterTouchDelay={0}>
            <IconButton
              onClick={handleAddGoal}
              disabled={goals.length === goalsLimit}
              aria-label={intl.get('pages.viewLearner.pdt.addLabel')}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={5}>
          <Tooltip title={intl.getHTML('pages.viewLearner.pdt.goalsTip')} enterTouchDelay={0}>
            <IconButton
              aria-label={intl.get('pages.viewLearner.pdt.goalsTip')}
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </ConditionalContainer>
      {
        goals.map((goal, index) => <Grid container alignItems="center" columnSpacing={1} mb={2} key={index}>
          <Grid item xs={11} md={6}>
            <AutocompleteAsync
              goal={goal}
              index={index}
              onChange={handleChangeGoal}
              disabled={(user && user.role.id.toString() === ROLES.THERAPIST && !userBelongsToSpecialty) || isSubmiting}
            />
          </Grid>
          <ConditionalContainer
            checkIf={index > 2 && ((user && user.role.id.toString() === ROLES.CLINIC) || userBelongsToSpecialty)}
            noComponent
          >
            <Grid item xs={1}>
              <Tooltip title={intl.getHTML('pages.viewLearner.pdt.removeLabel')} enterTouchDelay={0}>
                <IconButton
                  onClick={() => handleDeleteGoal(index)}
                  aria-label={intl.get('pages.viewLearner.pdt.removeLabel')}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </ConditionalContainer>
        </Grid>)
      }
      <Grid item xs={12} mb={3} mt={1}>
        <Grid item xs={12} md={6}>
          {isSubmiting && <LinearProgress />}
        </Grid>
      </Grid>
    </ConditionalContainer>
    <Grid container>
      <Grid item xs={12} md={6}>
        <NoResultsBox
          filtered={false}
          show={!!!goals.length}
          type={Type.goal}
        />
      </Grid>
    </Grid>
  </Grid>;
}
