import React, { useEffect, useMemo, useState } from 'react';

import intl from 'react-intl-universal';

import { ENDPOINTS, PERMISSIONS, ROUTES } from 'shared/constants';
import { initIntl } from 'shared/locales';
import api from 'shared/api';

import User from 'models/User';

import { useAppSelector } from 'store/hooks';

import Table, { Field } from 'components/Table';
import { Type } from 'components/NoResultsBox';

initIntl();

const getColumns = () => {
  return [
    intl.get('pages.clinics.id'),
    intl.get('pages.clinics.name'),
  ];
};

const getFields = (): Field[] => {
  return [
    {
      name: 'id',
    },
    {
      name: 'name',
    },
  ];
};

/**
 * Clinics component
 * @return {JSX.Element}
 */
function Clinics(): JSX.Element {
  const [clinics, setClinics] = useState<User[]>([]);
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    getClinics();
  }, []);

  const fields = useMemo(() => {
    return getFields();
  }, []);

  const columns = useMemo(() => {
    return getColumns();
  }, []);

  const selects = useMemo(() => {
    return [];
  }, []);

  const getClinics = async () => {
    const response = await api.get(ENDPOINTS.CLINICS.GET.ALL);
    setClinics(response.data);
  };

  return (
    <>
      {
        user ? <Table
          type={Type.clinic}
          columns={columns}
          fields={fields}
          onRefresh={getClinics}

          filter={{
            data: clinics,
            searchMatchFields: ['name'],
            searchPlaceholder: intl.get('pages.clinics.searchPlaceholder'),
            selects: selects,
          }}

          deleteDialog={{
            titleIntlKey: 'pages.clinics.deleteTitleDialog',
            descriptionIntlKey: 'pages.clinics.deleteDescriptionDialog',
          }}

          actions={{
            addPath: PERMISSIONS.CLINICS.CREATE.includes(user.role.id.toString()) ? ROUTES.CLINICS_ADD : undefined,
            editPath: PERMISSIONS.CLINICS.EDIT.includes(user.role.id.toString()) ? ROUTES.CLINICS_EDIT : undefined,
            deleteEndpoint: PERMISSIONS.CLINICS.REMOVE.includes(user.role.id.toString()) ? ENDPOINTS.CLINICS.DELETE.BY_ID : undefined,
          }}
        /> : null
      }

    </>
  );
}

export default Clinics;
