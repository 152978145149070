import React from 'react';

/**
  * AppContext interface
  */
export interface AppContextState {
     isLoading: boolean,
 }

const AppContext = React.createContext<AppContextState>({
  isLoading: true,
});

export default AppContext;
