import React, { ChangeEvent, CSSProperties, useEffect, useMemo, useState } from 'react';

import { Button, FormHelperText, Grid, IconButton, LinearProgress } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import * as yup from 'yup';
import intl from 'react-intl-universal';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import { Formik, Form, Field } from 'formik';
import { AxiosError } from 'axios';
import ImageViewer from 'react-simple-image-viewer';

import { useToastify } from 'hooks/toastfy';

import { displayError } from 'helpers/http';

import api from 'shared/api';
import { initIntl } from 'shared/locales';
import { UTILS, ENDPOINTS, ROUTES } from 'shared/constants';


import Breadcrumbs from 'components/Breadcrumbs';

const thumbStyle: CSSProperties = {
  borderRadius: '8px',
  cursor: 'pointer',
  marginTop: '16px',
  width: '300px',
  height: '300px',
  display: 'flex',
};

interface FormData {
  name: string;
  file: string;
}

initIntl();

const getValidation = (id: string | undefined) => yup.object({
  name: yup
    .string()
    .required(intl.get('validation.requiredField'))
    .max(UTILS.STRING_MAX_LEN),
  file: !id ? yup.mixed()
    .required(intl.get('validation.requiredField')) : yup.string().nullable().optional(),
});

/**
 * FormInfoFile component
 * @return {JSX.Element}
 */
export default function FormInfoFile(): JSX.Element {
  const navigate = useNavigate();
  const toast = useToastify();
  const { id } = useParams<{ id: string }>();
  const [file, setFile] = useState<File>();
  const [fileUrl, setFileUrl] = useState(UTILS.BLANK);
  const [initialValues, setInitialValues] = useState({
    name: UTILS.BLANK,
    file: UTILS.BLANK,
  });

  const validationSchema = getValidation(id);

  useEffect(() => {
    if (id) {
      getInfoFile();
    }
  }, []);

  const breadcrumbs = useMemo(() => {
    return [
      {
        name: intl.get('pages.infoFiles.title'),
        onClickCallback: () => navigate(ROUTES.INFO_FILES),
      },
      {
        name: `${id ? intl.get('common.edit') : intl.get('common.add')}${UTILS.SPACE}${intl.get('pages.formInfoFile.title')}`,
        onClickCallback: () => { },
      },
    ];
  }, [id]);

  const getInfoFile = async () => {
    if (id) {
      const response = await api.get(ENDPOINTS.INFO_FILES.GET.BY_ID.replace(':id', id));
      setInitialValues({
        name: response.data.name,
        file: UTILS.BLANK,
      });
      if (response.data.path) {
        setFileUrl(response.data.path);
      }
    }
  };

  const handleFileChange = async (
    event: ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ) => {
    if (event.target.files) {
      const uploadedFile: File = event.target.files[0];
      setFile(uploadedFile);
      setFieldValue('file', uploadedFile.name);
      event.target.value = UTILS.BLANK;
      const url = URL.createObjectURL(uploadedFile);
      setFileUrl(url);
    }
  };

  const save = async (values: FormData) => {
    try {
      const FD = new FormData();
      if (file) {
        FD.append('file', file, file.name);
      }
      FD.append('name', values.name);

      if (id) {
        FD.append('_method', 'put');
        await api.post(ENDPOINTS.INFO_FILES.PUT.BY_ID.replace(':id', id), FD);
      } else {
        await api.post(ENDPOINTS.INFO_FILES.POST.ADD, FD);
      }
      toast.success(intl.get('toast.saveSuccess'));
    } catch (error) {
      setFile(undefined);
      displayError(error as AxiosError);
    }
  };

  const forceDownload = () => {
    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = 'download';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      <Breadcrumbs items={breadcrumbs} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await save(values);
          setSubmitting(false);
          navigate(ROUTES.INFO_FILES);
        }}
      >
        {({ submitForm, isSubmitting, setFieldValue, errors, values }) => (
          <Form
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                submitForm();
              }
            }}
          >
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <Grid item sm={12} md={6}>
                  <Field
                    component={TextField}
                    fullWidth
                    type="text"
                    label={intl.get('pages.formInfoFile.name')}
                    name="name"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item sm={12} md={6}>
                  <input
                    type="file"
                    disabled={isSubmitting}
                    name="file"
                    onChange={(e) => handleFileChange(e, setFieldValue)}
                  />
                  {/* <Typography mt={1}>{intl.get('pages.formInfoFile.acceptedFiles')}</Typography> */}
                  <FormHelperText
                    sx={{ ml: '14px' }}
                    error={!!errors['file']}
                    id="component-error-text"
                  >
                    {errors['file']}
                  </FormHelperText>
                </Grid>
              </Grid>
              {
                fileUrl ?
                  <Grid container columnSpacing={1} direction={'column'}>
                    <Grid item xs={'auto'}>
                      <IconButton onClick={forceDownload}>
                        <FileDownloadIcon sx={{ height: 150, width: 150 }} />
                      </IconButton>
                    </Grid>
                  </Grid> :
                  null
              }
              <Grid item xs={12}>
                <Grid item sm={12} md={6}>
                  {isSubmitting && <LinearProgress />}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                  <Button
                    variant="outlined"
                    onClick={() => navigate(-1)}
                  >
                    {intl.get('common.cancel')}
                  </Button>
                  &nbsp;
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    {intl.get('common.save')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
