/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';

import intl from 'react-intl-universal';

import { ENDPOINTS, PERMISSIONS, ROUTES } from 'shared/constants';
import { initIntl } from 'shared/locales';
import api from 'shared/api';

import Learner from 'models/Learner';

import { useAppSelector } from 'store/hooks';

import Table, { Field } from 'components/Table';
import { Type } from 'components/NoResultsBox';

initIntl();

const getColumns = () => {
  return [
    intl.get('pages.learners.id'),
    intl.get('pages.learners.name'),
  ];
};

const getFields = (): Field[] => {
  return [
    {
      name: 'id',
    },
    {
      name: 'name',
    },
  ];
};

/**
 * Learners component
 * @return {JSX.Element}
 */
function Learners(): JSX.Element {
  const [learners, setLearners] = useState<Learner[]>([]);
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    getLearners();
  }, []);

  const fields = useMemo(() => {
    return getFields();
  }, []);

  const columns = useMemo(() => {
    return getColumns();
  }, []);

  const selects = useMemo(() => {
    return [];
  }, []);

  const getLearners = async () => {
    const response = await api.get(ENDPOINTS.LEARNERS.GET.ALL);
    setLearners(response.data);
  };

  return (
    <>
      {
        user ? <Table
          type={Type.learner}
          columns={columns}
          fields={fields}
          onRefresh={getLearners}

          filter={{
            data: learners,
            searchMatchFields: ['name'],
            searchPlaceholder: intl.get('pages.learners.searchPlaceholder'),
            selects: selects,
          }}

          deleteDialog={{
            titleIntlKey: 'pages.learners.deleteTitleDialog',
            descriptionIntlKey: 'pages.learners.deleteDescriptionDialog',
          }}

          actions={{
            addPath: PERMISSIONS.LEARNERS.CREATE.includes(user.role.id.toString()) ? ROUTES.LEARNERS_ADD : undefined,
            editPath: PERMISSIONS.LEARNERS.EDIT.includes(user.role.id.toString()) ? ROUTES.LEARNERS_EDIT : undefined,
            deleteEndpoint: PERMISSIONS.LEARNERS.REMOVE.includes(user.role.id.toString()) ? ENDPOINTS.LEARNERS.DELETE.BY_ID : undefined,
          }}
        /> : null
      }

    </>
  );
}

export default Learners;
