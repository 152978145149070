import React, { useEffect, useState } from 'react';

import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';

import Routes from 'routes';

import AppContext, { AppContextState } from 'contexts/app.context';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getUser } from 'store/features/auth/authSlice';

import defaultTheme from 'shared/theme';
import { initIntl } from 'shared/locales';

import ToastContainer from 'components/Toast/container';
import RequestInterceptor from 'components/RequestInterceptor';
import Loading from 'components/RequestInterceptor/Loading';

import 'assets/css/index.css';

/**
 * Application component
 * @return {JSX.Element}
 */
function App(): JSX.Element {
  const dispatch = useAppDispatch();
  const { logged, loading } = useAppSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const contextValues: AppContextState = { isLoading };

  useEffect(() => {
    initIntl();
    dispatch(getUser());
  }, [dispatch]);

  if (!logged && loading) {
    return <Loading open />;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <AppContext.Provider value={contextValues}>
        <ToastContainer />
        <RequestInterceptor isLoading={isLoading} setIsLoading={setIsLoading} />
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
