import intl from 'react-intl-universal';
import { AxiosError } from 'axios';

import { useToastify } from 'hooks/toastfy';

import { initIntl } from 'shared/locales';
import { ERROR_STATUS } from 'shared/constants';

initIntl();

export const displayError = (error: AxiosError) => {
  const toast = useToastify();

  if (error.response) {
    const response = error.response as any;
    if (response.status === ERROR_STATUS.FORBIDDEN) {
      toast.error(intl.get('toast.noPermission'));
    } else {
      toast.error(response.data.message);
    }
  }
};
