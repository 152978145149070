import React, { useEffect, useState } from 'react';

import { FormControl, Grid, TextField } from '@mui/material';

import intl from 'react-intl-universal';

import { ROLES, UTILS } from 'shared/constants';

import { useAppSelector } from 'store/hooks';

interface Props {
  userBelongsToSpecialty: boolean
  isSubmitingObsInputs: boolean
  onChange: (val: string) => void
  onSave: () => void;
  initialValue: string
  type: string
}

/**
 * ObservationInput component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function ObservationInput(props: Props): JSX.Element {
  const { user } = useAppSelector((state) => state.auth);
  const [value, setValue] = useState(UTILS.BLANK);

  useEffect(() => {
    setValue(props.initialValue);
  }, [props.initialValue]);

  useEffect(() => {
    if (props.initialValue !== value) {
      const saveObsInputs = setTimeout(() => props.onSave(), 10000);
      return () => clearTimeout(saveObsInputs);
    }
  }, [value]);

  return <Grid item xs={12}>
    <FormControl fullWidth>
      <TextField
        disabled={
          (user && user.role.id.toString() === ROLES.THERAPIST && !props.userBelongsToSpecialty) || props.isSubmitingObsInputs
        }
        onChange={(e) => {
          setValue(e.target.value);
          props.onChange(e.target.value);
        }}
        InputLabelProps={{ shrink: true }}
        value={value}
        label={intl.get('pages.viewLearner.map.obs')}
        placeholder={intl.get('pages.viewLearner.map.obsPlaceholder', { type: intl.get(`pages.viewLearner.map.behavior.${props.type}`) })}
        minRows={3}
        error={value.length === 0}
        multiline
      />
    </FormControl>
  </Grid>;
}
