import React from 'react';

import { Link } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

import defaultTheme from 'shared/theme';

export interface BreadcrumbsItem {
  name: string
  onClickCallback: () => void
}

interface Props {
  items: BreadcrumbsItem[]
}

/**
  * Breadcrumbs component
  * @param {Props} props
  * @return {JSX.Element}
  */
function Breadcrumbs(props: Props): JSX.Element {
  return <MuiBreadcrumbs separator=">" id="breadcrumbs" sx={{ mb: 2 }}>
    {
      props.items.map((item, index) => <Link
        key={index}
        id={`breadcrumbsItem${index}`}
        underline={'hover'}
        color={'inherit'}
        onClick={item.onClickCallback}
        sx={{
          fontSize: index === 0 ? 18 : 18,
          color: index === 0 ? defaultTheme.palette.primary.main : defaultTheme.palette.text.primary,
          cursor: 'pointer',
        }}
      >
        {item.name}
      </Link>)
    }
  </MuiBreadcrumbs>;
}

export default Breadcrumbs;
