import React, { useEffect, useMemo, useState } from 'react';

import { Autocomplete, Button, Grid, LinearProgress, MenuItem, Typography } from '@mui/material';
import MuiTextField from '@mui/material/TextField';

import intl from 'react-intl-universal';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { AxiosError } from 'axios';

import { useToastify } from 'hooks/toastfy';

import { useAppSelector } from 'store/hooks';

import User from 'models/User';
import Learner from 'models/Learner';
import SpecialtyUser from 'models/SpecialtyUser';

import { displayError } from 'helpers/http';

import api from 'shared/api';
import { initIntl } from 'shared/locales';
import { UTILS, ENDPOINTS, ROUTES } from 'shared/constants';

import { SelectOption } from 'components/Table/Filter';
import Breadcrumbs from 'components/Breadcrumbs';

interface FormData {
  name: string;
  learners_ids: SelectOption[];
  specialties_users_ids: SelectOption[];
}

initIntl();

const getValidation = (user: User | null) => yup.object({
  name: yup
    .string()
    .required(intl.get('validation.requiredField'))
    .max(UTILS.STRING_MAX_LEN),
  learners_ids: yup
    .array()
    .min(1, intl.get('validation.requiredField'))
    .required(intl.get('validation.requiredField')),
  specialties_users_ids: yup
    .array()
    .min(1, intl.get('validation.requiredField'))
    .required(intl.get('validation.requiredField')),
});

/**
 * FormTeam component
 * @return {JSX.Element}
 */
export default function FormTeam(): JSX.Element {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToastify();
  const { id } = useParams<{ id: string }>();
  const [learnerOptions, setLearnerOptions] = useState<SelectOption[]>([]);
  const [specialtyUserOptions, setSpecialtyUserOptions] = useState<SelectOption[]>([]);
  const [initialValues, setInitialValues] = useState({
    name: UTILS.BLANK,
    learners_ids: [],
    specialties_users_ids: [],
  });

  const validationSchema = getValidation(user);

  useEffect(() => {
    if (id) {
      getTeam();
    }
    getLearners();
    getSpecialtiesUsers();
  }, []);

  const teamContext = useMemo(() => {
    // if (location.pathname.search('multifuncionais') !== -1) {
    //   return {
    //     specialtiesLabel: intl.get('pages.formTeam.contents'),
    //     specialtiesPlaceholder: intl.get('pages.formTeam.contentsPlaceholder'),
    //     path: ROUTES.SPECIAL_TEAMS,
    //     special: 1,
    //   };
    // }
    return {
      specialtiesLabel: intl.get('pages.formTeam.specialties'),
      specialtiesPlaceholder: intl.get('pages.formTeam.specialtiesPlaceholder'),
      path: ROUTES.TEAMS,
      special: 0,
    };
  }, [location.pathname]);

  const breadcrumbs = useMemo(() => {
    return [
      {
        name: intl.get('pages.teams.title'),
        onClickCallback: () => navigate(teamContext.path),
      },
      {
        name: `${id ? intl.get('common.edit') : intl.get('common.add')}${UTILS.SPACE}${intl.get('pages.formTeam.title')}`,
        onClickCallback: () => { },
      },
    ];
  }, [id]);

  const getTeam = async () => {
    if (id) {
      const response = await api.get(ENDPOINTS.TEAMS.GET.BY_ID.replace(':id', id));
      setInitialValues({
        name: response.data.name,
        learners_ids: response.data.learners.map((learner: Learner) => ({
          value: learner.id,
          label: learner.name,
        })),
        specialties_users_ids: response.data.specialtiesUsers.map((specialtiesUser: SpecialtyUser) => ({
          value: specialtiesUser.id,
          label: `${specialtiesUser.specialty.name} - ${specialtiesUser.user.name}`,
        })),
      });
    }
  };

  const getLearners = async () => {
    const response = await api.get(ENDPOINTS.LEARNERS.GET.ALL);
    const learners = response.data.map((learner: Learner) => ({
      value: learner.id,
      label: learner.name,
    }));
    setLearnerOptions(learners);
  };

  const getSpecialtiesUsers = async () => {
    const response = await api.get(ENDPOINTS.SPECIALTY_USERS.GET.ALL);
    const specialtiesUsers = response.data.map((specialtiesUser: SpecialtyUser) => ({
      value: specialtiesUser.id,
      label: `${specialtiesUser.specialty.name} - ${specialtiesUser.user.name}`,
    }));
    setSpecialtyUserOptions(specialtiesUsers);
  };

  const save = async (values: FormData) => {
    try {
      const data = {
        name: values.name,
        special: teamContext.special,
        learners_ids: values.learners_ids.map((learnerId) => learnerId.value),
        specialties_users_ids: values.specialties_users_ids.map((specialtyUserId) => specialtyUserId.value),
      };
      if (id) {
        await api.put(ENDPOINTS.TEAMS.PUT.BY_ID.replace(':id', id), data);
      } else {
        await api.post(ENDPOINTS.TEAMS.POST.ADD, data);
      }
      toast.success(intl.get('toast.saveSuccess'));
    } catch (error) {
      displayError(error as AxiosError);
    }
  };

  return (
    <>
      <Breadcrumbs items={breadcrumbs} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await save(values);
          setSubmitting(false);
          navigate(teamContext.path);
        }}
      >
        {({ submitForm, isSubmitting, setFieldValue, values, errors }) => (
          <Form
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                submitForm();
              }
            }}
          >
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <Grid item sm={12} md={6}>
                  <Field
                    component={TextField}
                    fullWidth
                    type="text"
                    label={intl.get('pages.formTeam.name')}
                    name="name"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    multiple
                    fullWidth
                    filterSelectedOptions
                    options={specialtyUserOptions}
                    getOptionLabel={(option: SelectOption) => option.label}
                    value={values['specialties_users_ids']}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(e, value) => setFieldValue('specialties_users_ids', value)}
                    renderInput={(params) => (
                      <MuiTextField
                        error={!!errors['specialties_users_ids']}
                        helperText={!!errors['specialties_users_ids'] ? intl.get('validation.requiredField') : UTILS.BLANK}
                        {...params}
                        label={teamContext.specialtiesLabel}
                        placeholder={teamContext.specialtiesPlaceholder}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    multiple
                    fullWidth
                    filterSelectedOptions
                    options={learnerOptions}
                    getOptionLabel={(option: SelectOption) => option.label}
                    value={values['learners_ids']}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(e, value) => setFieldValue('learners_ids', value)}
                    renderInput={(params) => (
                      <MuiTextField
                        error={!!errors['learners_ids']}
                        helperText={!!errors['learners_ids'] ? intl.get('validation.requiredField') : UTILS.BLANK}
                        {...params}
                        label={intl.get('pages.formTeam.learners')}
                        placeholder={intl.get('pages.formTeam.learnersPlaceholder')}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item sm={12} md={6}>
                  {isSubmitting && <LinearProgress />}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                  <Button
                    variant="outlined"
                    onClick={() => navigate(-1)}
                  >
                    {intl.get('common.cancel')}
                  </Button>
                  &nbsp;
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    {intl.get('common.save')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
