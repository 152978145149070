import React, { useEffect, useMemo, useState } from 'react';

import { FormControl, TextField, Grid, InputLabel, Select, SelectChangeEvent, MenuItem } from '@mui/material';

import intl from 'react-intl-universal';
import { AxiosError } from 'axios';

import { ENDPOINTS, ROLES, UTILS } from 'shared/constants';
import api from 'shared/api';

import Team from 'models/Team';
import Report, { ReportSpecialty } from 'models/Report';
import User from 'models/User';

import { SelectOption } from 'components/Table/Filter';
import { useToastify } from 'hooks/toastfy';
import { useAppSelector } from 'store/hooks';
import { displayError } from 'helpers/http';

interface Props {
  team: Team
  report: Report
  user: User
  specialtyOptions: SelectOption[]
  loggedSpecialtyIds: string[]
  onRefresh: () => {}
  shouldRefresh: () => {}
}

/**
 * ReportTexts component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function ReportTexts(props: Props): JSX.Element {
  const toast = useToastify();
  const { user } = useAppSelector((state) => state.auth);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [specialtyId, setSpecialtyId] = useState(UTILS.BLANK);
  const [reportSpecialtyId, setReportSpecialtyId] = useState(UTILS.BLANK);
  const [isDirty, setIsDirty] = useState(false);

  const [introduction, setIntroduction] = useState(UTILS.BLANK);
  const [procedures, setProcedures] = useState(UTILS.BLANK);
  const [analysis, setAnalysis] = useState(UTILS.BLANK);

  useEffect(() => {
    if (props.specialtyOptions.length && !specialtyId) {
      if (user && user.role.id.toString() === ROLES.CLINIC) {
        setSpecialtyId(props.specialtyOptions[0].value);
      } else {
        setSpecialtyId(props.loggedSpecialtyIds[0]);
      }
    }
  }, [props.specialtyOptions]);

  useEffect(() => {
    if (specialtyId) {
      const reportSpecialty = props.report.reportSpecialties
        .filter((reportSpecialty: ReportSpecialty) => reportSpecialty.specialtyId.toString() === specialtyId)
        .shift();

      if (reportSpecialty) {
        setIntroduction(reportSpecialty.introduction);
        setProcedures(reportSpecialty.procedures);
        setAnalysis(reportSpecialty.analysis);
        setReportSpecialtyId(reportSpecialty.id.toString());
      } else {
        handleCreateReportSpecialty();
      }

      setIsDirty(false);
    }
  }, [specialtyId, props.report]);

  useEffect(() => {
    if (isDirty) {
      const saveReportTexts = setTimeout(() => handleSaveReportSpecialty(), 10000);
      return () => clearTimeout(saveReportTexts);
    }
  }, [introduction, procedures, analysis]);

  const userBelongsToSpecialty = useMemo(() => props.loggedSpecialtyIds.includes(specialtyId), [props.loggedSpecialtyIds, specialtyId]);

  const specialtiesLabel = useMemo(() => props.team.special ?
    intl.get('pages.viewLearner.contentLabel') :
    intl.get('pages.viewLearner.specialtyLabel'), [props]);

  const handleSpecialtyChange = (event: SelectChangeEvent) => {
    props.onRefresh();
    setSpecialtyId(event.target.value as string);
  };

  const handleCreateReportSpecialty = async () => {
    try {
      setIsSubmiting(true);

      await api.post(ENDPOINTS.REPORT_SPECIALTIES.POST.ADD, {
        report_id: props.report.id,
        specialty_id: specialtyId,
      });

      props.onRefresh();
      setIsSubmiting(false);
    } catch (error) {
      setIsSubmiting(false);
      displayError(error as AxiosError);
    }
  };

  const handleSaveReportSpecialty = async () => {
    try {
      setIsSubmiting(true);

      const data = props.report.reportSpecialties
        .filter((reportSpecialty: ReportSpecialty) => reportSpecialty.specialtyId.toString() === specialtyId)
        .flatMap((reportSpecialty: ReportSpecialty) => ({
          introduction,
          procedures,
          analysis,
          evaluation: reportSpecialty.evaluation,
          considerations: reportSpecialty.considerations,
          week_1: reportSpecialty.week1,
          week_2: reportSpecialty.week2,
          week_3: reportSpecialty.week3,
          week_4: reportSpecialty.week4,
          week_5: reportSpecialty.week5,
          week_6: reportSpecialty.week6,
        }))
        .shift();

      await api.put(ENDPOINTS.REPORT_SPECIALTIES.PUT.BY_ID.replace(':id', reportSpecialtyId), data);

      props.shouldRefresh();
      setIsSubmiting(false);
      toast.success(intl.get('toast.saveSuccess'));
    } catch (error) {
      setIsSubmiting(false);
      displayError(error as AxiosError);
    }
  };

  return <Grid container alignItems={'center'} columnGap={6} rowSpacing={3}>
    <Grid item xs={12} md={6}>
      <FormControl fullWidth>
        <InputLabel id="specialty-options-label">{specialtiesLabel}</InputLabel>
        <Select
          labelId="specialty-options-label"
          id="specialty-options-select"
          value={specialtyId}
          label={specialtiesLabel}
          onChange={handleSpecialtyChange}
        >
          {
            props.specialtyOptions && props.specialtyOptions.length ?
              props.specialtyOptions.map((specialtyOption, index) => <MenuItem key={index} value={specialtyOption.value}>
                {specialtyOption.label}
              </MenuItem>) :
              <MenuItem value={UTILS.BLANK}>
                {intl.get('pages.viewLearner.noOptions')}
              </MenuItem>
          }
        </Select>
      </FormControl>
    </Grid>
    <Grid item xs={12} md={6}>
      <FormControl fullWidth>
        <TextField
          disabled={(user && user.role.id.toString() === ROLES.THERAPIST && !userBelongsToSpecialty) || isSubmiting}
          onChange={(e) => {
            setIntroduction(e.target.value);
            setIsDirty(true);
          }}
          InputLabelProps={{ shrink: true }}
          value={introduction}
          label={intl.get('pages.viewLearner.report.introduction')}
          placeholder={intl.get('pages.viewLearner.report.introduction')}
          minRows={3}
          error={introduction.length === 0}
          multiline
        />
      </FormControl>
    </Grid>
    <Grid item xs={12} md={6}>
      <FormControl fullWidth>
        <TextField
          disabled={(user && user.role.id.toString() === ROLES.THERAPIST && !userBelongsToSpecialty) || isSubmiting}
          onChange={(e) => {
            setProcedures(e.target.value);
            setIsDirty(true);
          }}
          InputLabelProps={{ shrink: true }}
          value={procedures}
          label={intl.get('pages.viewLearner.report.procedures')}
          placeholder={intl.get('pages.viewLearner.report.procedures')}
          minRows={3}
          error={procedures.length === 0}
          multiline
        />
      </FormControl>
    </Grid>
    <Grid item xs={12} md={6}>
      <FormControl fullWidth>
        <TextField
          disabled={(user && user.role.id.toString() === ROLES.THERAPIST && !userBelongsToSpecialty) || isSubmiting}
          onChange={(e) => {
            setAnalysis(e.target.value);
            setIsDirty(true);
          }}
          InputLabelProps={{ shrink: true }}
          value={analysis}
          label={intl.get('pages.viewLearner.report.analysis')}
          placeholder={intl.get('pages.viewLearner.report.analysis')}
          minRows={3}
          error={analysis.length === 0}
          multiline
        />
      </FormControl>
    </Grid>
  </Grid >;
}
