import * as React from 'react';

import { Backdrop, CircularProgress } from '@mui/material';

interface Props {
  open: boolean;
}

/**
 * Loading component
 * @param {Props} props
 * @return {JSX.Element}
 */
function Loading(props: Props): JSX.Element {
  return (<Backdrop
    id="loading"
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={props.open}
  >
    <CircularProgress color="inherit" />
  </Backdrop>);
}

export default Loading;
