import React, { Fragment, SyntheticEvent, useEffect, useState } from 'react';

import MuiAutocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { CircularProgress, FilterOptionsState, TextField } from '@mui/material';

import intl from 'react-intl-universal';
import { AxiosError } from 'axios';

import { ENDPOINTS, UTILS } from 'shared/constants';
import api from 'shared/api';

import Goal from 'models/Goal';

import { displayError } from 'helpers/http';

interface Props {
  goal: Goal;
  index: number;
  disabled: boolean;
  onChange: (name: string, index: number) => void;
}

interface GoalOption {
  name: string;
  inputValue?: string;
}

const filter = createFilterOptions<GoalOption>();

/**
 * AutocompleteAsync component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function AutocompleteAsync(props: Props): JSX.Element {
  const [goalOptions, setGoalOptions] = useState<GoalOption[]>([]);
  const [value, setValue] = useState<GoalOption | null>(null);
  const [query, setQuery] = useState(UTILS.BLANK);
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [open, setOpen] = useState(false);
  const id = `goalAutocompleteAsync${props.index}`;

  useEffect(() => {
    setValue({
      name: props.goal.name,
    });
  }, [props.goal]);

  useEffect(() => {
    if (!open) {
      setGoalOptions([]);
    }
  }, [open]);

  useEffect(() => {
    if (value && isDirty) {
      props.onChange(value.name, props.index);
      setIsDirty(false);
    } else {
      setGoalOptions([]);
    }
  }, [value]);

  useEffect(() => {
    const minLength = 4;
    if (query.length >= minLength) {
      const queryGoals = setTimeout(() => getGoalsOptions(query), 500);
      return () => clearTimeout(queryGoals);
    }
  }, [query]);

  const getGoalsOptions = async (query?: string) => {
    try {
      setIsLoading(true);
      const response = await api.get(ENDPOINTS.GOALS.GET.QUERY.replace(':query', encodeURIComponent(query || UTILS.BLANK)));
      setIsLoading(false);
      const newGoalOptions = response.data.map((goal: Goal) => ({
        name: goal.name,
      }));
      setGoalOptions(newGoalOptions);
    } catch (error) {
      setIsLoading(false);
      displayError(error as AxiosError);
    }
  };

  const getOptionLabel = (option: string | GoalOption) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.name;
  };

  const filterOptions = (options: GoalOption[], params: FilterOptionsState<GoalOption>) => {
    const filtered = filter(options, params);

    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option.name);
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        inputValue: inputValue,
        name: `${intl.get('common.add')} "${inputValue}"`,
      });
    }

    return filtered;
  };

  const onOptionChange = (event: SyntheticEvent<Element, Event>, newValue: string | GoalOption | null) => {
    setIsDirty(true);
    if (typeof newValue === 'string') {
      setValue({
        name: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setValue({
        name: newValue.inputValue,
      });
    } else {
      setValue(newValue);
    }
  };

  return <MuiAutocomplete
    freeSolo
    fullWidth
    clearOnBlur
    selectOnFocus
    handleHomeEndKeys
    id={id}
    open={open}
    value={value}
    loading={isLoading}
    options={goalOptions}
    disabled={props.disabled}
    onOpen={() => setOpen(true)}
    onClose={() => setOpen(false)}
    onChange={onOptionChange}
    filterOptions={filterOptions}
    getOptionLabel={getOptionLabel}
    renderOption={(props, option) => <li {...props}>{option.name}</li>}
    renderInput={(params) => (
      <TextField
        {...params}
        label={`${intl.get('pages.viewLearner.pdt.goal')}${UTILS.SPACE}${props.index + 1}`}
        onChange={(e) => setQuery(e.target.value)}
        error={(!!value && value.name.length === 0) || !!!value}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <Fragment>
              {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
              {/* {params.InputProps.endAdornment} */}
            </Fragment>
          ),
        }}
      />
    )}
  />;
}
