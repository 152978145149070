/* eslint-disable quote-props */
import React, { CSSProperties } from 'react';

import { Hidden, IconButton, Typography, useMediaQuery, Box, Toolbar, SxProps, Theme } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';

import intl from 'react-intl-universal';
import { useNavigate } from 'react-router-dom';

import { logout } from 'store/features/auth/authSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import defaultTheme from 'shared/theme';
import { ROUTES } from 'shared/constants';

import LogoIIClearImage from 'assets/image/logo_ii_clear.png';
import LogoSOPClearImage from 'assets/image/logo_sop_clear.png';

import Drawer from './Drawer';

const drawerWidth = 200;

const imageStyle: CSSProperties = {
  cursor: 'pointer',
  height: '45px',
};

const appBarStyle = (smDown: boolean): SxProps<Theme> => ({
  height: '70px',
  width: smDown ? '100%' : `calc(100% - ${drawerWidth}px)`,
  ml: smDown ? 'unset' : `${drawerWidth}px`,
  backgroundColor: '#3C445C',
});

const boxStyle: SxProps<Theme> = { display: 'flex' };

const toolBarStyle: SxProps<Theme> = { minHeight: '70px' };

const menuIconStyle: SxProps<Theme> = { float: 'right' };

/**
 * Permanent Drawer component
 * @return {JSX.Element}
 */
export default function AppBar(): JSX.Element {
  const smDown = useMediaQuery(defaultTheme.breakpoints.down('md'));
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const toggleDrawer = (open: boolean) => setOpenDrawer(open);

  const onLogout = async () => {
    await dispatch(logout());
  };

  return (
    <Box sx={boxStyle}>
      <MuiAppBar
        elevation={0}
        position="fixed"
        sx={appBarStyle(smDown)}
      >
        <Toolbar sx={toolBarStyle}>
          <Hidden mdUp>
            <img style={imageStyle} src={LogoSOPClearImage} onClick={() => navigate(ROUTES.ROOT)} />
          </Hidden>
          <Box flexGrow={1} />
          <Hidden mdDown>
            {
              user ? <Typography sx={{ color: 'white' }}>{user.name} ({user.role.name}) </Typography> : null
            }
            <IconButton onClick={onLogout} sx={{ color: 'white' }} aria-label={intl.get('components.appBar.logout')}>
              <LogoutIcon />
            </IconButton>
          </Hidden>
          <Hidden mdUp>
            <IconButton sx={{ color: 'white' }} disableRipple onClick={() => toggleDrawer(true)}>
              <MenuIcon sx={menuIconStyle} />
            </IconButton>
          </Hidden>
        </Toolbar>
      </MuiAppBar>
      <Drawer open={openDrawer} onToggle={toggleDrawer} />
    </Box>
  );
}
