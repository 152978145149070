import React, { ChangeEvent, CSSProperties, useEffect, useState } from 'react';

import { Grid, IconButton, InputLabel, LinearProgress, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import ImageViewer from 'react-simple-image-viewer';
import intl from 'react-intl-universal';
import { AxiosError } from 'axios';

import api from 'shared/api';
import { ENDPOINTS, ROLES, UTILS } from 'shared/constants';

import { ReportSpecialtyImage } from 'models/Report';

import { displayError } from 'helpers/http';

import { useToastify } from 'hooks/toastfy';

import { useAppSelector } from 'store/hooks';

import { NoResultsBox, Type } from 'components/NoResultsBox';
import ConditionalContainer from 'components/ConditionalContainer';

const thumbStyle: CSSProperties = {
  borderRadius: '8px',
  cursor: 'pointer',
  marginTop: '16px',
  width: '300px',
  height: '300px',
  display: 'flex',
};

interface Props {
  reportSpecialtyId: string
  userBelongsToSpecialty: boolean
  image?: ReportSpecialtyImage
}

/**
 * VisualRecord component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function VisualRecord(props: Props): JSX.Element {
  const { user } = useAppSelector((state) => state.auth);
  const toast = useToastify();
  const [thumb, setThumb] = useState(UTILS.BLANK);
  const [id, setId] = useState<number>();
  const [original, setOriginal] = useState(UTILS.BLANK);
  const [open, setOpen] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);

  useEffect(() => {
    if (props.image) {
      setId(props.image.id);
      setOriginal(props.image.path);
      const parts = props.image.path.split('/');
      const fileName = parts.pop();
      setThumb(`${parts.join('/')}/thumb_${fileName}`);
    } else {
      setId(undefined);
      setOriginal(UTILS.BLANK);
      setThumb(UTILS.BLANK);
    }
  }, [props.image]);

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const uploadedFile: File = event.target.files[0];
      event.target.value = UTILS.BLANK;
      const url = URL.createObjectURL(uploadedFile);
      setThumb(url);
      setOriginal(url);
      try {
        const FD = new FormData();
        FD.append('image', uploadedFile, uploadedFile.name);
        FD.append('report_specialty_id', props.reportSpecialtyId);
        setIsSubmiting(true);
        const response = await api.post(ENDPOINTS.REPORT_SPECIALTY_IMAGES.POST.ONE, FD);
        setIsSubmiting(false);
        setId(response.data.id);
        toast.success(intl.get('toast.saveSuccess'));
      } catch (error) {
        setIsSubmiting(false);
        setThumb(UTILS.BLANK);
        setOriginal(UTILS.BLANK);
        displayError(error as AxiosError);
      }
    }
  };

  const handleDelete = async () => {
    if (id) {
      try {
        setIsSubmiting(true);
        await api.delete(ENDPOINTS.REPORT_SPECIALTY_IMAGES.DELETE.BY_ID.replace(':id', id.toString()));
        setIsSubmiting(false);
        toast.success(intl.get('toast.deleteSuccess'));
        setThumb(UTILS.BLANK);
        setOriginal(UTILS.BLANK);
      } catch (error) {
        setIsSubmiting(false);
        displayError(error as AxiosError);
      }
    } else {
      setThumb(UTILS.BLANK);
    }
  };

  return <>
    <Grid item xs={12}>
      <InputLabel sx={{ mb: 2 }}>{intl.get('pages.viewLearner.map.visualRecord')}</InputLabel>
      {
        ((user && user.role.id.toString() === ROLES.CLINIC) || props.userBelongsToSpecialty) ?
          <>
            <input
              type="file"
              disabled={isSubmiting}
              onChange={(e) => handleChange(e)}
            />
            <Typography mt={1}>{intl.get('pages.viewLearner.map.acceptedFiles')}</Typography>
          </> :
          null
      }
    </Grid>
    <ConditionalContainer noComponent checkIf={isSubmiting}>
      <Grid item xs={12} mb={3} mt={1}>
        <Grid item xs={12} md={6}>
          {isSubmiting && <LinearProgress />}
        </Grid>
      </Grid>
    </ConditionalContainer>
    {
      open && (
        <ImageViewer
          backgroundStyle={{ zIndex: 999999 }}
          src={[original]}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={() => setOpen(false)}
        />
      )
    }
    {
      thumb ?
        <Grid container columnSpacing={1} alignItems={'center'}>
          <Grid item xs={'auto'}>
            <img
              onClick={() => setOpen(true)}
              style={thumbStyle}
              src={thumb}
              alt="image"
            />
          </Grid>
          <Grid item xs={'auto'}>
            <IconButton onClick={handleDelete} disabled={isSubmiting}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid> :
        <NoResultsBox
          filtered={false}
          show={!!!thumb}
          type={Type.reportImage}
        />
    }
  </>;
}
