import React, { useEffect, useState } from 'react';

import { Backdrop, Box, Button, Typography, useMediaQuery } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';

import { BlobProvider, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { useNavigate } from 'react-router-dom';
import * as htmlToImage from 'html-to-image';
import intl from 'react-intl-universal';
import { AxiosError } from 'axios';

import api from 'shared/api';

import Report from 'models/Report';
import Team from 'models/Team';
import Learner from 'models/Learner';

import { ENDPOINTS, ROUTES, UTILS } from 'shared/constants';
import defaultTheme from 'shared/theme';

import { displayError } from 'helpers/http';

import { useToastify } from 'hooks/toastfy';

import GoalsChart from './GoalsChart';
import ReportDocument from './Document';

interface Props {
  learnerId: string
  teamId: string
  viewer?: boolean
  partial?: boolean
}

export const downloadOrSendReportButton = (
  partial: boolean,
  disabled: boolean,
  report?: Report,
  blob?: Blob,
  send?: (report: Report, blob: Blob) => void,
) => {
  return <Button
    variant="contained"
    color="primary"
    disabled={disabled}
    sx={{ width: '300px', mb: 2 }}
    startIcon={send ? <AttachEmailIcon /> : <DownloadIcon />}
    onClick={() => report && blob && send ? send(report, blob) : null}
  >
    {
      send ? intl.get('components.reportPDF.sendFullReport') :
        partial ?
          intl.get('components.reportPDF.downloadPartialReport') :
          intl.get('components.reportPDF.downloadFullReport')
    }
  </Button>;
};

/**
 * ReportPDF component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function ReportPDF(props: Props): JSX.Element {
  const navigate = useNavigate();
  const lgUp = useMediaQuery(defaultTheme.breakpoints.up('lg'));
  const [team, setTeam] = useState<Team>();
  const [learner, setLearner] = useState<Learner>();
  const [report, setReport] = useState<Report>();
  const [charts, setCharts] = useState<Map<number, string>>(new Map());
  const [images, setImages] = useState<Map<number, string>>(new Map());
  const [showPdf, setShowPdf] = useState(false);
  const [start, setStart] = useState(false);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if (props.teamId) {
      getTeam();
    }
  }, [props.teamId]);

  useEffect(() => {
    if (props.learnerId && team) {
      getLearner();
    }
  }, [props.learnerId, team]);

  useEffect(() => {
    if (learner && team) {
      getReport();
    }
  }, [learner, team]);

  useEffect(() => {
    if (report) {
      setTimeout(() => {
        setStart(true);
      }, 1000);
    }
  }, [report]);

  useEffect(() => {
    setTimeout(() => {
      chartToImage();
    }, 2000);
  }, [start]);

  useEffect(() => {
    if (charts.size) {
      getReportSpecialtyImages();
    }
  }, [charts]);

  useEffect(() => {
    if (props.partial) {
      setShowPdf(true);
    }
  }, [props.partial]);

  const getTeam = async () => {
    if (props.teamId) {
      try {
        const response = await api.get(ENDPOINTS.TEAMS.GET.BY_ID.replace(':id', props.teamId));
        setTeam(response.data);
      } catch (error) {
        displayError(error as AxiosError);
        navigate(ROUTES.TEAMS);
      }
    }
  };

  const getLearner = async () => {
    if (props.learnerId && team) {
      try {
        const response = await api.get(ENDPOINTS.LEARNERS.GET.BY_ID.replace(':id', props.learnerId));
        setLearner(response.data);
      } catch (error) {
        displayError(error as AxiosError);
        navigate(ROUTES.TEAMS_VIEW.replace(':id', team.id.toString()));
      }
    }
  };

  const getReport = async () => {
    if (learner && team) {
      try {
        const learnerTeamResponse = await api.get(
          ENDPOINTS.LEARNER_TEAM.GET.BY_LEARNER_ID_AND_TEAM_ID
            .replace(':learnerId', learner.id.toString())
            .replace(':teamId', team.id.toString()),
        );

        const response = await api.post(ENDPOINTS.REPORTS.POST.ADD, {
          learner_team_id: learnerTeamResponse.data.id,
        });

        setReport(response.data);
      } catch (error) {
        displayError(error as AxiosError);
      }
    }
  };

  const sendReportViaEmail = async (report: Report, blob: Blob) => {
    try {
      setSending(true);
      const toast = useToastify();

      const file = new File([blob], 'report.pdf', { type: blob.type });

      const FD = new FormData();
      FD.append('file', file, file.name);

      await api.post(ENDPOINTS.REPORTS.POST.SEND.replace(':id', report.id.toString()), FD);

      toast.success(intl.get('toast.sendSuccess'));
      setSending(false);
    } catch (error) {
      setSending(false);
      displayError(error as AxiosError);
    }
  };

  const getReportSpecialtyImages = async () => {
    try {
      if (report) {
        const newImages: Map<number, string> = new Map();
        for (const reportSpecialty of report.reportSpecialties) {
          if (reportSpecialty.image) {
            const response = await api.get(ENDPOINTS.REPORT_SPECIALTY_IMAGES.GET.BY_ID.replace(':id', reportSpecialty.image.id.toString()));
            newImages.set(reportSpecialty.image.id, response.data);
          }
        }
        setImages(newImages);
        setShowPdf(true);
      }
    } catch (error) {
      displayError(error as AxiosError);
    }
  };

  const chartToImage = async () => {
    if (report) {
      const newCharts: Map<number, string> = new Map();
      for (const reportSpecialty of report.reportSpecialties) {
        const chart = document.getElementById(`chart${reportSpecialty.specialtyId}`);
        if (chart) {
          await htmlToImage.toPng(chart).then((dataUrl) => {
            newCharts.set(reportSpecialty.specialtyId, dataUrl);
          });
        } else {
          newCharts.set(reportSpecialty.specialtyId, UTILS.BLANK);
        }
      }
      setCharts(newCharts);
    }
  };

  return <>
    {
      start && report ? <>
        {
          report.reportSpecialties.map((rs, index) => <GoalsChart
            key={index}
            hide={showPdf}
            goals={rs.goals}
            specialtyId={rs.specialtyId}
          />)
        }
      </> : null
    }
    {
      !showPdf ? <>
        <Backdrop
          id="loading"
          sx={{ color: 'primary', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'white' }}
          open={true}
        >
          <Box p={2}>
            {
              !charts.size ?
                <Typography sx={{ color: '#dccbb5', fontSize: '35px' }}>
                  {intl.get('components.reportPDF.warning')}
                </Typography> :
                charts.size ?
                  <Typography sx={{ color: '#dccbb5', fontSize: '35px' }}>
                    {intl.get('components.reportPDF.loading')}
                  </Typography> :
                  !images.size && !props.partial ?
                    <Typography sx={{ color: '#dccbb5', fontSize: '35px' }}>
                      {intl.get('components.reportPDF.gettingVisualRecords')}
                    </Typography> :
                    start ?
                      <Typography sx={{ color: '#dccbb5', fontSize: '35px' }}>
                        {intl.get('components.reportPDF.buildingReport')}
                      </Typography> :
                      null
            }
          </Box>
        </Backdrop>
      </> : null
    }
    {
      !!report ?
        props.viewer ?
          <PDFViewer style={{ border: 0, height: '100vh', width: '100vw' }}>
            <ReportDocument partial={props.partial} charts={charts} images={images} report={report} />
          </PDFViewer> :
          <>
            {
              !lgUp ?
                <PDFDownloadLink
                  style={{ textDecoration: 'none' }}
                  fileName={`${report.learner.name}${UTILS.DOT}pdf`}
                  document={<ReportDocument partial={props.partial} charts={charts} images={images} report={report} />}
                >
                  {
                    ({ blob, url, loading, error }) => {
                      if (blob) {
                        return downloadOrSendReportButton(props.partial || false, loading, report, blob);
                      }
                      return <></>;
                    }
                  }
                </PDFDownloadLink> :
                null
            }
            {/* {
              !props.partial ? <BlobProvider
                document={<ReportDocument partial={props.partial} charts={charts} images={images} report={report} />}
              >
                {
                  ({ blob, url, loading, error }) => {
                    if (blob) {
                      return downloadOrSendReportButton(false, sending, report, blob, sendReportViaEmail);
                    }
                    return <></>;
                  }
                }
              </BlobProvider> : null
            } */}
          </> :
        null
    }
  </>;
}
