import React, { useEffect, useMemo, useState } from 'react';

import intl from 'react-intl-universal';

import { ENDPOINTS, PERMISSIONS, ROLES, ROLES_OPTIONS, ROUTES } from 'shared/constants';
import { initIntl } from 'shared/locales';
import api from 'shared/api';

import User from 'models/User';

import { useAppSelector } from 'store/hooks';

import Table, { Field } from 'components/Table';
import { Type } from 'components/NoResultsBox';

initIntl();

const getColumns = (user: User) => {
  const columns = [
    intl.get('pages.users.id'),
    intl.get('pages.users.name'),
    intl.get('pages.users.role'),
    intl.get('pages.users.clinic'),
  ];

  if (user.role.id.toString() === ROLES.ADMIN) {
    return columns;
  }

  columns.pop();
  return columns;
};

const getFields = (user: User): Field[] => {
  const fields = [
    {
      name: 'id',
    },
    {
      name: 'name',
    },
    {
      name: 'role.name',
    },
    {
      name: 'clinic.name',
    },
  ];

  if (user.role.id.toString() === ROLES.ADMIN) {
    return fields;
  }

  fields.pop();
  return fields;
};

/**
 * Users component
 * @return {JSX.Element}
 */
function Users(): JSX.Element {
  const [users, setUsers] = useState<User[]>([]);
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    getUsers();
  }, []);

  const fields = useMemo(() => {
    if (user) {
      return getFields(user);
    }
    return [];
  }, [user]);

  const columns = useMemo(() => {
    if (user) {
      return getColumns(user);
    }
    return [];
  }, [user]);

  const selects = useMemo(() => {
    if (user && user.role.id.toString() === ROLES.ADMIN) {
      return [
        {
          selectAllLabel: intl.get('common.all'),
          selectLabel: 'Filtre por perfil',
          selectMatchField: 'role',
          matchFieldCustomProp: 'id',
          selectOptions: ROLES_OPTIONS,
        },
      ];
    }
    return [];
  }, [user]);

  const getUsers = async () => {
    const response = await api.get(ENDPOINTS.USERS.GET.ALL);
    setUsers(response.data);
  };

  return (
    <>
      {
        user ? <Table
          type={Type.user}
          columns={columns}
          fields={fields}
          onRefresh={getUsers}

          filter={{
            data: users,
            searchMatchFields: ['name', 'email'],
            searchPlaceholder: intl.get('pages.users.searchPlaceholder'),
            selects: selects,
          }}

          deleteDialog={{
            titleIntlKey: 'pages.users.deleteTitleDialog',
            descriptionIntlKey: 'pages.users.deleteDescriptionDialog',
          }}

          actions={{
            addPath: PERMISSIONS.USERS.CREATE.includes(user.role.id.toString()) ? ROUTES.USERS_ADD : undefined,
            editPath: PERMISSIONS.USERS.EDIT.includes(user.role.id.toString()) ? ROUTES.USERS_EDIT : undefined,
            deleteEndpoint: PERMISSIONS.USERS.REMOVE.includes(user.role.id.toString()) ? ENDPOINTS.USERS.DELETE.BY_ID : undefined,
          }}
        /> : null
      }

    </>
  );
}

export default Users;
