import React from 'react';

import { Box, CssBaseline, SxProps, Theme, useMediaQuery } from '@mui/material';

import { Outlet } from 'react-router-dom';

import defaultTheme from 'shared/theme';

const containerStyle = (smDown: boolean): SxProps<Theme> => ({
  backgroundColor: smDown ? 'ffffff' : '#f3f3f4',
  top: 0,
  bottom: 0,
});

/**
 * Login layout
 * @return {JSX.Element}
 */
function LoginLayout(): JSX.Element {
  const smDown = useMediaQuery(defaultTheme.breakpoints.down('md'));
  return (
    <>
      <CssBaseline />
      <Box id="loginLayout" sx={containerStyle(smDown)}>
        <Outlet />
      </Box>
    </>
  );
};

export default LoginLayout;
