import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import query from 'query-string';

import { RESET_PASSWORD, ROUTES } from 'shared/constants';

import ForgotPasswordForm from './ForgotPasswordForm';
import CreatePasswordForm from './CreatePasswordForm';
import ResetPasswordForm from './ResetPasswordForm';
import LoginForm from './LoginForm';

import LogoClathImage from 'assets/image/logo_clath.png';

enum Pages {
  NONE = '',
  CREATE_PASSWORD = 'createPassword',
  LOGIN = 'login',
  FORGOT = 'forgot',
  RESET_PASSWORD = 'resetPassword',
};

/**
 * Login component
 * @return {JSX.Element}
 */
function Login(): JSX.Element {
  const location = useLocation();
  const { id, token } = useParams<{ id: string, token: string }>();

  const [page, setPage] = useState<Pages>(Pages.NONE);
  const params = query.parse(location.search);

  useEffect(() => {
    if (id && token) {
      setPage(Pages.CREATE_PASSWORD);
    } else if (location.pathname === ROUTES.FORGOT_PASSWORD) {
      setPage(Pages.FORGOT);
    } else if (token && params.email && location.pathname.search(RESET_PASSWORD) !== -1) {
      setPage(Pages.RESET_PASSWORD);
    } else {
      setPage(Pages.LOGIN);
    }
  }, [id, token, params, location.pathname]);

  const pages: { [key: string]: any } = {
    [Pages.LOGIN]: <LoginForm />,
    [Pages.CREATE_PASSWORD]: <CreatePasswordForm />,
    [Pages.FORGOT]: <ForgotPasswordForm />,
    [Pages.RESET_PASSWORD]: <ResetPasswordForm />,
  };

  return (
    <Grid container alignItems="center" alignContent="center" justifyContent="center" sx={{ height: '100vh' }}>
      <Box width="400px" sx={{ backgroundColor: '#ffffff', padding: 3, borderRadius: 2 }}>
        <Grid container justifyContent="space-evenly" rowSpacing={2} mt={1}>
          <img width="140px" src={LogoClathImage} alt="Logo CLATH" />
        </Grid>
        <Grid container justifyContent="center" rowSpacing={2} mt={2}>
          {pages[page]}
        </Grid>
      </Box>
    </Grid>
  );
}

export default Login;
