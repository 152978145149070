import React, { useState, useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, IconButton, InputAdornment, MenuItem, OutlinedInput, TextField } from '@mui/material';

import { sanitizeString, normalizeString } from 'helpers/text';

import { UTILS } from 'shared/constants';

interface GenericObject {
  [key: string]: any;
};

export interface SelectOption {
  value: string;
  label: string;
};

export interface SelectProps {
  selectLabel: string;
  selectAllLabel: string;
  selectOptions: SelectOption[];
  selectMatchField: string;
  matchFieldCustomProp?: string;
};

interface Props<T> {
  data: T[];
  setFiltered: (filtered: T[]) => void;
  setPaginated: (paginated: T[]) => void;
  setPage: (page: number) => void;

  searchPlaceholder: string;
  searchMatchFields: string[];

  selects?: SelectProps[];
}

/**
 * Filter component
 * @param {Props} props
 * @return {JSX.Element}
 */
function Filter<T extends GenericObject>(props: Props<T>): JSX.Element {
  const [searchValue, setSearchValue] = useState(UTILS.BLANK);
  const [selectValues, setSelectValues] = useState<string[]>([]);

  useEffect(() => {
    if (props.selects && props.selects.length) {
      const selectValuesArr: string[] = [...selectValues];
      props.selects.forEach((select, index) => {
        const values = select.selectOptions.map((option) => option.value);
        if (values.length && !selectValues[index]) {
          selectValuesArr[index] = select.selectAllLabel;
        }
      });
      setSelectValues(selectValuesArr);
    }
  }, [props.selects]);

  useEffect(() => {
    filter();
  }, [props.data, selectValues, searchValue]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(sanitizeString(event.target.value));
  };

  const handleSelectChange = (value: string, index: number) => {
    const copySelectValues = [...selectValues];
    copySelectValues[index] = value;
    setSelectValues(copySelectValues);
  };

  const getValue = (value: T, field: string) => {
    if (field.indexOf('.') !== -1) {
      const fields = field.split('.');
      const object = value[fields[0]];
      if (object) {
        return object[fields[1]];
      }
      return '-';
    }
    return value[field] || '-';
  };

  const searchMatcher = (object: T, inputValue: string) => {
    for (let index = 0; index < props.searchMatchFields.length; index++) {
      let objectValue = getValue(object, props.searchMatchFields[index]);
      if (typeof objectValue !== 'number') {
        objectValue = normalizeString(objectValue);
        inputValue = normalizeString(inputValue);
        if (objectValue.indexOf(inputValue) >= 0) {
          return true;
        }
      } else {
        if (objectValue.toString() === inputValue) {
          return true;
        }
      }
    }
    return false;
  };

  const getObjectValue = (object: T, select: SelectProps) => {
    if (select.matchFieldCustomProp) {
      return object[select.selectMatchField][select.matchFieldCustomProp];
    } else {
      return object[select.selectMatchField];
    }
  };

  const selectMatcher = (object: T) => {
    if (props.selects) {
      for (let i = 0; i < props.selects.length; i++) {
        const select = props.selects[i];
        const objectValue = getObjectValue(object, select);
        if (
          selectValues[i] &&
          selectValues[i] != objectValue &&
          selectValues[i] !== select.selectAllLabel
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const filter = () => {
    let filtered = props.data;
    if (searchValue.length || selectValues.length) {
      filtered = props.data.filter((value: T) => {
        if (selectMatcher(value) && searchMatcher(value, searchValue)) {
          return true;
        }
        return false;
      });
    }
    props.setPaginated([]);
    props.setPage(0);
    props.setFiltered(filtered);
  };

  return (
    <div id="filter">
      <Grid container alignItems={'center'} columnSpacing={3} rowSpacing={3}>
        {
          selectValues.length === props.selects?.length ?
            props.selects?.map((select, index) => <Grid item md="auto" key={index}>
              <TextField
                select
                id="filterTextFieldFunctions"
                disabled={!props.data.length}
                value={selectValues[index]}
                label={select.selectLabel}
                onChange={(e) => handleSelectChange(e.target.value, index)}
                sx={{
                  width: '300px',
                }}
                fullWidth
                size="small"
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                  },
                }}
              >
                <MenuItem value={select.selectAllLabel}>
                  {select.selectAllLabel}
                </MenuItem>
                {
                  select.selectOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                }
              </TextField>
            </Grid>) : null
        }
        <Grid item md="auto">
          <OutlinedInput
            disabled={!props.data.length}
            id="filterInput"
            placeholder={props.searchPlaceholder}
            value={searchValue}
            onChange={handleInputChange}
            sx={{
              width: '300px',
            }}
            fullWidth
            size="small"
            endAdornment={<InputAdornment position="end">
              <IconButton
                disableRipple
                onClick={() => setSearchValue(UTILS.BLANK)}>
                {
                  searchValue.length ? <CloseIcon /> : <SearchIcon />
                }
              </IconButton>
            </InputAdornment>}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default Filter;
