import React, { useEffect, useMemo, useRef, useState } from 'react';

import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';

import intl from 'react-intl-universal';
import { AxiosError } from 'axios';

import { ReportSpecialtyBehavioralAspect } from 'models/ReportSpecialtyBehavioralAspect';
import Report, { ReportSpecialty, ReportSpecialtyImage } from 'models/Report';
import { BehavioralAspectsByType } from 'models/BehavioralAspect';
import Team from 'models/Team';
import User from 'models/User';
import Goal from 'models/Goal';

import { ENDPOINTS, ROLES, UTILS } from 'shared/constants';
import api from 'shared/api';

import { displayError } from 'helpers/http';

import { useToastify } from 'hooks/toastfy';

import { useAppSelector } from 'store/hooks';

import { minGoals } from '../Pdt';

import ConditionalContainer from 'components/ConditionalContainer';
import { SelectOption } from 'components/Table/Filter';
import BehavioralAspects from './BehavioralAspects';
import Evaluation from './Evaluation';
import Considerations from './Considerations';
import Goals from './Goals';
import VisualRecord from './VisualRecord';

interface Props {
  team: Team
  report: Report
  user: User
  specialtyOptions: SelectOption[]
  behavioralAspects: BehavioralAspectsByType
  loggedSpecialtyIds: string[]
  onRefresh: () => {}
  shouldRefresh: () => {}
}

/**
 * Mapa component
 * @param {Props} props
 * @return {JSX.Element}
 */
export default function Mapa(props: Props): JSX.Element {
  const { user } = useAppSelector((state) => state.auth);
  const toast = useToastify();
  const goalsInputs = useRef<Goal[]>([]);
  const evaluationInput = useRef(UTILS.BLANK);
  const considerationsInput = useRef(UTILS.BLANK);
  const behavioralObsInput = useRef(UTILS.BLANK);
  const cognitiveObsInput = useRef(UTILS.BLANK);
  const adaptiveObsInput = useRef(UTILS.BLANK);
  const [specialtyId, setSpecialtyId] = useState(UTILS.BLANK);
  const [reportSpecialtyId, setReportSpecialtyId] = useState(UTILS.BLANK);
  const [reportSpecialtyImage, setReportSpecialtyImage] = useState<ReportSpecialtyImage>();
  const [isSubmitingReportSpecialty, setIsSubmitingReportSpecialty] = useState(false);
  const [reportSpecialtyBehavioralAspects, setReportSpecialtyBehavioralAspects] = useState<
    Map<number, ReportSpecialtyBehavioralAspect>
  >(new Map());

  useEffect(() => {
    if (props.specialtyOptions.length && !specialtyId) {
      if (user && user.role.id.toString() === ROLES.CLINIC) {
        setSpecialtyId(props.specialtyOptions[0].value);
      } else {
        setSpecialtyId(props.loggedSpecialtyIds[0]);
      }
    }
  }, [props.specialtyOptions]);

  useEffect(() => {
    if (specialtyId) {
      evaluationInput.current = UTILS.BLANK;
      considerationsInput.current = UTILS.BLANK;
      behavioralObsInput.current = UTILS.BLANK;
      cognitiveObsInput.current = UTILS.BLANK;
      adaptiveObsInput.current = UTILS.BLANK;
      const newReportSpecialtyBehavioralAspects: Map<number, ReportSpecialtyBehavioralAspect> = new Map();

      const newGoals = props.report.reportSpecialties
        .filter((reportSpecialty: ReportSpecialty) => {
          if (reportSpecialty.specialtyId.toString() === specialtyId) {
            setReportSpecialtyId(reportSpecialty.id.toString());
            setReportSpecialtyImage(reportSpecialty.image);

            evaluationInput.current = reportSpecialty.evaluation;
            considerationsInput.current = reportSpecialty.considerations;
            behavioralObsInput.current = reportSpecialty.behavioralObs;
            cognitiveObsInput.current = reportSpecialty.cognitiveObs;
            adaptiveObsInput.current = reportSpecialty.adaptiveObs;

            reportSpecialty.behavioralAspects.forEach((reportSpecialtyBehavioralAspect) => {
              newReportSpecialtyBehavioralAspects.set(reportSpecialtyBehavioralAspect.behavioralAspectId, reportSpecialtyBehavioralAspect);
            });

            return true;
          }
          return false;
        })
        .flatMap((reportSpecialty: ReportSpecialty) => reportSpecialty.goals);

      setReportSpecialtyBehavioralAspects(newReportSpecialtyBehavioralAspects);
      goalsInputs.current = newGoals;
    }
  }, [specialtyId, props.report]);

  const userBelongsToSpecialty = useMemo(() => props.loggedSpecialtyIds.includes(specialtyId), [props.loggedSpecialtyIds, specialtyId]);

  const specialtiesLabel = useMemo(() => props.team.special ?
    intl.get('pages.viewLearner.contentLabel') :
    intl.get('pages.viewLearner.specialtyLabel'), [props]);

  const handleSpecialtyChange = (event: SelectChangeEvent) => {
    props.onRefresh();
    setSpecialtyId(event.target.value as string);
  };

  const handleSaveReportSpecialty = async () => {
    try {
      setIsSubmitingReportSpecialty(true);
      await api.put(ENDPOINTS.REPORT_SPECIALTIES.PUT.BY_ID.replace(':id', reportSpecialtyId), {
        evaluation: evaluationInput.current,
        considerations: considerationsInput.current,
        behavioral_obs: behavioralObsInput.current,
        adaptive_obs: adaptiveObsInput.current,
        cognitive_obs: cognitiveObsInput.current,
      });
      props.shouldRefresh();
      setIsSubmitingReportSpecialty(false);
      toast.success(intl.get('toast.saveSuccess'));
    } catch (error) {
      setIsSubmitingReportSpecialty(false);
      displayError(error as AxiosError);
    }
  };

  return <Grid container columnGap={6} rowSpacing={3}>
    <Grid item xs={12} md={6}>
      <FormControl fullWidth>
        <InputLabel id="specialty-options-label">{specialtiesLabel}</InputLabel>
        <Select
          labelId="specialty-options-label"
          id="specialty-options-select"
          value={specialtyId}
          label={specialtiesLabel}
          onChange={handleSpecialtyChange}
        >
          {
            props.specialtyOptions && props.specialtyOptions.length ?
              props.specialtyOptions.map((specialtyOption, index) => <MenuItem key={index} value={specialtyOption.value}>
                {specialtyOption.label}
              </MenuItem>) :
              <MenuItem value={UTILS.BLANK}>
                {intl.get('pages.viewLearner.noOptions')}
              </MenuItem>
          }
        </Select>
      </FormControl>
    </Grid>
    <Grid item xs={12} md={6}>
      <Grid container columnGap={6} rowSpacing={3}>
        {
          goalsInputs.current.length < minGoals ?
            <Grid item xs={12}>
              <Typography color={'primary'} variant="h2" fontWeight={'bold'}>
                {
                  userBelongsToSpecialty || (user && user.role.id.toString() === ROLES.CLINIC) ?
                    intl.get('pages.viewLearner.map.noGoalsCreateTip') :
                    intl.get('pages.viewLearner.map.noGoalsInfoTip')
                }
              </Typography>
            </Grid> :
            <>
              <Evaluation
                initialValue={evaluationInput.current}
                userBelongsToSpecialty={userBelongsToSpecialty}
                onChange={(val) => evaluationInput.current = val}
                onSave={handleSaveReportSpecialty}
                isSubmiting={isSubmitingReportSpecialty}
              />
              <Goals
                initialValue={goalsInputs.current}
                userBelongsToSpecialty={userBelongsToSpecialty}
                shouldRefresh={props.shouldRefresh}
              />
              <Considerations
                initialValue={considerationsInput.current}
                userBelongsToSpecialty={userBelongsToSpecialty}
                onChange={(val) => considerationsInput.current = val}
                onSave={handleSaveReportSpecialty}
                isSubmiting={isSubmitingReportSpecialty}
              />
              <BehavioralAspects
                shouldRefresh={props.shouldRefresh}
                behavioralAspects={props.behavioralAspects}
                reportSpecialtyBehavioralAspects={reportSpecialtyBehavioralAspects}
                reportSpecialtyId={reportSpecialtyId}
                userBelongsToSpecialty={userBelongsToSpecialty}
                setReportSpecialtyBehavioralAspects={setReportSpecialtyBehavioralAspects}
                onChangeAdaptiveObsInputs={(val) => adaptiveObsInput.current = val}
                onChangeBehavioralObsInputs={(val) => behavioralObsInput.current = val}
                onChangeCognitiveObsInputs={(val) => cognitiveObsInput.current = val}
                adaptiveObsInitialValue={adaptiveObsInput.current}
                behavioralObsInitialValue={behavioralObsInput.current}
                cognitiveObsInitialValue={cognitiveObsInput.current}
                onSaveObsInputs={handleSaveReportSpecialty}
                isSubmitingObsInputs={isSubmitingReportSpecialty}
              />
            </>
        }
        <ConditionalContainer noComponent checkIf={goalsInputs.current.length >= minGoals}>
          <VisualRecord
            reportSpecialtyId={reportSpecialtyId}
            userBelongsToSpecialty={userBelongsToSpecialty}
            image={reportSpecialtyImage}
          />
        </ConditionalContainer>
      </Grid>
    </Grid>

  </Grid >;
}
