import { UTILS } from 'shared/constants';

export const sanitizeString = (input: string) => {
  const specialCharacters = /[//()[\]{}%ªº+=§*¬&$#@!¢^~`¨´;,£?|:''\\\/]/g;
  const spaces = /[^\S\r\n]{2,}/g;

  return input.replace(specialCharacters, UTILS.BLANK).replace(spaces, UTILS.SPACE).trimStart();
};

export const normalizeString = (text: string) => {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, UTILS.BLANK).trim().toLowerCase();
};

export const truncateString = (str: string, sizeLimit: number): string => {
  if (str.length > sizeLimit) {
    return `${str.slice(0, sizeLimit - 1)}${UTILS.ELLIPSIS}`;
  }
  return str;
};
