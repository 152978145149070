import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Box, Tab, Tabs, Tooltip, useMediaQuery } from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';
import intl from 'react-intl-universal';
import { AxiosError } from 'axios';

import Team from 'models/Team';
import Learner from 'models/Learner';
import Report from 'models/Report';
import { BehavioralAspectsByType } from 'models/BehavioralAspect';

import { useAppSelector } from 'store/hooks';

import { ENDPOINTS, ROUTES } from 'shared/constants';
import defaultTheme from 'shared/theme';
import api from 'shared/api';

import { displayError } from 'helpers/http';
import { sortAlphabetically } from 'helpers/array';

import ConditionalContainer from 'components/ConditionalContainer';
import { SelectOption } from 'components/Table/Filter';
import Breadcrumbs from 'components/Breadcrumbs';
import Pdt from './Pdt';
import Mapa from './Mapa';
import Dsdt from './Dsdt';
import DownloadReports from './Download';
import ReportTexts from './ReportTexts';

/**
 * ViewLearner component
 * @return {JSX.Element}
 */
export default function ViewLearner(): JSX.Element {
  const navigate = useNavigate();
  const lgUp = useMediaQuery(defaultTheme.breakpoints.up('lg'));
  const { user } = useAppSelector((state) => state.auth);
  const { id, teamId } = useParams<{ id: string, teamId: string }>();
  const [team, setTeam] = useState<Team>();
  const [learner, setLearner] = useState<Learner>();
  const [report, setReport] = useState<Report>();
  const [tab, setTab] = useState(0);
  const [specialtyOptions, setSpecialtyOptions] = useState<SelectOption[]>([]);
  const [behavioralAspects, setBehavioralAspects] = useState<BehavioralAspectsByType>();
  const shouldRefresh = useRef(true);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  useEffect(() => {
    getBehavioralAspects();
  }, []);

  useEffect(() => {
    if (teamId) {
      getTeam();
    }
  }, [teamId]);

  useEffect(() => {
    if (id && team) {
      getLearner();
    }
  }, [id, team]);

  useEffect(() => {
    if (learner && team && shouldRefresh.current) {
      getReport();
      shouldRefresh.current = false;
    }
  }, [learner, team, tab]);

  useEffect(() => {
    if (team) {
      const newSpecialtyOptions = team.specialtiesUsers
        .map((specialtiesUser) => ({
          value: specialtiesUser.specialty.id.toString(),
          label: specialtiesUser.specialty.name,
        }));

      newSpecialtyOptions.sort((a, b) => sortAlphabetically<SelectOption>(a, b, 'label'));

      setSpecialtyOptions(newSpecialtyOptions);
    }
  }, [team]);

  const loggedSpecialtyIds = useMemo(() => {
    if (team && user) {
      return team.specialtiesUsers
        .filter((specialtiesUser) => specialtiesUser.user.id === user.id)
        .map((specialtiesUser) => specialtiesUser.specialty.id.toString());
    }
    return [];
  }, [team, user]);

  const breadcrumbs = useMemo(() => {
    if (team && learner) {
      return [
        {
          name: intl.get('pages.teams.title'),
          onClickCallback: () => navigate(ROUTES.TEAMS),
        },
        {
          name: team.name,
          onClickCallback: () => navigate(ROUTES.TEAMS_VIEW.replace(':id', team.id.toString())),
        },
        {
          name: learner.name,
          onClickCallback: () => { },
        },
      ];
    }
    return [];
  }, [team, learner]);

  const getTeam = async () => {
    if (teamId) {
      try {
        const response = await api.get(ENDPOINTS.TEAMS.GET.BY_ID.replace(':id', teamId));
        setTeam(response.data);
      } catch (error) {
        displayError(error as AxiosError);
        navigate(ROUTES.TEAMS);
      }
    }
  };

  const getLearner = async () => {
    if (id && team) {
      try {
        const response = await api.get(ENDPOINTS.LEARNERS.GET.BY_ID.replace(':id', id));
        setLearner(response.data);
      } catch (error) {
        displayError(error as AxiosError);
        navigate(ROUTES.TEAMS_VIEW.replace(':id', team.id.toString()));
      }
    }
  };

  const getReport = async () => {
    if (learner && team) {
      try {
        const learnerTeamResponse = await api.get(
          ENDPOINTS.LEARNER_TEAM.GET.BY_LEARNER_ID_AND_TEAM_ID
            .replace(':learnerId', learner.id.toString())
            .replace(':teamId', team.id.toString()),
        );

        const response = await api.post(ENDPOINTS.REPORTS.POST.ADD, {
          learner_team_id: learnerTeamResponse.data.id,
        });

        setReport(response.data);
      } catch (error) {
        displayError(error as AxiosError);
      }
    }
  };

  const getBehavioralAspects = async () => {
    try {
      const response = await api.get(ENDPOINTS.BEHAVIORAL_ASPECTS.GET.ALL);
      setBehavioralAspects(response.data);
    } catch (error) {
      displayError(error as AxiosError);
    }
  };

  return <>
    <Breadcrumbs items={breadcrumbs} />
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        variant="scrollable"
        value={tab}
        onChange={handleChange}
        aria-label={intl.get('pages.viewLearner.tabsTitle')}
      >
        <Tab label={intl.get('pages.viewLearner.reportTab')} />
        <Tab
          label={<Tooltip title={intl.get('pages.viewLearner.pdtMeaning')}>
            <span>{intl.get('pages.viewLearner.pdtTab')}</span>
          </Tooltip>}
        />
        <Tab
          label={<Tooltip title={intl.get('pages.viewLearner.dsdtMeaning')}>
            <span>{intl.get('pages.viewLearner.dsdtTab')}</span>
          </Tooltip>}
        />
        <Tab
          label={<Tooltip title={intl.get('pages.viewLearner.mapMeaning')}>
            <span>{intl.get('pages.viewLearner.mapTab')}</span>
          </Tooltip>}
        />
        <Tab label={intl.get('pages.viewLearner.downloadTab')} />
      </Tabs>
    </Box>
    <Box pt={3}>
      <ConditionalContainer checkIf={tab === 0} noComponent>
        {
          team && report && user ?
            <ReportTexts
              shouldRefresh={() => shouldRefresh.current = true}
              onRefresh={getReport}
              specialtyOptions={specialtyOptions}
              loggedSpecialtyIds={loggedSpecialtyIds}
              report={report}
              team={team}
              user={user}
            /> : null
        }
      </ConditionalContainer>
      <ConditionalContainer checkIf={tab === 1} noComponent>
        {
          team && report && user ?
            <Pdt
              shouldRefresh={() => shouldRefresh.current = true}
              onRefresh={getReport}
              specialtyOptions={specialtyOptions}
              loggedSpecialtyIds={loggedSpecialtyIds}
              report={report}
              team={team}
            /> : null
        }
      </ConditionalContainer>
      <ConditionalContainer checkIf={tab === 2} noComponent>
        {
          team && report && user ?
            <Dsdt
              shouldRefresh={() => shouldRefresh.current = true}
              onRefresh={getReport}
              specialtyOptions={specialtyOptions}
              loggedSpecialtyIds={loggedSpecialtyIds}
              report={report}
              team={team}
              user={user}
            /> : null
        }
      </ConditionalContainer>
      <ConditionalContainer checkIf={tab === 3} noComponent>
        {
          team && report && user && behavioralAspects ?
            <Mapa
              behavioralAspects={behavioralAspects}
              shouldRefresh={() => shouldRefresh.current = true}
              onRefresh={getReport}
              specialtyOptions={specialtyOptions}
              loggedSpecialtyIds={loggedSpecialtyIds}
              report={report}
              team={team}
              user={user}
            /> : null
        }
      </ConditionalContainer>
      <ConditionalContainer checkIf={tab === 4} noComponent>
        {
          team && report && behavioralAspects ?
            <DownloadReports
              lgUp={lgUp}
              team={team}
              report={report}
              behavioralAspects={behavioralAspects}
            /> :
            null
        }
      </ConditionalContainer>
    </Box>
  </>;
}
